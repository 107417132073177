import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import { useDispatchApp } from 'redux/rootSelectors';
import { AppState } from 'redux/rootReducer';
import { ClientsState, delLastId, setLastId, setLastStatus } from 'domains/clients/reducer';
import { Status } from 'domains/clients/types';

/** возврощает последний посещенный ид */
export function useHighlights(): string {
  const dispatch = useDispatchApp();
  const [id, setId] = useState<string>('');
  const history = useHistory();
  const lastId = useSelector<AppState, ClientsState['lastId']>(
    ({ clientsReducer }) => clientsReducer.lastId,
    shallowEqual,
  );

  const lastStatus = useSelector<AppState, ClientsState['lastStatus']>(
    ({ clientsReducer }) => clientsReducer.lastStatus,
    shallowEqual,
  );

  useEffect(() => {
    if (!lastId || !lastStatus) return;
    const url = new URL(window.location.href);
    url.searchParams.set('highlights', lastId);
    url.searchParams.set('status', lastStatus);
    history.push(url.search);
  }, [lastId, lastStatus]);

  useEffect(() => {
    const url = new URL(window.location.href);
    const highlights = url.searchParams.get('highlights') ?? '';
    dispatch(setLastId(highlights));
    setId(highlights);
  }, [window.location.href]);
  return id;
}
/** устанвливает последний посещенный ид из сылки */
export function useSetLastIdClients(status?: Status): void {
  const dispatch = useDispatchApp();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const id = query.get('client_id');
  useEffect(() => {
    if (!id || !status) return;
    dispatch(setLastId(id));
    dispatch(setLastStatus(status));
  }, [id, status]);
}
/** удаляет последний посещеный ид */
export function useDelLastIdClients(): () => void {
  const dispatch = useDispatchApp();
  return useCallback(() => {
    dispatch(delLastId());
  }, []);
}
