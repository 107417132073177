import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ToastUtilsSuccessParams } from 'types/general';
import { FetchedData, genFetchedData } from '../../redux/fetchedData';
import { ClientsList, ParamClients, ParamSetStatus, Status } from './types';

export type RerenderType = {
  status: Status | null;
  idAgency: string | null;
  alertText?: string | ToastUtilsSuccessParams;
  isArchive?: boolean;
};

type ParamSetStatusSaga = ParamSetStatus & {
  setter: (param: boolean) => void;
  value: boolean;
  reRenderParams?: RerenderType;
};

export type ClientsState = {
  tempClients: FetchedData<ClientsList>;
  tempAllClients: FetchedData<ClientsList>;
  lastId: string;
  lastStatus: string;
};

const initialState: ClientsState = {
  tempClients: genFetchedData<ClientsList>(null),
  tempAllClients: genFetchedData<ClientsList>(null),
  lastId: '',
  lastStatus: '',
};

const clientsSlice = createSlice({
  name: 'Clients',
  initialState,
  reducers: {
    setTempClients(state, action: PayloadAction<ClientsState['tempClients']>) {
      state.tempClients = action.payload;
    },
    setTempAllClients(state, action: PayloadAction<ClientsState['tempAllClients']>) {
      state.tempAllClients = action.payload;
    },
    setLastId(state, action: PayloadAction<ClientsState['lastId']>) {
      state.lastId = action.payload;
    },
    setLastStatus(state, action: PayloadAction<ClientsState['lastStatus']>) {
      state.lastStatus = action.payload;
    },
    delLastId(state) {
      state.lastId = initialState.lastId;
    },
    delLastStatus(state) {
      state.lastStatus = initialState.lastStatus;
    },
  },
});

export const fetchClients = createAction<ParamClients>('Clients/fetchClients');
export const fetchAllClients = createAction<ParamClients>('Clients/fetchAllClients');


export const setStatusClientAction = createAction<ParamSetStatusSaga>(
  'Clients/setStatusClient',
);

export const { setTempClients, setTempAllClients, setLastId, setLastStatus, delLastId, delLastStatus } = clientsSlice.actions;
export const clientsReducer = clientsSlice.reducer;
