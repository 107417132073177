import React, { FC, useEffect, useState } from 'react';
import {
  Field,
  FieldProps,
  FormikHandlers,
  FormikHelpers,
  FormikState,
} from 'formik';
import { useTranslation } from 'react-i18next';
import Card from 'components/UI/Card';
import Help from 'components/Help';
import { checkedLocalization } from 'utils/checkedLocalization';
import { useUserTheme } from 'domains/user/model/selectors';
import css from '../styles.module.scss';
import TypeSelect from '../../../TypeSelect';
import {
  isGardHTMLType,
  isGardHTMLTypeError,
  isGardMultiFormatType,
  isGardPushType,
  isGardTeaserType,
  isGardVideoInlineType,
  isGardYandexType,
  isGardYandexTypeError,
} from '../../../hooks';
import { TFormikCreativeAdd } from '../../../../types';
import { SelectField } from '../../../../../../types/general';
import { FetchedDataObj } from '../../../../../../redux/fetchedData';
import { useValidateVendorLInk } from '../../../../model/hooks/useValidateVendorLInk';
import useType from '../../hooks/useType';
import HtmlBlock from './Type/Html';
import PushBlock from './Type/Push';
import Clickunder from './Type/Clickunder';
import YandexBlock from './Type/Yandex';
import TeaserBlock from './Type/Teaser';
import VideoBlock from './Type/Video';
import VideovastBlock from './Type/Videovast';
import MultiformatBlock from './Type/Multiformat';
import Companion from './Type/Companion';

type Props = {
  formik: FormikState<TFormikCreativeAdd> &
    FormikHandlers &
    FormikHelpers<TFormikCreativeAdd>;
  defaultSizeOption: (v: string) => SelectField[] | [];
  sizeOption: SelectField[];
  error: FetchedDataObj<boolean>['error'];
  helper: ReturnType<typeof useValidateVendorLInk>['helper'];
  setIsDisableSaveButton: React.Dispatch<React.SetStateAction<boolean>>;
  handlerCopyHtml: () => void;
};

const TypeHtml: FC<Props> = ({
  formik,
  sizeOption,
  defaultSizeOption,
  handlerCopyHtml,
  setIsDisableSaveButton,
  error,
  helper,
}) => {
  const { showBlock, erid, setToggle } = useType({ formik, defaultSizeOption });

  const { t } = useTranslation();

  const { getValueFromSettingForKey } = useUserTheme();

  const [showHint, setShowHint] = useState(Boolean(erid));

  useEffect(() => {
    if (erid) {
      setShowHint(!formik.values.erid);
    }
  }, [erid, formik.values.erid]);  

  return (
    <Card
      noHover
      className={css.card}
      showHint={showHint}
      closeHint={() => setShowHint(false)}
      hintClassName="erir"
      textHint={
        <>
          {t('creative.erir_hint_1')}
          <strong> {t('creative.erir_hint_2')} </strong>
          {t('creative.erir_hint_3')}
        </>
      }
    >
      <h3 className={css.title}>
        <Field name="type">
          {(formProps: FieldProps) => <TypeSelect {...formProps} />}
        </Field>
        {getValueFromSettingForKey('HideHelp') && (
          <Field name="type">
            {({ field }: FieldProps) => (
              <Help
                width={200}
                title={t(`creative.create_menu.${field.value}`)}
                helpattributes={{
                  link: checkedLocalization(
                    `creative.creative_prompt.type.${field.value}.link`,
                    ``,
                  ),
                  sizeIcon: 18,
                }}
              />
            )}
          </Field>
        )}
      </h3>

      {formik.values.type === 'HTML' &&
        isGardHTMLType(formik.values.data) &&
        isGardHTMLTypeError(formik.errors.data) && (
          <HtmlBlock
            formik={formik}
            defaultSizeOption={defaultSizeOption}
            handlerCopyHtml={handlerCopyHtml}
            sizeOption={sizeOption}
            erid={erid}
            showBlock={showBlock}
            setToggle={setToggle}
            setIsDisableSaveButton={setIsDisableSaveButton}
          />
        )}

      {formik.values.type === 'PUSH' && isGardPushType(formik.values.data) && (
        <PushBlock formik={formik} />
      )}

      {formik.values.type === 'YANDEX' &&
        isGardYandexType(formik.values.data) &&
        isGardYandexTypeError(formik.errors.data) && (
          <YandexBlock formik={formik} />
        )}

      {formik.values.type === 'CLICKUNDER' && erid && (
        <Clickunder
          formik={formik}
          erid={erid}
          setIsDisableSaveButton={setIsDisableSaveButton}
        />
      )}

      {formik.values.type === 'TEASER' &&
        isGardTeaserType(formik.values.data) && (
          <TeaserBlock
            formik={formik}
            defaultSizeOption={defaultSizeOption}
            erid={erid}
            showBlock={showBlock}
            setToggle={setToggle}
            setIsDisableSaveButton={setIsDisableSaveButton}
          />
        )}

      {formik.values.type === 'MULTI_FORMAT' &&
        isGardMultiFormatType(formik.values.data) && (
          <MultiformatBlock formik={formik} />
        )}

      {formik.values.type === 'VIDEO_INLINE' &&
        isGardVideoInlineType(formik.values.data) && (
          <VideoBlock
            formik={formik}
            defaultSizeOption={defaultSizeOption}
            sizeOption={sizeOption}
            helper={helper}
            erid={erid}
            showBlock={showBlock}
            setToggle={setToggle}
            setIsDisableSaveButton={setIsDisableSaveButton}
          />
        )}

      {formik.values.type === 'VIDEO_VAST' && (
        <VideovastBlock
          formik={formik}
          defaultSizeOption={defaultSizeOption}
          error={error}
          sizeOption={sizeOption}
          helper={helper}
          erid={erid}
          showBlock={showBlock}
          setToggle={setToggle}
          setIsDisableSaveButton={setIsDisableSaveButton}
        />
      )}

      {formik.values.type === 'COMPANION' && (
        <Companion
          formikVideo={formik}
          helper={helper}
          erid={erid}
          showBlock={showBlock}
          setToggle={setToggle}
          sizeOption={sizeOption}
          defaultSizeOption={defaultSizeOption}
          setIsDisableSaveButton={setIsDisableSaveButton}
        />
      )}
    </Card>
  );
};

export default TypeHtml;
