import React, { FC } from 'react';
import cn from 'classnames';
import Tooltip from 'components/UI/Tooltip';
import { ReactSVG } from 'react-svg';
import { FiXCircle, FiAlertCircle } from 'react-icons/fi';
import { useIsTranslation } from 'components/Translation/hooks/useIsTranslation';
import css from 'domains/campaigns/Item/styles.module.scss';
import CheckCircle from 'assets/check-circle.svg';
import i18next from 'i18next';
import TooltipPoratal from 'components/UI/TooltipPoratal';
import { ResponseTranslation } from '../../../domains/campaigns/types';
import i18n from '../../../i18n';

interface Props {
  toggle?: string;
  xxhash?: string;
  data: ResponseTranslation | null;
  error?: boolean;
}

const Translation: FC<Props> = ({ data, toggle, xxhash, error }) => {
  const {
    translationData: {
      isOrganizationStatus,
      isAgencyStatus,
      isBalance,
      isCampaignCosts,
      isCampaignPeriod,
      isCampaignStatus,
      isCreativeStatus,
      isModerationStatus,
      isClientStatus,
      isCreativeData,
      isTargetGeo,
      isTargetSource,
      isTranslation,
      isCreo,
    },
  } = useIsTranslation(data);

  const broadCastData = [
    {
      text: `translations.status_paused`,
      value: isOrganizationStatus,
    },
    {
      text: `translations.agency_no_start`,
      value: isAgencyStatus,
    },
    {
      text: `translations.balance_exhausted`,
      value: isBalance,
    },
    {
      text: `translations.zero_target`,
      value: isCampaignCosts,
    },
    {
      text: `translations.outdated_period`,
      value: isCampaignPeriod,
    },
    {
      text: `translations.campaign_stoppen`,
      value: isCampaignStatus,
    },
    {
      text: `translations.ad_stopped`,
      value: isCreativeStatus,
    },
    {
      text: `translations.ad_not_pass`,
      value: isModerationStatus,
    },
    {
      text: `translations.client_not_start`,
      value: isClientStatus,
    },
    {
      text: `translations.error_creo`,
      value: isCreativeData,
    },
    {
      text: `translations.geo_target_empty`,
      value: isTargetGeo,
    },
    {
      text: `translations.source_target_empty`,
      value: isTargetSource,
    },
  ];

  return (
    <div
      className={
        isCreo
          ? cn(css.status, {
              _isHide: toggle === xxhash,
            })
          : cn(css.value, 'center')
      }
    >
      {error ? (
        <Tooltip
          title={<FiXCircle size={20} color="#FF3636" />}
          white
          left={!!isCreo}
          hintWidth={240}
          className={isCreo ? '' : css.status}
        >
          {i18next.t('errors.err94')}
        </Tooltip>
      ) : (
        <TooltipPoratal
          left
          hint={
            <>
              {broadCastData.map((status) => {
                if (status !== undefined && !status.value) {
                  return (
                    <div className={css.statusRaw} key={`${status.text}`}>
                      {i18n.t(status.text)}
                    </div>
                  );
                }
                return null;
              })}
              {!isTranslation &&
                broadCastData.every((item) => item.value === true) && (
                  <div className={cn(css.statusRaw, '_isYelow')}>
                    {i18n.t(`translations.dsp_disable`)}
                  </div>
                )}
              {isTranslation &&
                (broadCastData.some((item) => item.value === false) ? (
                  <div className={cn(css.statusRaw, '_isYelow')}>
                    {isCreo
                      ? i18n.t(`translations.ad_is_run`)
                      : i18n.t(`translations.camp_is_run`)}
                  </div>
                ) : (
                  <div className={cn(css.statusRaw, '_isGreen')}>
                    {isCreo
                      ? i18n.t(`translations.adv_uploaded`)
                      : i18n.t(`translations.campaign_ready`)}
                  </div>
                ))}
            </>
          }
          classname={isCreo ? '' : css.status}
          hintWidth={240}
          white
        >
          {
            // eslint-disable-next-line no-nested-ternary
            isTranslation &&
            broadCastData.every((item) => item.value === true) ? (
              <ReactSVG src={CheckCircle} className={css.checkCircle} />
            ) : (isTranslation &&
                broadCastData.some((item) => item.value === false)) ||
              (!isTranslation &&
                broadCastData.every((item) => item.value === true)) ? (
              <FiAlertCircle size={20} color="#FF9C08" />
            ) : (
              <FiXCircle size={20} color="#FF3636" />
            )
          }
        </TooltipPoratal>
      )}
    </div>
  );
};

export default Translation;
