import {
  AgencyInfo,
  TMethodRecalcParams,
  TSettingsForm,
} from 'domains/agency/types';
import { useSearchBreadcrumbsInfo } from 'domains/search/model/selectors';
import { FormikErrors, useFormik } from 'formik';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import * as Yup from 'yup';
import i18next from 'i18next';
import { TExperimentalInfoField } from 'domains/campaign/types';
import { useTranslation } from 'react-i18next';
import { ResponseInfoClient } from 'domains/client/types';
import { useExperimentalData } from 'utils/useExperimentalData';
import { additionalMetricsValidation, statisticCoeffValidation } from './utils';

export interface IRecalcMethodFormProps {
  fields: TMethodRecalcParams;
  from: number;
  to: number;
  method_recalculating_statistics: string;
}

export interface IRacalcMethodParams {
  fields: TMethodRecalcParams;
  range_calculation_coefficients: TSettingsForm;
  method_recalculating_statistics: string;
}

type TUseRecalculationMethod = (
  data: AgencyInfo | ResponseInfoClient | null,
  dispatch: (
    params: IRacalcMethodParams,
    callbacks: Record<string, Dispatch<SetStateAction<boolean>>>,
  ) => void,
  SRMethod: string,
) => {
  formik: any;
  isLoading: boolean;
  commissionAgencyData: TExperimentalInfoField;
  commissionSelfServiceData: TExperimentalInfoField;
  cpcData: TExperimentalInfoField | undefined;
  marginalityData: TExperimentalInfoField | undefined;
};

/**
 *
 * @param data Информация о клиенте или агенстве, возможно будет расширяться
 * @param dispatch Методы, которые мы дергаем на formik.submitForm
 * @param SRMethod Выбранный метод
 * @returns Возвращает formik, статус loading, commissionAgencyData, commissionSelfServiceData, cpcData, marginalityData
 */
export const useRecalculationMethod: TUseRecalculationMethod = (
  data,
  dispatch,
  SRMethod,
) => {
  const { t } = useTranslation();
  const { data: breadcrumbs } = useSearchBreadcrumbsInfo();
  const {
    cpcData,
    marginalityData,
    configValuesIds,
    commissionAgencyData,
    commissionSelfServiceData,
  } = useExperimentalData();

  const [isLoading, setIsLoading] = useState(false);

  const submitHandler = (
    values: IRecalcMethodFormProps,
    callbacks: Record<string, Dispatch<SetStateAction<boolean>>>,
  ) => {
    if (!breadcrumbs) return;

    let params: IRacalcMethodParams = {
      fields: {
        statistic_coefficient: values.fields.statistic_coefficient?.replace(
          / /g,
          '\t',
        ),
        config_values: values.fields.config_values,
        additional_metrics: values.fields.additional_metrics,
        hidden_commissions: values.fields.hidden_commissions,
        commission_agency: values.fields.commission_agency || 0,
        commission_self_service: values.fields.commission_self_service || 0,
      },
      range_calculation_coefficients: {
        from: values.from,
        to: values.to,
      },
      method_recalculating_statistics:
        SRMethod || data?.method_recalculating_statistics || 'RAW_STAT',
    };

    params = {
      ...params,
      fields: {
        ...params.fields,
        config_values: cpcData?.id && marginalityData?.id && values.fields.config_values && {
          [cpcData.id]: values.fields.config_values[cpcData.id] || 0,
          [marginalityData.id]: values.fields.config_values[marginalityData.id] || 0,
        } || undefined,
        hidden_commissions: values.fields.hidden_commissions || 0
      }
    }

    dispatch(params, callbacks);
  };

  const formik = useFormik<IRecalcMethodFormProps>({
    enableReinitialize: true,
    initialValues: {
      fields: {
        statistic_coefficient: data?.fields.statistic_coefficient || '',
        additional_metrics: data?.fields.additional_metrics || '',
        config_values: cpcData?.id &&
          marginalityData?.id &&
          data?.fields.config_values && {
            [cpcData.id]: data?.fields.config_values[cpcData.id] || 0,
            [marginalityData.id]:
              data?.fields.config_values[marginalityData.id] || 0,
          } || undefined,
        hidden_commissions: data?.fields.hidden_commissions || 0,
        commission_agency: data?.fields.commission_agency || 0,
        commission_self_service: data?.fields.commission_self_service || 0,
      },
      from: data?.range_calculation_coefficients.from || 0,
      to: data?.range_calculation_coefficients.to || 0,
      method_recalculating_statistics:
        data?.method_recalculating_statistics || 'RAW_STAT',
    },
    validationSchema: Yup.object({
      fields: Yup.object({
        commission_agency: Yup.number().max(
          100,
          i18next.t('errors.commission_limit'),
        ),
        commission_self_service: Yup.number().max(
          100,
          i18next.t('errors.commission_limit'),
        ),
        additional_metrics: Yup.string().test(
          'additional_metrics validation',
          i18next.t(
            `campaigns_page.campaign_settings.click_hint.error_additional_metrics`,
          ),
          (value) => additionalMetricsValidation(value),
        ),
        statistic_coefficient: Yup.string().test(
          'statistic_coefficient validation',
          i18next.t(
            `campaigns_page.campaign_settings.click_hint.error_statistic_coefficient`,
          ),
          (value) => statisticCoeffValidation(value),
        ),
      }),
      from: Yup.number().required(t('clients_page.client_create.hint1')),
      to: Yup.number().required(t('clients_page.client_create.hint1')),
    }),
    validate: (values: IRecalcMethodFormProps) => {
      const errors: FormikErrors<IRecalcMethodFormProps> = {};

      if (SRMethod === 'CAMPAIGN_TYPE') {
        if (values.from > values.to) {
          errors.from = t(
            'campaigns_page.campaign_settings.additional.maxToBeMax',
          );
          errors.to = t(
            'campaigns_page.campaign_settings.additional.maxToBeMax',
          );
        }
      }

      if (SRMethod === 'MAX_FIX_CPC_MARGINALITY') {
        configValuesIds.forEach((id) => {
          const fieldValue = values?.fields.config_values?.[id];
          if (fieldValue && +fieldValue > 2147483647) {
            errors[id] = i18next.t('errors.limitsym');
          }
        });
      }

      return errors;
    },
    onSubmit: (values) => {
      const callbacks = {
        setIsLoading,
      };
      submitHandler(values, callbacks);
    },
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
  });

  useEffect(() => {
    if (data) {
      const currentConfigValues: Record<string, string | number | undefined> = {}
      configValuesIds.forEach(id => {
        const value = data?.fields.config_values ? data?.fields.config_values[id] : ''
        currentConfigValues[id] = value
      });

      formik.setFieldValue('fields.config_values', currentConfigValues);
    }
  }, [data, configValuesIds]);

  return {
    formik,
    isLoading,
    commissionAgencyData,
    commissionSelfServiceData,
    cpcData,
    marginalityData,
  };
};
