/* eslint-disable no-nested-ternary */
import ModalOverlay from 'components/Modals/ModalOverlay1';
import Card from 'components/UI/Card';
import React, { FC, useEffect, useState } from 'react';
import { FiAlertCircle, FiSearch, FiX } from 'react-icons/fi';
import cn from 'classnames';
import Button from 'components/UI/Buttons/buttons';
import InputAutoSave from 'components/UI/InputAutoSave';
import PriceInput from 'components/UI/PriceInput';
import { Form, FormikProvider } from 'formik';
import { IError, IErrorState, IGroupItem } from 'domains/client/types';
import { ResponseCampaignsStata } from 'domains/campaigns/types';
import i18n from '../../../../i18n';
import css from './styles.module.scss';
import Item from '../Item';
import { useGetCampaignsList } from '../hooks/useGetCampaignsList';
import { useCreateGroupForm } from '../hooks/useCreateGroupForm';
import { useSearchCampaigns } from '../hooks/useSearchCampaigns';
import LoadingCampaigns from '../ui/Loading/LoadingCampaigns';

type TLimitGroupsClientModal = {
  open: boolean;
  onClose: () => void;
  groupInfo?: IGroupItem;
  error?: IError;
};

const LimitGroupsClientModal: FC<TLimitGroupsClientModal> = ({
  open,
  onClose,
  groupInfo,
  error,
}) => {
  const { campaigns, isLoadingCampaigns } = useGetCampaignsList(open);
  const { search, setSearch, campaignsFilter } = useSearchCampaigns(groupInfo);
  const [errorDescription, setErrorDescription] = useState<IErrorState>({
    errorCode: 0,
    errorText: '',
  });

  const {
    formik,
    onKeyDown,
    campaignsXxhash,
    setCampaignsXxhash,
    resetForm,
    isResetCampaigns,
    setIsResetCampaigns,
    isLoading,
  } = useCreateGroupForm(
    onClose,
    open,
    errorDescription,
    setErrorDescription,
    groupInfo,
  );

  const returnliftedCampaigns = () => {
    const liftedCampaign: ResponseCampaignsStata[] = [];

    campaignsFilter(campaigns)?.forEach(camp => {
      if (campaignsXxhash.includes(camp.xxhash)) {
        liftedCampaign.unshift(camp)
      } else {
        liftedCampaign.push(camp)
      }
    })

    return liftedCampaign;
  }

  useEffect(() => {
    if (error?.code) {
      if (error.code === 1) {
        setErrorDescription({
          errorCode: error.code,
          errorText: 'Ошибка. Название уже используется',
        });
      }
    }
  }, [error]);

  useEffect(() => {
    if (groupInfo) {
      setCampaignsXxhash([...groupInfo.campaigns].map((item) => item.xxhash));
    }
  }, []);

  return (
    <ModalOverlay onCloseModal={onClose} isOpen={open}>
      <Card className={cn(css.container)} noHover style={{ padding: '28px' }}>
        <div className={css.header}>
          <div className={css.title}>
            <h1>{i18n.t(`clients_page.limit_groups.title`)}</h1>
          </div>
          <div className={css.close}>
            <FiX
              size={24}
              onClick={() => {
                setSearch('');
                onClose();
              }}
            />
          </div>
        </div>
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit} onKeyDown={onKeyDown}>
            <div className={css.form}>
              <div className={css.form_data}>
                <div
                  className={cn(css.name_group, {
                    error: formik.errors.limits?.show_per_user,
                  })}
                >
                  <InputAutoSave
                    tooltip={{
                      title: '',
                      text: i18n.t(`clients_page.limits_group_help`),
                      colorIcon: 'rgba(34, 34, 34, 1)'
                    }}
                    label={`${i18n.t(`clients_page.client_name`)}*`}
                    value={formik.values.title}
                    name="title"
                    maxLength={32}
                    setFieldValue={(field, value) => {
                      const regx = /^[A-Za-z0-9_\n]*$/;
                      if (regx.test(value) && value.length <= 32) {
                        formik.setFieldValue(field, value);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    handlerInputEnter={() => null}
                    isError={
                      !!formik.errors.title || !!errorDescription.errorCode
                    }
                    errorText={
                      formik.errors.title ||
                      errorDescription.errorText ||
                      error?.message
                    }
                    isSaved={false}
                    isDisable={false}
                  />
                </div>
                <div
                  className={cn(css.row, {
                    error: formik.errors.limits?.show_per_user,
                  })}
                >
                  <div className={css.row_title_wrapper}>
                    <div className={css.row_title}>
                      {i18n.t('campaigns_page.campaign_settings.per_user')}
                      {formik.errors.limits?.show_per_user && (
                        <FiAlertCircle size={16} className="alert-icon" />
                      )}
                    </div>
                  </div>
                  <div className={css.row_field}>
                    <PriceInput
                      theme="horizontal"
                      error={formik.errors.limits?.show_per_user?.day}
                      value={formik.values.limits.show_per_user.day}
                      label={i18n.t('campaigns_page.campaign_settings.Day')}
                      placeholder={i18n.t(
                        'campaigns_page.campaign_settings.Please_enter',
                      )}
                      decimalScale={0}
                      onValueChange={({ floatValue }) => {
                        formik.setFieldValue(
                          'limits.show_per_user.day',
                          floatValue,
                        );
                      }}
                    />
                  </div>
                  <div className={css.row_field}>
                    <PriceInput
                      theme="horizontal"
                      error={formik.errors.limits?.show_per_user?.total}
                      value={formik.values.limits.show_per_user.total}
                      label={i18n.t('campaigns_page.campaign_settings.Total')}
                      placeholder={i18n.t(
                        'campaigns_page.campaign_settings.Please_enter',
                      )}
                      decimalScale={0}
                      onValueChange={({ floatValue }) => {
                        formik.setFieldValue(
                          'limits.show_per_user.total',
                          floatValue,
                        );
                      }}
                      notField={false}
                      isSaved={false}
                    />
                  </div>
                </div>
                {
                  // Удалила по задаче https://sniper.atlassian.net/browse/AS-9216
                }
                {/* <div
                  className={cn(css.row, {
                    error: formik.errors.limits?.show,
                  })}
                >
                  <div className={css.row_title_wrapper}>
                    <div className={css.row_title}>
                      {i18n.t('campaigns_page.campaign_settings.Impressions')}
                      {formik.errors.limits?.show && (
                        <FiAlertCircle size={16} className="alert-icon" />
                      )}
                    </div>
                  </div>
                  <div className={css.row_field}>
                    <PriceInput
                      theme="horizontal"
                      error={formik.errors.limits?.show?.day}
                      value={formik.values.limits.show.day}
                      label={i18n.t('campaigns_page.campaign_settings.Day')}
                      placeholder={i18n.t(
                        'campaigns_page.campaign_settings.Please_enter',
                      )}
                      decimalScale={0}
                      onValueChange={({ floatValue }) => {
                        formik.setFieldValue('limits.show.day', floatValue);
                      }}
                      notField={false}
                      isSaved={false}
                    />
                  </div>
                  <div className={css.row_field}>
                    <PriceInput
                      theme="horizontal"
                      error={formik.errors.limits?.show?.total}
                      value={formik.values.limits.show.total}
                      label={i18n.t('campaigns_page.campaign_settings.Total')}
                      placeholder={i18n.t(
                        'campaigns_page.campaign_settings.Please_enter',
                      )}
                      decimalScale={0}
                      onValueChange={({ floatValue }) => {
                        formik.setFieldValue('limits.show.total', floatValue);
                      }}
                      notField={false}
                      isSaved={false}
                    />
                  </div>
                </div>
                <div
                  className={cn(css.row, {
                    error: formik.errors.limits?.click,
                  })}
                >
                  <div className={css.row_title_wrapper}>
                    <div className={css.row_title}>
                      {i18n.t('campaigns_page.campaign_settings.Clicks')}
                      {formik.errors.limits?.click && (
                        <FiAlertCircle size={16} className="alert-icon" />
                      )}
                    </div>
                  </div>
                  <div className={css.row_field}>
                    <PriceInput
                      theme="horizontal"
                      error={formik.errors.limits?.click?.day}
                      value={formik.values.limits.click.day}
                      label={i18n.t('campaigns_page.campaign_settings.Day')}
                      placeholder={i18n.t(
                        'campaigns_page.campaign_settings.Please_enter',
                      )}
                      decimalScale={0}
                      onValueChange={({ floatValue }) => {
                        formik.setFieldValue('limits.click.day', floatValue);
                      }}
                      notField={false}
                      isSaved={false}
                    />
                  </div>
                  <div className={css.row_field}>
                    <PriceInput
                      theme="horizontal"
                      error={formik.errors.limits?.click?.total}
                      value={formik.values.limits.click.total}
                      label={i18n.t('campaigns_page.campaign_settings.Total')}
                      placeholder={i18n.t(
                        'campaigns_page.campaign_settings.Please_enter',
                      )}
                      decimalScale={0}
                      onValueChange={({ floatValue }) => {
                        formik.setFieldValue('limits.click.total', floatValue);
                      }}
                      notField={false}
                      isSaved={false}
                    />
                  </div>
                </div>
                <div
                  className={cn(css.row, {
                    error: formik.errors.limits?.budget,
                  })}
                >
                  <div className={css.row_title_wrapper}>
                    <div className={css.row_title}>
                      {i18n.t('campaigns_page.campaign_settings.Total_raw')},{' '}
                      {currency}
                      {formik.errors.limits?.budget && (
                        <FiAlertCircle size={16} className="alert-icon" />
                      )}
                    </div>
                  </div>
                  <div className={css.row_field}>
                    <PriceInput
                      theme="horizontal"
                      error={formik.errors.limits?.budget?.day}
                      value={formik.values.limits.budget.day}
                      label={i18n.t('campaigns_page.campaign_settings.Day')}
                      placeholder={i18n.t(
                        'campaigns_page.campaign_settings.Please_enter',
                      )}
                      decimalScale={0}
                      onValueChange={({ floatValue }) => {
                        formik.setFieldValue('limits.budget.day', floatValue);
                      }}
                      notField={false}
                      isSaved={false}
                    />
                  </div>
                  <div className={css.row_field}>
                    <PriceInput
                      theme="horizontal"
                      error={formik.errors.limits?.budget?.total}
                      value={formik.values.limits.budget.total}
                      label={i18n.t('campaigns_page.campaign_settings.Total')}
                      placeholder={i18n.t(
                        'campaigns_page.campaign_settings.Please_enter',
                      )}
                      decimalScale={0}
                      onValueChange={({ floatValue }) => {
                        formik.setFieldValue('limits.budget.total', floatValue);
                      }}
                      notField={false}
                      isSaved={false}
                    />
                  </div>
                </div> */}
              </div>
              <div className={css.form_campaigns}>
                <h4 className={css.title}>{i18n.t('just_words.w6')}</h4>
                <div className={css.search}>
                  <input
                    type="text"
                    autoComplete="off"
                    placeholder={i18n.t(
                      `clients_page.limit_groups.search_camp`,
                    )}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  {search === '' ? (
                    <FiSearch size={24} />
                  ) : (
                    <FiX
                      size={24}
                      className={css.cross}
                      onClick={() => setSearch('')}
                    />
                  )}
                </div>
                {isLoadingCampaigns ? (
                  <LoadingCampaigns />
                ) : (
                  <div className={css.search_result}>
                    <div className={css.search_items}>
                      {returnliftedCampaigns().length ? (
                        returnliftedCampaigns().map((camp) => (
                          <Item
                            key={camp.internal_id}
                            id={camp.xxhash}
                            internal_id={camp.internal_id}
                            name={camp.title}
                            isResetCampaigns={isResetCampaigns}
                            campaignsXxhash={campaignsXxhash}
                            setCampaignsXxhash={setCampaignsXxhash}
                            setIsResetCampaigns={setIsResetCampaigns}
                          />
                        ))
                      ) : (
                        <div className={css.empty_search}>
                          {i18n.t(`search_none.none_1`)}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
              <div className={css.buttons}>
                <Button
                  title={i18n.t(`clients_page.limit_groups.clear_group`)}
                  transparent
                  buttonAttributes={{
                    type: 'reset',
                    onClick: () => {
                      resetForm();
                      setSearch('');
                    },
                  }}
                />
                <Button
                  title={i18n.t(`save_btn`)}
                  buttonAttributes={{
                    onClick: () => formik.handleSubmit(),
                    disabled:
                      !formik.isValid ||
                      isLoading ||
                      !!errorDescription.errorText,
                  }}
                />
              </div>
            </div>
          </Form>
        </FormikProvider>
      </Card>
    </ModalOverlay>
  );
};

export default LimitGroupsClientModal;
