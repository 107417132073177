import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import Breadcrumbs from 'components/Breadcrumbs/breadcrumbs';
import cn from 'classnames';
import CardsList from 'components/UI/CardList';
import Card from 'components/UI/Card';
import { FiAlertCircle } from 'react-icons/fi';
import CalculationCoefficientsBlock from 'components/CalculationCoefficientsBlock';
import { Trans } from 'react-i18next';
import css from './styles.module.scss';
import { useClientSettings } from '../model/useClientSettings';

const Settings: FC = () => {
  const {
    t,
    routeParams,
    clientInfo,
    isLoading,
    formik,
    commissionAgencyData,
    commissionSelfServiceData,
    cpcData,
    marginalityData,
    listSRMethods,
    onChangeSRMethod,
    SRMethod
  } = useClientSettings();

  return (
    <div className={css.settings}>
      <Helmet>
        <title>{t(`project_page.settings`)}</title>
      </Helmet>
      <div className={css.header}>
        <div className="container">
          <Breadcrumbs type="Client" xxhash={routeParams.id} />
          <div className={css.status}>
            <h1 className={css.title}>{clientInfo?.title}</h1>
          </div>
        </div>
      </div>
      <div className={css.body}>
        <div className={cn(css.container, 'container')}>
          <CardsList>
            <CalculationCoefficientsBlock
              isLoadingData={isLoading}
              formik={formik}
              SRMethod={SRMethod}
              listSRMethods={listSRMethods}
              onChangeSRMethod={onChangeSRMethod}
              commissionAgencyData={commissionAgencyData}
              commissionSelfServiceData={commissionSelfServiceData}
              cpcData={cpcData}
              marginalityData={marginalityData}
              data={clientInfo}
            />
          </CardsList>
          <CardsList>
            <Card noHover isUseAccess>
              <div className={css.info}>
                <FiAlertCircle size={20} />
                <div className={css.info_text}>
                  <p>
                    {t(`campaigns_page.campaign_settings.additional.info_1`)}
                  </p>
                  <p>
                    <Trans
                      i18nKey="campaigns_page.campaign_settings.additional.info_2"
                      values={{
                        method: 'method_recalculating_statistics_CAMPAIGN_TYPE',
                      }}
                      components={{ bold: <strong /> }}
                    />
                  </p>
                </div>
              </div>
            </Card>
          </CardsList>
        </div>
      </div>
    </div>
  );
};

export default Settings;
