import React, { FC, useEffect, useMemo, useRef } from 'react';
import cn from 'classnames';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import Load from 'components/UI/Load';
import ElementsNone from 'components/ElementsNone';
import MainSearch from 'components/MainSearch';
import { useOnScroll } from 'hooks/useOnScroll';
import { Element, scroller } from 'react-scroll';
import css from './styles.module.scss';
import NavigationHeader from '../../components/NavigationHeader';
import { useDataClients } from './hooks/useDataClients';
import Breadcrumbs from '../../components/Breadcrumbs/breadcrumbs';
import Button from '../../components/UI/Buttons/buttons';
import { useIsShowMCreate } from '../client/create/hooks';
import useSearchField from '../../hooks/useSearchField';
import PreloaderList from '../../components/Preloaders/List';
import Item from './Item';
import { ElementsNoneType } from '../../types/general';
import AccessUserRight from '../../components/AccessUserRight';
import ErrorReport from '../../components/Errors/Reports';
import { useHighlights } from '../../utils/useLastIdHelpers/useLastIdClient';

const ClientsList: FC = () => {
  const { t } = useTranslation();
  const searchRef = useRef<HTMLInputElement>(null);
  const { fixed: isFixed } = useOnScroll(120);
  const { onChangeSearch, search, onCloseSearch, onOpenSearch, toggleSearch } =
    useSearchField(searchRef);
  const { status, clients, isLoading, setStatusAction, idAgency, error } =
    useDataClients();
  const openMCreateCl = useIsShowMCreate(true);

  const filterData = useMemo(() => {
    if (status) {
      return clients.filter((client) =>
        client.title.toLowerCase().includes(search.toLowerCase()),
      );
    }
    return clients.filter(
      (client) =>
        client.title.toLowerCase().includes(search.toLowerCase()) &&
        client.status !== 'DELETED',
    );
  }, [clients, search]);

  const lastId = useHighlights();

  useEffect(() => {
    setTimeout(() => {
      scroller.scrollTo(lastId, {
        duration: 0,
        delay: 0,
        smooth: false,
        offset: -400,
      });
    }, 1000);
  }, [lastId, status]);

  if (error.isError) {
    return <ErrorReport error={error} />;
  }

  return (
    <div className={cn(css.clients)}>
      <Helmet>
        <title>{t('clients_page.clients_tit')}</title>
      </Helmet>

      <NavigationHeader>
        <button
          type="button"
          className={cn('tabs__item', {
            isActive: status && status === 'LAUNCHED',
          })}
          onClick={() => setStatusAction('LAUNCHED')}
          disabled={isLoading}
        >
          {t('clients_page.active_clients')}
        </button>
        <button
          type="button"
          className={cn('tabs__item', {
            isActive: status && status === 'STOPPED',
          })}
          onClick={() => setStatusAction('STOPPED')}
          disabled={isLoading}
        >
          {t('clients_page.clients_stopped')}
        </button>
        <button
          type="button"
          className={cn('tabs__item', {
            isActive: status && status === 'ARCHIVE',
          })}
          onClick={() => setStatusAction('ARCHIVE')}
          disabled={isLoading}
        >
          {t('clients_page.archive_clients')}
        </button>
        <button
          type="button"
          className={cn('tabs__item', {
            isActive: status && status === 'DELETED',
          })}
          onClick={() => setStatusAction('DELETED')}
          disabled={isLoading}
        >
          {t('clients_page.clients_deleted')}
        </button>
        <button
          type="button"
          className={cn('tabs__item', { isActive: status === null })}
          onClick={() => setStatusAction(null)}
          disabled={isLoading}
        >
          {t('clients_page.clients_all')}
        </button>
      </NavigationHeader>

      <div
        className={cn(css.header, { isToggleSearch: toggleSearch, isFixed })}
      >
        <div className="container">
          <Breadcrumbs type="Agency" xxhash={idAgency} />
          <div className={css.status}>
            <h1 className={css.title}>{t('clients_page.clients_tit')}</h1>
            <div className={css.actions}>
              <MainSearch
                toggleSearch={toggleSearch}
                match={filterData.length}
                onOpenSearch={onOpenSearch}
                value={search}
                onChangeSearch={onChangeSearch}
                searchRef={searchRef}
                onCloseSearch={onCloseSearch}
                placeholder={t('clients_page.clients_search')}
                isShowMatch={!isLoading}
              />
              <AccessUserRight>
                {(props) => (
                  <Button
                    height={40}
                    title={t('clients_page.add_client')}
                    buttonAttributes={{
                      onClick: openMCreateCl,
                      disabled: props.isDisable,
                    }}
                  />
                )}
              </AccessUserRight>
            </div>
          </div>
        </div>
      </div>

      <div className="listings__table">
        <div className="container">
          {!isLoading && filterData && filterData.length === 0 ? (
            <ElementsNone
              type={ElementsNoneType.CLIENTS}
              search={filterData.length === 0 && clients.length > 0}
            />
          ) : (
            <div className="table">
              <div className="table__header">
                <div>
                  {!isLoading ? t('clients_page.client_name') : <Load />}
                </div>
              </div>
              {isLoading ? (
                <div className="table__body">
                  <PreloaderList type="client" />
                </div>
              ) : (
                <div className="table__body">
                  {filterData.map((e) => (
                    <Element name={e.id} key={e.id}>
                      <Item
                        client={e}
                        paramsClients={{ idAgency, status }}
                        isLastEdited={e.id === lastId}
                      />
                    </Element>
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ClientsList;
