import { shallowEqual, useSelector } from 'react-redux';
import { AppState } from '../../../redux/rootReducer';
import { AgencyInfo, AgencyState } from '../types';

interface IErrorObj {
  isError: boolean;
  message: string;
  code?: number | undefined;
  mnemonic?: string | undefined;
  description?: string | undefined;
  fields?: string[] | undefined;
  creatives?:
    | {
        xxhash: string;
        title: string;
        campaign_xxhash: string;
      }[]
    | undefined;
}
interface RAgencyInfo {
  agency: AgencyInfo | null;
  isLoading: boolean;
  error: IErrorObj;
  LTU: number;
}
/** Функция получает состояние Агенства из стейта */
export const useAgencyInfo = (): RAgencyInfo => {
  const tempAgency = useSelector<AppState, AgencyState['agencyInfo']>(
    ({ agencyReducer }) => agencyReducer.agencyInfo,
    shallowEqual,
  );

  const agency = tempAgency.get('data');
  const isLoading = tempAgency.get('isLoading');
  const error = tempAgency.get('error');
  const LTU = tempAgency.get('LTU');

  return {
    agency,
    isLoading,
    error,
    LTU,
  };
};
