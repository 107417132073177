import i18next from 'i18next';
import { FormikErrors } from 'formik';
import { CreativeDetail } from '../../types';
import { SelectField } from '../../../../types/general';

export type TFormSize = {
  size: CreativeDetail['size'];
  width?: string;
  height?: string;
};

type RUseCreativeSize = {
  validate: (p: TFormSize) => FormikErrors<TFormSize>;
  sizeOption: SelectField[];
  defaultSizeOption: (size: string) => SelectField[];
  checkSizes: (size?: string) => boolean;
};

type TUseCreativeSize = (type?: CreativeDetail['type']) => RUseCreativeSize;

export const useCreativeSize: TUseCreativeSize = (type) => {
  const checkSizes: RUseCreativeSize['checkSizes'] = (size) =>
    size ? !size.includes('1x1') : false;

  const validate = (values: TFormSize) => {
    const errors: FormikErrors<TFormSize> = {};

    if (!checkSizes(values.size)) {
      if (Number(values.width) > 10000) {
        errors.width = i18next.t('errors.err45');
      }

      if (Number(values.width) === 0 && Number(values.height) > 0) {
        errors.width = i18next.t('errors.err45');
      }

      if (Number(values.height) > 10000) {
        errors.height = `${i18next.t(
          'errors.limit_symbol.start',
        )}10000${i18next.t('errors.limit_symbol.pixel')}`;
      }
      if (Number(values.height) === 0 && Number(values.width) > 0) {
        errors.height = i18next.t('errors.err45');
      }
    } else {
      const size = values.size.split('x');

      if (Number(size[0]) > 10000 || Number(size[1]) > 10000) {
        errors.size = `${i18next.t(
          'errors.limit_symbol.start',
        )}10000${i18next.t('errors.limit_symbol.pixel')}`;
      }
    }

    return errors;
  };

  const sizeOptionVideoVast = [
    { label: '576x320', value: '576x320' },
    { label: '1920x1080', value: '1920x1080' },
    { label: i18next.t('creative.create_creative.Set_sizes'), value: '1x1' },
  ];

  const sizeOption = [
    { label: '160x600', value: '160x600' },
    { label: '240x400', value: '240x400' },
    { label: '300x250', value: '300x250' },
    { label: '300x300', value: '300x300' },
    { label: '300x500', value: '300x500' },
    { label: '300x600', value: '300x600' },
    { label: '320x50', value: '320x50' },
    { label: '320x100', value: '320x100' },
    { label: '320x480', value: '320x480' },
    { label: '336x280', value: '336x280' },
    { label: '480x320', value: '480x320' },
    { label: '580x400', value: '580x400' },
    { label: '728x90', value: '728x90' },
    { label: '970x250', value: '970x250' },
    { label: '970x90', value: '970x90' },
    { label: '1080x1920', value: '1080x1920' },
    { label: i18next.t('creative.create_creative.Set_sizes'), value: '1x1' },
  ];
  

  /** Задает значение размера по умолчанию */
  const defaultSizeOption: RUseCreativeSize['defaultSizeOption'] = (size) => {
    if (!size) {
      return [];
    }
    const data: SelectField[] =
      type === 'VIDEO_VAST' ? sizeOptionVideoVast : sizeOption;
    const defaultValue = data.filter((v) => v.value === size);
    if (defaultValue.length) {
      return defaultValue;
    }

    return [
      {
        label: i18next.t('creative.create_creative.Set_sizes'),
        value: '1x1',
      },
    ];
  };

  return {
    validate,
    sizeOption: type === 'VIDEO_VAST' ? sizeOptionVideoVast : sizeOption,
    defaultSizeOption,
    checkSizes,
  };
};
