import { useGetFieldConfigValue } from 'domains/creative/creatives/hooks/useGetFieldConfigValue';
import { TTypeHookProps, TFormikCreativeAdd } from 'domains/creative/types';
import { FormikHelpers, FormikState, FormikHandlers } from 'formik';
import { CSSProperties, useEffect, useState } from 'react';
import { useSpring } from 'react-spring';
import { SelectField } from 'types/general';
import { useCheckGridEridInfo } from '../../hooks/useCheckGridEridInfo';

export interface IUseTypeProp {
  formik: FormikState<TFormikCreativeAdd> &
    FormikHandlers &
    FormikHelpers<TFormikCreativeAdd>;
  defaultSizeOption: (v: string) => SelectField[] | [];
}

type TRUseType = TTypeHookProps

const useType = ({
  formik: { values/* , setFieldValue  */},
  defaultSizeOption,
}: IUseTypeProp): TRUseType => {
  const [toggle, setToggle] = useState<boolean>(false);

  const erid = useGetFieldConfigValue('erid', values.type);

  useCheckGridEridInfo({eridData: erid, eridVal: values.erid}) 

/*   useEridValue(
    values.data,
    setFieldValue,
  ); */

  const showBlock = useSpring<CSSProperties>(
    !toggle
      ? {
          height: 'auto',
          visibility: 'visible',
          opacity: 1,
          transform: 'translateY(0)',
          config: { duration: 120 },
          delay: 0,
          marginBottom: '24px',
        }
      : {
          opacity: 0,
          height: 0,
          visibility: 'hidden',
          transform: 'translateY(-50px)',
          delay: 30,
          marginBottom: '0px',
        },
  );

  useEffect(() => {
    if (values.size) {
      if (defaultSizeOption(values.size)) {
        if (defaultSizeOption(values.size)[0].value === '1x1') {
          setToggle(false);
        } else {
          setToggle(true);
        }
      }
    } else {
      setToggle(true);
    }
  }, [values.size]);

  return {
    showBlock,
    erid,
    setToggle,
  };
};

export default useType;
