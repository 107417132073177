import { useAgencyInfo, useAuditDictionaryList } from "domains/agency/model/selectors";
import {
  editAgency,
  fetchAddAuditDictionary,
  fetchAgencyInfo,
  fetchAuditDictionaryList,
  fetchEditAuditDictionary,
} from 'domains/agency/reducer';
import { AgencyInfo, TGetAuditDictionary } from "domains/agency/types";
import { FormikErrors, useFormik } from "formik";
import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { useDispatchApp } from "redux/rootSelectors";
import i18next from "i18next";
import { useSearchBreadcrumbsInfo } from "domains/search/model/selectors";
import { fetchCampaignExperimental } from "domains/campaign/model/actions";
import { TExperimentalInfoField } from "domains/campaign/types";
import { IRacalcMethodParams, useRecalculationMethod } from "utils/MethodRecalcHelpers/useRecalculationMethod";

export type TAddAuditData = {
  title: string;
  status: string;
};

type TuseSettings = (
  SRMethod: string,
) => {
  AgencyData: AgencyInfo | null;
  isLoadingAgency: any;
  formik: any;
  isLoading: boolean;
  editAuditDictionary: (id: number, editedData: TAddAuditData) => void;
  formikAudit: any;
  formikIntermediateAudit: any;
  handlerInput: (currentSubmitHandler: () => void) => void;
  setCurrentIntermediateData: Dispatch<SetStateAction<TGetAuditDictionary | undefined>>;
  auditLoading: boolean;
  auditDictionaryList: TGetAuditDictionary[] | null;
  commissionAgencyData: TExperimentalInfoField;
  commissionSelfServiceData: TExperimentalInfoField;
  cpcData: TExperimentalInfoField | undefined;
  marginalityData: TExperimentalInfoField | undefined;
};

export const useSettings: TuseSettings = (SRMethod) => {
  const dispatch = useDispatchApp();
  const { data: AgencyData, isLoading: isLoadingAgency } = useAgencyInfo();
  const { data: auditDictionaryList, isLoading: auditLoading } = useAuditDictionaryList();
  const { data: breadcrumbs } = useSearchBreadcrumbsInfo();
  const { id: paramId } = useParams<{ id: string }>();

  const [filteredAudits, setFilteredAudits] = useState<TGetAuditDictionary[]>([]);
  const [currentIntermediateData, setCurrentIntermediateData] = useState<TGetAuditDictionary>();
  const [itemToAdd, setItemToAdd] = useState<string>();
  const [updatedAuList, setUpdatedAuList] = useState<boolean>(false);

  const editAgencyDispatch = (
    params: IRacalcMethodParams,
    callbacks: Record<string, Dispatch<SetStateAction<boolean>>>,
  ) => {
    dispatch(
      editAgency({
        id: breadcrumbs?.Agency.xxhash as string,
        ...params,
        callbacks,
      }),
    );
  };

  const {
    formik,
    isLoading,
    commissionAgencyData,
    commissionSelfServiceData,
    cpcData,
    marginalityData,
  } = useRecalculationMethod(AgencyData, editAgencyDispatch, SRMethod);

  const previousBreadcrumbsXxhash = useRef<string>();

  const addNewAuditDictionary = (data: string[]): void => {
    if (breadcrumbs && breadcrumbs.Agency) {
      dispatch(
        fetchAddAuditDictionary({
          data: {
            title: data,
            status: 'LAUNCHED',
            partner_xxhash: breadcrumbs.Agency.xxhash,
          },
        }),
      );
    }
  };

  const editAuditDictionary = (id: number, editedData: TAddAuditData): void => {
    if (breadcrumbs && breadcrumbs.Agency) {
      dispatch(
        fetchEditAuditDictionary({
          id,
          data: {
            ...editedData,
            partner_xxhash: breadcrumbs.Agency.xxhash,
          },
        }),
      );
    }
  };

  useEffect(() => {
    if (updatedAuList && auditDictionaryList) {
      const filteredArr = auditDictionaryList.filter(
        (item) => item.status === 'LAUNCHED',
      );
      setFilteredAudits(filteredArr);
    }
  }, [auditDictionaryList, updatedAuList]);

  useEffect(() => {
    if (
      breadcrumbs &&
      breadcrumbs.Agency &&
      breadcrumbs.Agency.xxhash !== previousBreadcrumbsXxhash.current
    ) {
      setUpdatedAuList(true);
      dispatch(
        fetchAuditDictionaryList({
          partner_xxhash: breadcrumbs.Agency.xxhash,
        }),
      );
      previousBreadcrumbsXxhash.current = breadcrumbs.Agency.xxhash;
    }
  }, [breadcrumbs]);

  useEffect(() => {
    if (paramId === '') return;
    dispatch(fetchAgencyInfo({ id: paramId }));
    dispatch(fetchCampaignExperimental());
  }, [paramId]);

  const formikAudit = useFormik<TGetAuditDictionary[]>({
    enableReinitialize: true,
    initialValues: filteredAudits,
    validate: (values: TGetAuditDictionary[]) => {
      const errors: FormikErrors<TGetAuditDictionary> = {};

      values.forEach((item, index) => {
        if (item.title.length < 1 || item.title.length > 20) {
          if (!errors[index]) {
            errors[index] = {};
          }
          errors[index].title = i18next.t(`errors.err45`);
        }
      });

      return errors;
    },
    onSubmit: () => {
      if (formikAudit.isValid && formikAudit.errors) {
        if (auditDictionaryList && currentIntermediateData) {
          editAuditDictionary(+currentIntermediateData?.id, {
            title: currentIntermediateData?.title,
            status: currentIntermediateData?.status,
          });
          localStorage.setItem('isAuditEdited', JSON.stringify(currentIntermediateData?.id));
        }
      }
    },
  });

  const formikIntermediateAudit = useFormik<TGetAuditDictionary[]>({
    enableReinitialize: true,
    initialValues: [],
    validate: (values: TGetAuditDictionary[]) => {
      const errors: FormikErrors<TGetAuditDictionary> = {};

      values.forEach((item, index) => {
        if (item.title.length < 1 || item.title.length > 20) {
          if (!errors[index]) {
            errors[index] = {};
          }
          errors[index].title = i18next.t(`errors.err45`);
        }
      });

      return errors;
    },
    onSubmit: () => {
      if (formikIntermediateAudit.isValid && formikIntermediateAudit.errors) {
        if (auditDictionaryList && itemToAdd) {
          formikIntermediateAudit.setValues([]);
          addNewAuditDictionary([itemToAdd]);
          localStorage.setItem('isLastItemAdded', '');
        }
      }
    },
  });

  const handlerInput = useCallback((currentSubmitHandler: () => void, item?: string): void => {
    currentSubmitHandler();
    setItemToAdd(item);
  }, [AgencyData, auditDictionaryList]);

  return {
    AgencyData,
    isLoadingAgency,
    formik,
    isLoading,
    editAuditDictionary,
    formikAudit,
    formikIntermediateAudit,
    handlerInput,
    setCurrentIntermediateData,
    auditLoading,
    auditDictionaryList,
    commissionAgencyData,
    commissionSelfServiceData,
    cpcData,
    marginalityData
  };
};
