import { useCallback, useEffect, useMemo, useState } from 'react';

type Tag = string;

export type TFUseTagsAutoSaveProps = {
  tagsData: string[];
  handlerData?: (p: string[]) => void;
  /** временная метка прошлых состояний */
  LTUPast?: number;
  handleRevert?: () => void;
  /** теги с ошибками */
  isErrorTags?: string[];
};

type TFUseTagsAutoSave = (props: TFUseTagsAutoSaveProps) => {
  tags: Tag[];
  handlerTagAdd: (tag: Tag) => void;
  handlerRemoveTag: (tag: Tag) => void;
  handlerUpdateTag: (tag: Tag, index: number) => void;
  handlerTagListAdd: (taglist: Tag) => void;
  counter: number;
  handlerLocalRevert: () => void;
  isTagsWithError: boolean;
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  startCreateList: boolean;
  setStartCreateList: React.Dispatch<React.SetStateAction<boolean>>;
  textareaValue: string;
  setTextareaValue: React.Dispatch<React.SetStateAction<string>>;
  closingAmin: boolean;
  setClosingAmin: React.Dispatch<React.SetStateAction<boolean>>;
  startIndexNewItem: number | null;
};

const useTagsAutoSave: TFUseTagsAutoSave = ({
  tagsData,
  handlerData,
  LTUPast,
  handleRevert,
  isErrorTags,
}) => {
  const [tags, setTags] = useState<Tag[]>([...tagsData]);
  const [search, setSearch] = useState<string>('');
  const [counter, setCounter] = useState<number>(0);

  const [startCreateList, setStartCreateList] = useState<boolean>(false);
  const [textareaValue, setTextareaValue] = useState<string>('');
  const [closingAmin, setClosingAmin] = useState<boolean>(false);
  const [startIndexNewItem, setStartIndexNewItem] = useState<number | null>(null);

  useEffect(() => {
    let timer: any;

    if (!startCreateList) {
      timer = setTimeout(() => {
        setClosingAmin(false);
      }, 400);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [closingAmin, startCreateList]);

  const handlerTagAdd: ReturnType<TFUseTagsAutoSave>['handlerTagAdd'] =
    useCallback(
      (tag) => {
        if (tags.every((value) => value !== tag)) {
          setTags((prev) => [...prev, tag]);
        }
      },
      [tags],
    );

  const handlerRemoveTag: ReturnType<TFUseTagsAutoSave>['handlerRemoveTag'] =
    useCallback(
      (tag) => {
        setTags((prev) => [...prev.filter((value) => value !== tag)]);
      },
      [tags],
    );

  const handlerUpdateTag: ReturnType<TFUseTagsAutoSave>['handlerUpdateTag'] =
    useCallback(
      (tag, index) => {
        setTags((prev) => prev.map((value, i) => (i === index ? tag : value)));
      },
      [tags],
    );

  const handlerLocalRevert = () => {
    if (handleRevert) {
      handleRevert();
    }
    setCounter(0);
  };

  const handlerTagListAdd: ReturnType<TFUseTagsAutoSave>['handlerTagListAdd'] = useCallback(
    (taglist) => {
      // Фиксируем при первом срабатывании сколько тего уже есть, 
      // и записываем индес, с которого потом подсвечивать новые теги
      if (startIndexNewItem === null) {
        setStartIndexNewItem(tags.length)
      }
      const newTags = new Set(tags);
      const items = taglist.split(/\r?\n/);

      items.forEach(item => {
        const trimmedItem = item.trim();
        if (trimmedItem) {
          newTags.add(trimmedItem);
        }
      });

      setTags(Array.from(newTags));

      setTextareaValue('');
      setStartCreateList(false)
    },
    [tags],
  );

  const listenerCtrlZ = (event: KeyboardEvent) => {
    if (event.code === 'KeyZ') {
      event.preventDefault();
      handlerLocalRevert();
    }
  };

  const isTagsWithError = useMemo<boolean>(() => {
    if (isErrorTags) {
      const tagsWithFilter = tags.filter((tag) => isErrorTags.includes(tag));
      return !!tagsWithFilter.length;
    }
    return false;
  }, [tags, isErrorTags]);

  useEffect(() => {
    setTags(() => [...tagsData]);
  }, [tagsData]);

  useEffect(() => {
    if (handlerData && JSON.stringify(tags) !== JSON.stringify(tagsData)) {
      handlerData(tags);
    }
  }, [tags]);

  useEffect(() => {
    if (LTUPast) {
      setCounter(5);
    }
  }, [LTUPast]);

  useEffect(() => {
    if (counter > 0) {
      setTimeout(() => {
        setCounter((prev) => prev - 1);
      }, 1000);
    }
  }, [counter]);

  useEffect(() => {
    document.addEventListener('keyup', listenerCtrlZ);
    return () => {
      document.removeEventListener('keyup', listenerCtrlZ);
    };
  }, []);

  return {
    tags,
    handlerTagAdd,
    handlerRemoveTag,
    handlerUpdateTag,
    handlerLocalRevert,
    counter,
    isTagsWithError,
    search,
    setSearch,
    startCreateList,
    setStartCreateList,
    textareaValue,
    setTextareaValue,
    handlerTagListAdd,
    closingAmin,
    setClosingAmin,
    startIndexNewItem,
  };
};

export default useTagsAutoSave;