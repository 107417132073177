import { useClientInfo, useGroupList } from '../../model/selectors';
import { IError, ResponseInfoClient, TGroupList } from '../../types';

interface groupReturn {
  groups: TGroupList | null;
  clientInfo: ResponseInfoClient | null;
  isLoading: boolean;
  error?: IError;
}

export function useGetGroupList(): groupReturn {
  const { data: clientInfo } = useClientInfo();
  const groups = useGroupList().get('data');
  const isLoading = useGroupList().get('isLoading');
  const error = useGroupList().get('error');

  return { groups, clientInfo, isLoading, error };
}
