import React, { FC, useState } from 'react';
import ModalOverlay from 'components/Modals/ModalOverlay1';
import i18next from 'i18next';
import SaveProcessing from 'components/SaveProcessing';
import cn from 'classnames';
import css from './styles.module.scss';
import Button from '../../../components/UI/Buttons/buttons';

type Props = {
  /** Видимость модвльного окна */
  isOpen?: boolean;
  /** Закрытие модального окна */
  onClose: () => void;
  /** Функция обрабатывает при отмене в модальном окне */
  onClickNegative?: () => void;
  /** Функция отрабатывающая при подтверждении в модальном окне */
  onClick: () => void;
  /** Заголовок кнопки нажатия */
  btnTitle?: string;
  /** заголовок кнопки закрытия */
  btnCloseTitle?: string;
  /** Заголовок элемента */
  title?: string;
  /** описание модалки */
  subText: string | JSX.Element;
  /** Заголовок модалки */
  modalName: string;
};

const ModalWithQuestion: FC<Props> = ({
  isOpen = true,
  onClose,
  title,
  btnTitle = i18next.t('delete_btn'),
  btnCloseTitle = i18next.t('cancel_btn'),
  modalName,
  subText,
  onClickNegative,
  onClick,
}) => {
  const [buttonBlock, setButtonBlock] = useState<boolean>(false);
  const buttonClickHandler = () => {
    setButtonBlock(true);
    onClick();
  };
  return (
    <ModalOverlay onCloseModal={onClose} isOpen={isOpen}>
      <div className={css.card}>
        <div className={css.header}>
          <div className={css.title}>
            <h2>{modalName}</h2>
          </div>
        </div>
        <div className={css.text}>
          {subText}
          {title && <strong> {title}</strong>}?
        </div>
        <div className={css.actions}>
          <Button
            transparent
            title={`${btnCloseTitle}`}
            buttonAttributes={{
              style: {
                height: 40,
              },
              onClick: onClickNegative ?? onClose,
            }}
            autoWidth
          />
          <Button
            title={`${btnTitle}`}
            autoWidth
            buttonAttributes={{
              style: {
                height: 40,
              },
              disabled: buttonBlock,
              onClick: buttonClickHandler,
            }}
          />
        </div>
        <div
          className={cn(css.loading, {
            _isShow: buttonBlock,
          })}
        >
          <SaveProcessing />
        </div>
      </div>
    </ModalOverlay>
  );
};

export default ModalWithQuestion;
