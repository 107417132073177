import React, { FC, useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FiHelpCircle } from 'react-icons/fi';
import { useIsUserNotification } from 'domains/user/model/selectors';
import { useDispatchApp } from 'redux/rootSelectors';
import {
  editSubscriptionRequest,
  fetchNotificationSubscriptionsRequest,
  NotificationState,
} from '../../domains/notification/reducer';
import ToggleSwitch from '../UI/ToggleSwitch';
import { AppState } from '../../redux/rootReducer';
import { Subscripbe } from '../../domains/notification/types';
import Card from '../UI/Card';
import css from './styles.module.scss';
import Tooltip from '../UI/Tooltip';
import OnlyOwner from '../OnlyOwner';

type Props = {
  entity_id: Subscripbe['entity_id'];
  entity_type: Subscripbe['entity_type'];
};

const Subscription: FC<Props> = ({ entity_id, entity_type }) => {
  const { isUserNotification } = useIsUserNotification();
  const { t } = useTranslation();
  const dispatch = useDispatchApp();
  const { isErrorBrowserNotification, subscriptions } = useSelector<
    AppState,
    NotificationState
  >(({ notificationReducer }) => notificationReducer, shallowEqual);
  const notification = subscriptions.get('data');
  const isLoading = subscriptions.get('isLoading');
  const isNotification = () => {
    if (notification) {
      const data = notification.filter(
        (subscribe) =>
          subscribe.entity_type === entity_type &&
          subscribe.entity_id === entity_id,
      );
      if (data.length) {
        return true;
      }
    }

    return false;
  };

  const [subscribe, setSubScribe] = useState(() => isNotification());
  const [togglePush, setTogglePush] = useState<boolean>(false);

  useEffect(() => {
    if (notification === null && !togglePush) {
      dispatch(fetchNotificationSubscriptionsRequest());
    }
    setSubScribe(() => isNotification());
  }, [notification]);

  return (
    // eslint-disable-next-line no-inline-styles/no-inline-styles
    <Card noHover style={{ padding: '20px 14px' }} className={css.card}>
      <div className={css.notifications}>
        <div className={css.title}>
          <span>
            {t('campaigns_page.campaign_settings.push_notifications.title')}
          </span>
          <Tooltip title={<FiHelpCircle size={24} />} hintWidth={300}>
            <p>
              {t(
                entity_type === 'Campaign'
                  ? 'campaigns_page.campaign_settings.push_notifications.hint'
                  : 'campaigns_page.campaign_settings.push_notifications.hint2',
              )}
            </p>
          </Tooltip>
        </div>
        {!isLoading && (
          <OnlyOwner>
            <div className={css.switch}>
              <ToggleSwitch
                disabled={!isUserNotification || isErrorBrowserNotification}
                toggleOnly
                onChange={() => {
                  setTogglePush(true);
                  dispatch(
                    editSubscriptionRequest({
                      active: !subscribe,
                      entity_type,
                      entity_id,
                    }),
                  );
                }}
                checked={subscribe}
              />
            </div>
          </OnlyOwner>
        )}
      </div>
    </Card>
  );
};
export default Subscription;
