import React, { CSSProperties, FC } from 'react';
import { FiLock, FiX } from 'react-icons/fi';
import cn from 'classnames';
import { useUserTheme } from 'domains/user/model/selectors';
import css from './styles.module.scss';
import { useIsGroup } from '../../../domains/user/hooks';
import i18n from '../../../i18n';

type CardProps = {
  className?: string;
  style?: CSSProperties;
  noHover?: boolean;
  locked?: boolean;
  transparent?: boolean;
  isUseAccess?: boolean;
  isGround?: boolean;
  textHint?: React.ReactNode;
  showHint?: boolean;
  positionHint?: number;
  closeHint?: () => void;
  hintClassName?: string;
};

const Card: FC<CardProps> = ({
  children,
  className,
  style,
  noHover,
  locked,
  transparent,
  isUseAccess = false,
  textHint,
  isGround,
  showHint,
  closeHint,
  positionHint,
  hintClassName,
}) => {
  const { isLocked } = useIsGroup();
  const { getValueFromSettingForKey } = useUserTheme();

  return (
    <div
      className={cn(css.card, className, 'Card_component', {
        isNoHover: noHover,
        isLocked: locked || (isUseAccess && isLocked),
        isTransparent: transparent,
        isGround,
      })}
      style={style}
    >
      <div className={css.lock}>
        <FiLock size={24} />
        <div className={css.lock__info}>
          <span>{i18n.t(`disable_edit`)}</span>
        </div>
      </div>
      {children}
      {showHint &&
        textHint &&
        getValueFromSettingForKey('ShowHint') !== null &&
        !getValueFromSettingForKey('ShowHint') && (
          <div
            className={cn(css.card__hint, 'card_hint', hintClassName, {
              _isClose: !showHint,
            })}
            style={{
              top: `${positionHint}px`,
            }}
          >
            <span>{textHint}</span>
            <div className={css.card__hintClose} onClick={closeHint}>
              <FiX size={24} />
            </div>
          </div>
        )}
    </div>
  );
};

export default Card;
