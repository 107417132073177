import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import { useDispatchApp } from 'redux/rootSelectors';
import { AppState } from 'redux/rootReducer';
import { APIUsersState, delLastId, setLastId } from '../../domains/usersAPI/model/reducer';

/** возврощает последний посещенный ид */
export function useHighlights(): string {
  const dispatch = useDispatchApp();
  const [id, setId] = useState<string>('');
  const history = useHistory();
  const lastId = useSelector<AppState, APIUsersState['lastId']>(
    ({ APIUsersReducer }) => APIUsersReducer.lastId,
    shallowEqual,
  );

  useEffect(() => {
    if (!lastId) return;
    const url = new URL(window.location.href);
    url.searchParams.set('highlights', lastId);
    history.push(url.search);
  }, [lastId]);

  useEffect(() => {
    const url = new URL(window.location.href);
    const highlights = url.searchParams.get('highlights') ?? '';
    dispatch(setLastId(highlights));
    setId(highlights);
  }, [window.location.href]);

  return id;
}
/** устанвливает последний посещенный ид из сылки */
export function useSetLastIdUsersAPI(): void {
  const dispatch = useDispatchApp();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const email = query.get('email');
  useEffect(() => {
    if (!email) return;
    dispatch(setLastId(email));
  }, [email]);
}
/** удаляет последний посещеный ид */
export function useDelLastIdUsersAPI(): () => void {
  const dispatch = useDispatchApp();
  return useCallback(() => {
    dispatch(delLastId());
  }, []);
}
