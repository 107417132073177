import { FormikProps, useFormik } from 'formik';
import React, { SetStateAction, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router';
import { useCampaignsInfo } from 'domains/campaigns/model/selectors';
import { useDispatchApp } from 'redux/rootSelectors';
import isEqual from 'lodash/isEqual';
import {
  validateFormGroup,
  validateSchemeGroup,
} from 'domains/campaign/model/shared/validateForm';
import { objectOptimizationWithFormik } from 'utils/objectOptimizationWithFormik/objectOptimizationWithFormik';
import { IErrorState, IGroupItem, PSetGroup } from 'domains/client/types';
import { createGroup } from 'domains/client/reduser';
import { TID } from 'types/general';
import { useCurrency } from 'domains/project/hooks';

type TFUseCreateGroupForm = {
  formik: FormikProps<PSetGroup>;
  onKeyDown: (v: React.KeyboardEvent) => void;
  isLoading: ReturnType<typeof useCampaignsInfo>['isLoading'];
  campaignsXxhash: string[];
  setCampaignsXxhash: React.Dispatch<React.SetStateAction<string[]>>;
  resetForm: () => void;
  isResetCampaigns: boolean;
  setIsResetCampaigns: React.Dispatch<React.SetStateAction<boolean>>;
  currency: string;
};

export const useCreateGroupForm = (
  onClose: () => void,
  open: boolean,
  errorDescription: IErrorState,
  setErrorDescription: React.Dispatch<SetStateAction<IErrorState>>,
  groupInfo?: IGroupItem,
): TFUseCreateGroupForm => {
  const dispatch = useDispatchApp();
  const { isLoading } = useCampaignsInfo();
  const { params } = useRouteMatch<TID>();
  const [campaignsXxhash, setCampaignsXxhash] = useState<string[]>([]);
  const [isResetCampaigns, setIsResetCampaigns] = useState(false);
  const { currency } = useCurrency();

  const resetValues: PSetGroup = {
    xxhash: params.id,
    title: '',
    limits: {
      budget: {
        total: 0,
        day: 0,
      },
      show: {
        total: 0,
        day: 0,
      },
      show_per_user: {
        total: 0,
        day: 0,
      },
      click: {
        total: 0,
        day: 0,
      },
    },
    campaign_xxhash_list: [],
  };

  let initialValues = resetValues;

  if (groupInfo) {
    initialValues = {
      id: groupInfo.group.id,
      xxhash: params.id,
      title: groupInfo.group.title,
      callbacks: { onClose },
      limits: {
        ...groupInfo.group.limits,
      },
      campaign_xxhash_list: [...groupInfo.campaigns.map((item) => item.xxhash)],
    };
  }

  const resetForm = () => {
    formik.resetForm({
      values: resetValues,
      errors: {},
    });
    setCampaignsXxhash([]);
    setIsResetCampaigns(true);
    setErrorDescription({
      errorCode: 0,
      errorText: '',
    });
  };

  const formik = useFormik<PSetGroup>({
    initialValues,
    validate: validateFormGroup,
    validationSchema: validateSchemeGroup,
    onSubmit: (values) => {
      const paramData = objectOptimizationWithFormik<PSetGroup>(
        initialValues,
        values,
      );
      let groupParams: PSetGroup = {
        ...paramData,
        xxhash: params.id,
        title: values.title.toLowerCase(),
        callbacks: { onClose },
        limits: {
          ...values.limits,
        },
        campaign_xxhash_list: [...campaignsXxhash],
      };

      if (groupInfo) {
        groupParams = {
          ...paramData,
          id: groupInfo.group.id,
          xxhash: params.id,
          title: values.title.toLowerCase(),
          callbacks: { onClose },
          limits: {
            ...values.limits,
          },
          campaign_xxhash_list: [...campaignsXxhash],
        };
      }

      if (groupInfo && isEqual(groupParams, initialValues)) {
        onClose();
        return;
      }
      dispatch(createGroup(groupParams));
    },
  });

  const onKeyDown: TFUseCreateGroupForm['onKeyDown'] = (keyboardEvent) => {
    const { charCode, keyCode } = keyboardEvent;
    if ((charCode || keyCode) === 13) {
      keyboardEvent.preventDefault();
    }
  };

  useEffect(() => {
    formik.resetForm({
      values: initialValues,
    });
    setCampaignsXxhash([]);
    setErrorDescription({
      errorCode: 0,
      errorText: '',
    });
  }, [open]);

  useEffect(() => {
    if (errorDescription.errorText) {
      setErrorDescription({
        errorCode: 0,
        errorText: '',
      });
    }
  }, [formik.values.title]);

  return {
    formik,
    onKeyDown,
    isLoading,
    campaignsXxhash,
    setCampaignsXxhash,
    resetForm,
    isResetCampaigns,
    setIsResetCampaigns,
    currency,
  };
};
