import React, { FC } from 'react';
import cn from 'classnames';
import './styles.scss';
import { FiArrowUp, FiArrowDown } from 'react-icons/fi';
import { useDetectFinishScroll } from 'utils/useDetectFinishScroll';
import { useOnScroll } from '../../hooks/useOnScroll';

interface IScrollToTopButtonProps {
  mode?: 'SCROLLBOTH';
}

const ScrollToTopButton: FC<IScrollToTopButtonProps> = ({ mode }) => {
  const { fixed } = useOnScroll(58);
  const { endBody } = useDetectFinishScroll();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const handleScroll = () => {
    if (mode === 'SCROLLBOTH') {
      if (endBody) {
        scrollToTop();
      } else {
        window.scrollTo(0, document.body.scrollHeight);
      }
    } else {
      scrollToTop();
    }
  };

  if (mode === 'SCROLLBOTH') {
    return (
      <div
        className={cn('scroll-to-top-button', {
          isVisible: mode === 'SCROLLBOTH',
        })}
        onClick={handleScroll}
      >
        {endBody ? <FiArrowUp size={24} /> : <FiArrowDown size={24} />}
      </div>
    );
  }

  return (
    <div
      className={cn('scroll-to-top-button', {
        isVisible: fixed,
      })}
      onClick={handleScroll}
    >
      <FiArrowUp size={24} />
    </div>
  );
};
export default ScrollToTopButton;
