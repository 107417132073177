import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TContractStatus } from 'domains/contract/model/types';
import { FetchedData, genFetchedData } from '../../../redux/fetchedData';
import { ContractsList } from './types';

export type contractsState = {
  contractsList: FetchedData<ContractsList>;
  lastId: string;
  lastStatus: TContractStatus;
};

export const initialState: contractsState = {
  contractsList: genFetchedData<ContractsList>(null),
  lastId: '',
  lastStatus: '',
};

const contractsSlice = createSlice({
  name: 'Contracts',
  initialState,
  reducers: {
    setContractsList(
      state,
      action: PayloadAction<contractsState['contractsList']>,
    ) {
      state.contractsList = action.payload;
    },
    setLastId(state, action: PayloadAction<contractsState['lastId']>) {
      state.lastId = action.payload;
    },
    setLastStatus(state, action: PayloadAction<contractsState['lastStatus']>) {
      state.lastStatus = action.payload;
    },
    delLastId(state) {
      state.lastId = initialState.lastId;
    },
    delLastStatus(state) {
      state.lastStatus = initialState.lastStatus;
    },
  },
});

export const {
  setContractsList,
  setLastId,
  setLastStatus,
  delLastId,
  delLastStatus,
} = contractsSlice.actions;
export const contractsReducer = contractsSlice.reducer;
