import { Form, FormikProvider } from 'formik';
import React, { FC } from 'react';
import { useCampaignExperimental } from 'domains/campaign/Experimental/hooks';
import { useIsGroup } from 'domains/user/hooks';
import { useEditCampaignForm } from './hook/useEditCampaignForm';
import Period from '../components/Period';
import Optimization from '../components/Optimization';
import AdvancedOptimization from '../components/AdvancedOptimization';
import Container from '../components/Container';
import Limits from '../components/Limits';
import Campaign from '../components/Campaign';

type EditCampaignProps = Parameters<typeof useEditCampaignForm>[0];

const EditCampaign: FC<EditCampaignProps> = ({ campaign, pastCampaign }) => {
  const { isOwner } = useIsGroup();
  const { experimentalInfo } = useCampaignExperimental()

  const {
    formik,
    companyTags,
    handlerUpdateTags,
    LTUPastTags,
    handlerRevertTags,
    isSavedTags,
    errorTags,
    handlerInput,
    savedField,
    currency,
    clients,
    advancedList,
    isRawStat,
    isTest,
    setField,
    locked,
    resetForm,
    offersOptions,
    contractsOptions,
  } = useEditCampaignForm({ campaign, pastCampaign, experimentalInfo });

  return (
    <FormikProvider value={formik}>
      <Form>
        <Campaign
          setField={setField}
          experimentalInfo={experimentalInfo}
          formik={formik}
          campaign={campaign}
          savedField={savedField}
          tags={companyTags}
          errorTags={errorTags}
          handlerInput={handlerInput}
          handlerRevertTags={handlerRevertTags}
          handlerUpdateTags={handlerUpdateTags}
          isSavedTags={isSavedTags}
          LTUPastTags={LTUPastTags}
          clients={clients}
          clientDisable
          autoSubmit
          locked={locked}
          offersOptions={offersOptions}
          contractsOptions={contractsOptions}
          type='EDIT'
        />
        <Period
          savedField={savedField}
          formik={formik}
          showButton
          resetForm={resetForm}
        />
        <Optimization
          savedField={savedField}
          experimentalInfo={experimentalInfo}
          setField={setField}
          formik={formik}
          currency={currency}
          autoSubmit
        />
        <Limits
          resetForm={resetForm}
          showButton
          formik={formik}
          currency={currency}
          isRawStat={isRawStat}
          isTest={isTest}
          savedField={savedField}
        />
        {isOwner && <AdvancedOptimization
          setField={setField}
          formik={formik}
          advancedList={advancedList}
          autoSubmit
        />}
        <Container
          campaign={campaign}
          handlerInput={handlerInput}
          savedField={savedField}
          formik={formik}
          setField={setField}
        />
      </Form>
    </FormikProvider>
  );
};

export default EditCampaign;
