import React, { FC } from 'react';
import cn from 'classnames';
import avatarColors from 'utils/avatarColors';
import { NavLink, useLocation } from 'react-router-dom';
import { CounteragentItem } from 'domains/counteragent/model/types';
import { useOrdList } from 'domains/counteragents/model/selectors';
import css from '../styles.module.scss';

interface Props {
  counteragentData: CounteragentItem;
  isLastEdited?: boolean;
}

const Item: FC<Props> = ({ counteragentData, isLastEdited }) => {
  const { name, uuid, ord, exportName, aliases, inn } = counteragentData;
  const location = useLocation();
  const { data: ordList } = useOrdList();

  return (
    <div
      className={cn(css.item_wrapper, {
        _isLastEdited: isLastEdited,
      })}
    >
      <div className={css.indicator} />
      <div className={css.item}>
        <NavLink
          to={{
            ...location,
            pathname: `counteragent/${uuid}/edit`,
          }}
        />
        <div className={css.item_header}>
          <div
            className={css.img}
            style={
              /* item.icon
                ? {
                    backgroundImage: `url(${item.icon})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundColor: 'white',
                  }
                :  */ {
                backgroundColor: avatarColors(name?.slice(0, 1).toLowerCase()),
              }
            }
          >
            {/* item.icon ? '' :  */ name?.slice(0, 1).toUpperCase()}
          </div>
          <div className={css.item_info}>
            <div className={css.item_title}>{name}</div>
            <div className={css.item_id}>{uuid}</div>
          </div>
        </div>
        <div>{exportName}</div>
        <div>{aliases}</div>
        <div>{inn}</div>
        <div>
          {ordList?.find(({ id }) => id === ord)?.title || 'Неизвестная ОРД'}
        </div>
      </div>
    </div>
  );
};

export default Item;
