import React, { useCallback, useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { FormikHelpers } from 'formik';
import { FetchedDataErrors, TFileParam } from 'types/general';
import { counterSecond } from 'utils/duringVideo/countSec';
import { useDispatchApp } from 'redux/rootSelectors';
import { AppState } from '../../../redux/rootReducer';
import { TSizes, TSizesZip, TParamVideo } from '../helpers';
import { TUploadFile } from '../../../utils/upload';
import { UploadState, setTempClearFile, setFormDefault } from '../reducer';
import { fetchImageRequest } from '../model/actions';
import { TKeyUploadFile, TParamFiles, TTempFile } from '../types';
import { TTypeImage } from '../model/api';
import { selectorTempHTML } from '../model/selectors';

type Props = {
  /** Изображение с креатива */
  preview: TSizes | null | TSizesZip | TParamVideo;
  /** название инпута */
  nameInput: TTempFile['key'];
  /** функция Formik для изменения поля в форме */
  setFieldValue: FormikHelpers<string>['setFieldValue'];
  /** ссылка для загрузки файла */
  url: string;
  /** ограничения файла */
  fileLimits: TFileParam;
  /** вложение в объект data */
  isData: boolean;
  /** функция измения ссылка на файл */
  setUrl: (param: string) => void;
  /** индекс таба */
  tabIndex: number;
  typeSection: TTypeImage['type'];
  keyUploadFile: TKeyUploadFile;
  fileInputRef: React.RefObject<HTMLInputElement>;
};
type TPreviewState = TSizes | TUploadFile | TSizesZip | null | TParamVideo;

type TFUseCreativeUpload = (p: Props) => {
  onChangeFile: (v: React.FormEvent<HTMLInputElement>) => void;
  previewImage: TPreviewState;
  isLoading: boolean;
  error: FetchedDataErrors;
  handlerUploadFileLink: (outerUrl?: string | Blob) => void;
  handleClearStateFile: (isCleanUrl?: boolean, isDefault?: boolean, isClearVideoState?: boolean) => void;
  constructorModal: boolean;
  toggleConstructorModal: () => void;
};

export const isGardPreviewVideo = (v: TPreviewState): v is TParamVideo => true;

export const isGardVideo = (v: TPreviewState): v is TUploadFile => true;

/** Гард на проверку типа */
export const isTPreviewState = (v: TPreviewState): v is TSizes => true;
export const isVideoPoster = (v: TPreviewState): v is TUploadFile => true;

export const useCreativeUpload: TFUseCreativeUpload = ({
  preview,
  nameInput,
  setFieldValue,
  url,
  fileLimits,
  isData,
  setUrl,
  tabIndex,
  typeSection,
  keyUploadFile,
  fileInputRef,
}) => {
  const dispatch = useDispatchApp();
  const [constructorModal, setConstructorModal] = useState(false);

  const toggleConstructorModal = () => {
    setConstructorModal(!constructorModal);
  };

  const tempFile = useSelector<AppState, UploadState['tempFile']>(
    ({ uploadReducer }) => uploadReducer.tempFile,
    shallowEqual,
  );

  const { formDefault } = useSelector<AppState, UploadState>(
    ({ uploadReducer }) => uploadReducer,
    shallowEqual,
  );

  const filePreview = tempFile[nameInput].get('data');
  const isLoading = tempFile[nameInput].get('isLoading');
  const error = tempFile[nameInput].get('error');
  const LTU = tempFile[nameInput].get('LTU');

  const tempHTMLCode = useSelector(selectorTempHTML);

  const [statePreview, setStatePreview] = useState<TPreviewState>(
    () => preview,
  );

  const getOptions = (): TParamFiles => {
    if (
      keyUploadFile === 'zip_yandex_html' ||
      keyUploadFile === 'img_yandex_html' ||
      keyUploadFile === 'video'
    ) {
      return {
        type: keyUploadFile,
        field: null,
      };
    }

    if (keyUploadFile === 'teaser') {
      return {
        type: 'teaser',
        field: nameInput,
      };
    }
    if (keyUploadFile === 'multiFormat') {
      return {
        type: 'multiFormat',
        field: nameInput.slice(7, nameInput.length),
      };
    }

    return {
      type: 'push',
      field: nameInput,
    };
  };

  const onChangeFile: ReturnType<TFUseCreativeUpload>['onChangeFile'] =
    useCallback((e) => {
      const { files: fileList } = e.currentTarget;
      if (fileList === null) {
        e.currentTarget.setAttribute('value', '');
        return;
      }
      if (fileList.length === 0) {
        e.currentTarget.setAttribute('value', '');

        return;
      }
      const file = fileList[0];
      dispatch(
        fetchImageRequest({
          typeSection,
          key: nameInput,
          file,
          paramUrl: getOptions(),
          fileLimits,
        }),
      );
    }, []);

  const handlerUploadFileLink: ReturnType<TFUseCreativeUpload>['handlerUploadFileLink'] =
    useCallback(
      (outerUrl) => {
        setFieldValue('size', null);
        dispatch(
          fetchImageRequest({
            typeSection,
            key: nameInput,
            file: outerUrl ?? url,
            paramUrl: getOptions(),
            fileLimits,
          }),
        );
      },
      [url],
    );

  const handleClearStateFile: ReturnType<TFUseCreativeUpload>['handleClearStateFile'] =
    (isCleanUrl = true, isDefault = false, isClearVideoState = false) => {
      if (fileInputRef.current) {
        // eslint-disable-next-line no-param-reassign
        fileInputRef.current.value = '';
      }
      setFieldValue(isData ? `data.${nameInput}` : nameInput, '');
      if (nameInput === 'imgMask' || nameInput === 'image') {
        setFieldValue(isData ? 'data.imgFormats' : 'imgFormats', []);
      }
      if (nameInput === 'iconMask' || nameInput === 'icon') {
        setFieldValue(isData ? 'data.iconFormats' : 'iconFormats', []);
      }
      if (nameInput === 'video_url' && isClearVideoState) {
        setFieldValue(isData ? 'data.video_bitrate' : 'video_bitrate', 0);
        setFieldValue(isData ? 'data.duration' : 'duration', 0);
        setFieldValue('size', null);
        setFieldValue('data.file_size', 0);
        setFieldValue('data.video_poster_url', '');
      }
      if (
        nameInput === 'images.icon' ||
        nameInput === 'images.image_1' ||
        nameInput === 'images.image_2'
      ) {
        setFieldValue(nameInput, '');
      }
      if (isCleanUrl) {
        setUrl('');
      }
      if (isDefault && preview?.file_url) {
        setStatePreview(() => preview);
      } else {
        setStatePreview(() => null);
      }
      dispatch(setTempClearFile(nameInput));
    };
  useEffect(() => {
    if (error.isError) {
      handleClearStateFile();
    }
  }, [tabIndex]);

  useEffect(() => {
    if (url !== '') {
      dispatch(setFormDefault(false));
    }
    if (error.isError && !formDefault) {
      handleClearStateFile(false);
    }
  }, [url]);
  useEffect(() => {
    if (formDefault && (filePreview || url || error.isError)) {
      handleClearStateFile(true, true);
    }
  }, [formDefault]);

  useEffect(() => {
    if (preview?.file_url) {
      setStatePreview(() => preview);
    }
  }, [preview?.file_url]);

  useEffect(() => {
    if (preview?.file_url) {
      setStatePreview(() => preview);
    }
    return function () {
      handleClearStateFile();
    };
  }, []);

  useEffect(() => {
    if (tempHTMLCode && filePreview) {
      if (isData) {
        setFieldValue('data.html_code', tempHTMLCode);
        return;
      }
      setFieldValue('html_code', tempHTMLCode);
    }
  }, [tempHTMLCode, filePreview]);

  useEffect(() => {
    if (error.isError) {
      dispatch(setFormDefault(false));
    }
  }, [error]);

  useEffect(() => {
    if (filePreview) {
      dispatch(setFormDefault(false));
      setStatePreview(() => filePreview);
      setFieldValue(
        isData ? `data.${nameInput}` : nameInput,
        filePreview.file_url,
      );
      if (nameInput === 'imgMask' || nameInput === 'image') {
        setFieldValue(isData ? 'data.imgFormats' : 'imgFormats', [
          `${filePreview.width}x${filePreview.height}`,
        ]);
        if (isData) {
          setFieldValue('width', `${filePreview?.width}`);
          setFieldValue('height', `${filePreview?.height}`);
        }
        setFieldValue('size', `${filePreview?.width}x${filePreview?.height}`);
      }
      if (nameInput === 'iconMask' || nameInput === 'icon') {
        setFieldValue(isData ? 'data.iconFormats' : 'iconFormats', [
          `${filePreview.width}x${filePreview.height}`,
        ]);
      }
      if (nameInput === 'video_url') {
        setFieldValue(
          isData ? 'data.video_bitrate' : 'video_bitrate',
          filePreview.bitrate,
        );
        setFieldValue(
          isData ? 'data.duration' : 'duration',
          counterSecond(filePreview.duration || ''),
        );
        setFieldValue('size', `${filePreview.width}x${filePreview.height}`);
        setFieldValue('width', `${filePreview.width}`);
        setFieldValue('height', `${filePreview.height}`);
        setFieldValue('data.file_size', Math.round(filePreview.size / 1024));
        setFieldValue('data.video_poster_url', filePreview.video_poster_url);
      }
    }
  }, [LTU, filePreview]);

  return {
    onChangeFile,
    previewImage: statePreview,
    isLoading,
    error,
    handlerUploadFileLink,
    handleClearStateFile,
    constructorModal,
    toggleConstructorModal,
  };
};