import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { useCampaignsInfo } from 'domains/campaigns/model/selectors';
import { setTagsCampaigns } from 'domains/search/model/reducer';
import { useSearchBreadcrumbsInfo } from 'domains/search/model/selectors';
import {
  addActiveTagRequest,
  fetchCreativeAllTagsRequest,
  resetCreativeAllTags,
  setActiveTags,
} from '../../domains/creative/reduser';
import { KeysSettings, useStateValue } from '../../utils/context';
import { useCreativeListFilter } from '../../utils/useLastIdHelpers/useLastIdCreative';
import { useDispatchApp } from '../../redux/rootSelectors';
import { useCampaignInfo } from '../../domains/campaign/model/selectors';
import {
  useCreativeActiveTag,
  useCreativeAllTagsInfo,
} from '../../domains/creative/model/selectors';
import { useCampaignsTags } from '../../domains/search/model/hooks';

type PropsCreative = {
  isShowModal: boolean;
};

type TFUseSearchTags = (isShowModal: boolean) => {
  tagsCampaigns: ReturnType<typeof useCampaignsTags>['tagsCampaigns'];
  tagCampaignsActive: ReturnType<typeof useCampaignsTags>['tagCampaignsActive'];
};

type TFUseSearchTagsCreative = (p: PropsCreative) => {
  allTags: ReturnType<typeof useCreativeAllTagsInfo>['data'];
  activeTags: ReturnType<typeof useCreativeActiveTag>;
  isLoading: ReturnType<typeof useCreativeAllTagsInfo>['isLoading'];
  handleActiveTag: (k: string) => void;
};

export const useSearchTags: TFUseSearchTags = (isShowModal) => {
  const dispatch = useDispatchApp();
  const { pathname } = useLocation();
  const { tagsCampaigns, tagCampaignsActive } = useCampaignsTags();
  const { data: breadcrumbs } = useSearchBreadcrumbsInfo();

  const { data: campaigns } = useCampaignsInfo();

  const result = () => {
    const resultAr: string[] = [];
    if (pathname.indexOf('campaigns') + 1 && campaigns) {
      const arTags: string[] = [];
      campaigns.map((campaign) => {
        const arTitleParent = (breadcrumbs ? Object.keys(breadcrumbs) : [])
          .filter((e) => e !== 'Campaign')
          .map((key) => breadcrumbs ? breadcrumbs[key].title : key);
        return campaign.title.split('/').map((v) => {
          if (
            arTitleParent.some(
              (parent) => parent?.toLowerCase() === v.trim().toLowerCase(),
            )
          ) {
            return arTags;
          }
          arTags.push(v.trim().toUpperCase());
          return arTags;
        });
      });
      const resultObject = arTags.reduce((acc: Record<string, number>, el) => {
        // eslint-disable-next-line no-param-reassign
        acc[el] = (acc[el] || 0) + 1;
        return acc;
      }, {});
      Object.keys(resultObject).map((key) => {
        if (resultObject[key] > 1 && key !== '') {
          resultAr.push(key);
        }
        return resultAr;
      });
      return resultAr;
    }
    return [];
  };

  useEffect(() => {
    if (isShowModal && campaigns?.length && pathname.indexOf('campaigns') + 1) {
      const resultTagsAr = result();
      dispatch(setTagsCampaigns(resultTagsAr));
    }
  }, [campaigns?.length, isShowModal]);

  return {
    tagsCampaigns,
    tagCampaignsActive,
  };
};

export const useSearchTagsCreative: TFUseSearchTagsCreative = ({
  isShowModal,
}) => {
  const dispatch = useDispatchApp();
  const { state } = useStateValue();
  const { listFilter } = useCreativeListFilter();
  const { period } = state.settings[KeysSettings.CAMPAIGN];
  const { data: allTags, isLoading } = useCreativeAllTagsInfo();
  const activeTags = useCreativeActiveTag();
  const { pathname } = useLocation();
  const { data: campaign } = useCampaignInfo();

  const handleActiveTag: ReturnType<TFUseSearchTagsCreative>['handleActiveTag'] =
    (k) => {
      if (campaign) {
        dispatch(
          addActiveTagRequest({
            tag: k,
            campaign_xxhash: campaign.xxhash,
            filters: { status: listFilter },
            period,
          }),
        );
      }
    };

  useEffect(() => {
    if (
      !allTags &&
      campaign &&
      isShowModal &&
      pathname.indexOf('/creatives') + 1 &&
      !(pathname.indexOf('/creatives/') + 1)
    ) {
      dispatch(fetchCreativeAllTagsRequest({ xxhash: campaign.xxhash }));
    }
    if (!isShowModal) {
      dispatch(resetCreativeAllTags());
    }
  }, [isShowModal, pathname]);

  useEffect(() => {
    if (
      !isShowModal &&
      !!activeTags.length &&
      pathname.indexOf('/creatives') + 1
    ) {
      dispatch(setActiveTags([]));
    }
  }, [pathname]);

  return {
    allTags,
    activeTags,
    isLoading,
    handleActiveTag,
  };
};
