import React, { FC, useEffect, useRef } from 'react';
import cn from 'classnames';
import { FiSearch, FiX } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import TooltipPoratal from 'components/UI/TooltipPoratal';
import css from './styles.module.scss';

interface IMainSearchProps {
  toggleSearch: boolean;
  onOpenSearch: () => void;
  value: string;
  onChangeSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
  searchRef: React.RefObject<HTMLInputElement>;
  audienceBlockRef?: React.RefObject<HTMLDivElement>;
  onCloseSearch: () => void;
  placeholder: string;
  disabled?: boolean;
  match: number;
  isShowMatch: boolean;
  className?: string;
}

const MainSearch: FC<IMainSearchProps> = ({
  toggleSearch,
  onOpenSearch,
  value,
  onChangeSearch,
  searchRef,
  audienceBlockRef,
  onCloseSearch,
  placeholder,
  disabled,
  match,
  isShowMatch,
  className,
}) => {
  const { t } = useTranslation();
  const openIconRef = useRef<HTMLDivElement>(null);

  const handleClickOutsideSearch = (event: any) => {
    if (
      searchRef.current &&
      openIconRef.current &&
      audienceBlockRef &&
      audienceBlockRef.current &&
      !searchRef.current.contains(event.target) &&
      !openIconRef.current.contains(event.target) &&
      (event.target.classList.contains('InputAutoSaveLabel') ||
        !audienceBlockRef.current.contains(event.target))
    ) {
      searchRef.current.blur();
      onCloseSearch();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutsideSearch);
    return () => {
      document.removeEventListener('click', handleClickOutsideSearch);
    };
  }, []);

  useEffect(() => {
    searchRef?.current?.focus();
  }, [onOpenSearch]);

  return (
    <div
      className={cn(css.search, className, 'MainSearch', {
        isActive: toggleSearch,
        isDisabled: disabled,
      })}
    >
      <div className={css.icon} onClick={onOpenSearch} ref={openIconRef}>
        <TooltipPoratal hint={t(`targetings.search`)}>
          <FiSearch size={24} />
        </TooltipPoratal>
      </div>
      <div className={css.input}>
        <input
          type="text"
          autoComplete="off"
          placeholder={placeholder}
          value={value}
          onChange={onChangeSearch}
          ref={searchRef}
        />
      </div>
      {value !== '' && isShowMatch && (
        <div className={css.match}>{`${match} ${t(`found`)}`}</div>
      )}

      <div className={css.close} onClick={onCloseSearch}>
        <FiX size={24} />
      </div>
    </div>
  );
};

export default MainSearch;
