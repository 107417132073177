import { FormikProps, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import copy from 'copy-to-clipboard';
import { useUserTheme } from 'domains/user/model/selectors';
import { findEridMetaTeg } from 'domains/creative/model/helpers/findEridMetaTeg';
import { useCreativeDetailInfo } from 'domains/creative/model/selectors';
import * as Yup from 'yup';
import { TFormHTML, validate } from '../validateHTML';
import { objectOptimizationWithFormik } from '../../../../../../../utils/objectOptimizationWithFormik/objectOptimizationWithFormik';
import { setEditCreativeRequest } from '../../../../../reduser';
import { CreativeDetail, TCreativeHTML } from '../../../../../types';
import { setFormDefault } from '../../../../../../uploads/reducer';
import { useDispatchApp } from '../../../../../../../redux/rootSelectors';
import { useCreativeSize } from '../../../../../model/hooks/useCreativeSize';
import { toastSuccess } from '../../../../../../../utils/ToastUtils/toastUtils';
import i18n from '../../../../../../../i18n';

type PSentFormik = {
  xxhash: CreativeDetail['xxhash'];
  data?: TCreativeHTML;
  size?: CreativeDetail['size'];
  erid?: string;
  type: string;
};

type TUseEditProps = {
  payload: {
    xxhash: CreativeDetail['xxhash'];
    size: string;
    html_code: TCreativeHTML['html_code'];
    erid: string;
  };
  setEdit: (param: boolean) => void;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
};
type TFUseEditCreativeHTML = (props: TUseEditProps) => {
  formik: FormikProps<TFormHTML>;
  handleFormikSubmit: () => void;
  handleFormikReset: () => void;
  sizeOption: ReturnType<typeof useCreativeSize>['sizeOption'];
  defaultSizeOption: ReturnType<typeof useCreativeSize>['defaultSizeOption'];
  checkSizes: ReturnType<typeof useCreativeSize>['checkSizes'];
  handlerCopyHtml: () => void;
};

export const useEditCreativeHTML: TFUseEditCreativeHTML = ({
  payload,
  setEdit,
  setIsLoading
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatchApp();
  const { getValueFromSettingForKey } = useUserTheme();
  const { LTU } = useCreativeDetailInfo()

  const { sizeOption, defaultSizeOption, checkSizes } = useCreativeSize();
  const [width, height] = payload.size.split('x');

  const formik = useFormik<TFormHTML>({
    enableReinitialize: true,
    initialValues: {
      html_code: payload.html_code,
      size: payload.size,
      width: Number(width) || 1,
      height: Number(height) || 1,
      erid: payload.erid,
      imgMask: '',
      imgFormats: [],
    },
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    onSubmit: (values) => {
      const formValueData: TFormHTML = {
        html_code: values.html_code,
        size: '',
        erid: values.erid,
      };
      if (values.size === '1x1') {
        formValueData.size = `${values.width}x${values.height}`;
      } else if (sizeOption.some((v) => v.value === values.size)) {
        formValueData.size = values.size;
      } else if (typeof values.size === 'string' && values.size !== '') {
        const size = formik.values.size.split('x');
        if (
          values.width !== Number(size[0]) ||
          values.height !== Number(size[1])
        ) {
          formValueData.size = `${values.width}x${values.height}`;
        } else {
          formValueData.size = values.size;
        }
      } else {
        formValueData.size = values.size;
      }
      const param = objectOptimizationWithFormik<TFormHTML>(
        {
          size: payload.size,
          html_code: payload.html_code,
          erid: payload.erid,
        },
        formValueData,
      );
      if (param) {
        let paramSend: PSentFormik = { xxhash: payload.xxhash, type: 'HTML' };
        if ('html_code' in param) {
          paramSend = {
            ...paramSend,
            data: {
              html_code: param.html_code as string,
            },
          };
          if (!findEridMetaTeg(formik.values.html_code) && formik.values.erid) {
            paramSend.erid = formik.values.erid;
          }
        }
        if (param.size) {
          paramSend = { ...paramSend, size: param.size as string };
        }
        if (param.erid !== null && param.erid !== undefined) {
          paramSend = { ...paramSend, erid: param.erid as string };
        }

        dispatch(
          setEditCreativeRequest({
            data: paramSend,
            visual: {
              setter: setEdit,
              setIsLoading,
              translation:
                getValueFromSettingForKey('ShowBroadcastStatus') !== null &&
                !getValueFromSettingForKey('ShowBroadcastStatus'),
              value: false,
              alertText: `${i18n.t('creative.type.type')}HTML`,
            },
          }),
        );
      } else {
        setEdit(false);
        dispatch(setFormDefault(true));
      }
    },
    validate,
    validationSchema: Yup.object({
      width: Yup.number().max(
        10000,
        `${i18n.t('errors.limit_symbol.start')}10000${i18n.t(
          'errors.limit_symbol.pixel',
        )}`,
      ).min(1, i18n.t('errors.err45')),
      height: Yup.number().max(
        10000,
        `${i18n.t('errors.limit_symbol.start')}10000${i18n.t(
          'errors.limit_symbol.pixel',
        )}`,
      ).min(1, i18n.t('errors.err45')),
    }),
  });

  const handleFormikSubmit = () => {
    formik.handleSubmit();
  };

  const handleFormikReset = () => {
    formik.handleReset({ html_code: payload.html_code, size: payload.size });
    setEdit(false);
    dispatch(setFormDefault(true));
  };

  useEffect(() => {
    const defSize = sizeOption.filter((v) => v.value === formik.values.size);
    if (defSize.length === 0) {
      if (formik.values.size && formik.values.size !== sizeOption[sizeOption.length - 1].label) {
        const size = formik.values.size.split('x');
        formik.setFieldValue('width', size[0]);
        formik.setFieldValue('height', size[1]);
      }
    }
  }, [formik.values.size]);

  useEffect(() => {
    if(payload.html_code !== formik.values.html_code) {
      formik.setFieldValue('html_code', payload.html_code);
    }
  }, [LTU]);

  /** Копирование HTML кода в буфер */
  const handlerCopyHtml = () => {
    if (formik.values.html_code) {
      copy(formik.values.html_code);
      toastSuccess(t('alerts.a2'));
    }
  };

  useEffect(() => {
    formik.validateForm();
  }, [formik.values.width, formik.values.height])

  return {
    formik,
    handleFormikSubmit,
    handleFormikReset,
    sizeOption,
    defaultSizeOption,
    checkSizes,
    handlerCopyHtml,
  };
};
