import React, { FC } from 'react';
import cn from 'classnames';
import { FiTrash2 } from 'react-icons/fi';
import css from './styles.module.scss';
import useTag from './hook';
import Input from '../Input';

type TagProps = {
  value: string;
  handlerClose?: (value: string) => void;
  handlerUpdate?: (value: string, index: number) => void;
  index: number;
  isSaved: boolean;
  isError: boolean;
  isDisable?: boolean;
  maxLength?: number;
  /** Добавляется синяя рамка к тегу */
  blueBorder?: boolean;
};

const Tag: FC<TagProps> = ({
  value,
  handlerClose,
  index,
  handlerUpdate,
  isSaved,
  isError,
  isDisable,
  maxLength,
  blueBorder,
}) => {
  const { edit, handlerHideEdit, handlerOpenEdit, wrapperRef } = useTag();
  return (
    <div
      ref={wrapperRef}
      className={cn(css.tagWrapper, {
        isDisable,
      })}
    >
      <div className={cn(css.editWrapper, { isShow: edit })}>
        <Input
          handlerClose={handlerHideEdit}
          handlerUpdateAction={handlerUpdate}
          index={index}
          maxLength={maxLength}
          type="edit"
          valueDefault={value}
          isError={isError}
          showEvent={edit}
        />
      </div>
      <div
        className={cn(css.tagView, {
          isSaved,
          isBlueBorder: blueBorder,
          isError,
          isShow: edit,
        })}
        onClick={handlerOpenEdit}
      >
        <span className={css.main_value}>{value}</span>
        <span className={css.plug_value}>{value}</span>
        {handlerClose && (
          <button className={css.close} type="button" aria-label="Close input">
            <FiTrash2
              size={18}
              onClick={(e) => {
                e.stopPropagation();
                handlerClose(value);
              }}
            />
          </button>
        )}
      </div>
    </div>
  );
};

export default Tag;
