import React, { FC, useState } from 'react';
import { Helmet } from 'react-helmet';
import cn from 'classnames';
import { FiSearch, FiX } from 'react-icons/fi';
import NavigationHeader from 'components/NavigationHeader';
import Button from 'components/UI/Buttons/buttons';
import ElementsNone from 'components/ElementsNone';
import PreloaderList from 'components/Preloaders/List';
import DateRangeFilter from 'components/DateRangeFilter';
import { parsingDate } from 'utils/momentParse';
import TableFilter from 'components/UI/TableFilter';
import Tooltip from 'components/UI/Tooltip';
import AccessUserRight from 'components/AccessUserRight';
import i18n from '../../../i18n';
import css from './styles.module.scss';
import { useDataUsers } from '../model/hooks';
import Item from './Item';
import { activityUserKeys } from '../model/shared';
import CreateUserModal from './modal/settingsUser';

const Users: FC = () => {
  const {
    users,
    isLoading,
    tabs,
    tabsCollection,
    changeTab,
    isLoadingOnline,
    getStatisticsUser,
    type,
    period,
    onSetDateRange,
    key: activeKeySort,
    sort,
    handleSort,
    searchRef,
    onOpenSearch,
    onChangeSearch,
    search,
    onCloseSearch,
    toggleSearch,
    filterData,
  } = useDataUsers();

  const [createUser, setCreateUser] = useState(false);

  const showNotFound =
    !isLoading && !isLoadingOnline && filterData && filterData.length === 0;

  const showSearch =
    !!users && users.length > 0 && !!filterData && filterData.length === 0;

  return (
    <div className={css.users}>
      <Helmet>
        <title>{i18n.t('users_page.page_title')}</title>
      </Helmet>

      <NavigationHeader>
        {tabsCollection.map((key) => (
          <button
            key={key}
            type="button"
            className={cn('tabs__item', {
              isActive: tabs === key,
            })}
            onClick={() => changeTab(key)}
            disabled={isLoading || isLoadingOnline}
          >
            {i18n.t(`users_page.navigation.${key}`)}
          </button>
        ))}
      </NavigationHeader>

      <div className={cn(css.header, toggleSearch ? 'isToggleSearch' : '')}>
        <div className="container">
          <div className={css.status}>
            <h1 className={css.title}>{i18n.t('users_page.page_title')}</h1>
            <div className={css.actions}>
              <div className={cn(css.search, toggleSearch ? 'isActive' : '')}>
                <div className={css.icon} onClick={onOpenSearch}>
                  <FiSearch size={24} />
                </div>
                <div className={css.input}>
                  <input
                    type="text"
                    autoComplete="off"
                    placeholder={i18n.t('users_page.search_placeholder')}
                    value={search}
                    onChange={onChangeSearch}
                    ref={searchRef}
                  />
                </div>

                <div className={css.close} onClick={onCloseSearch}>
                  <FiX size={24} />
                </div>
              </div>
              {tabs === 'statistics' && (
                <div className={css.period}>
                  <DateRangeFilter
                    range={{
                      from: parsingDate(period.from).format('YYYY-MM-DD'),
                      to: parsingDate(period.to).format('YYYY-MM-DD'),
                    }}
                    dateStart={parsingDate('2021-11-18 15:56').toDate()}
                    dateEnd={parsingDate(new Date()).toDate()}
                    initialTitleDay={type}
                    onSetDateRange={onSetDateRange}
                  />
                </div>
              )}
              <AccessUserRight>
                {({ isDisable }) => (
                  <Button
                    title={i18n.t('users_page.create_btn')}
                    buttonAttributes={{
                      disabled: isDisable,
                      style: {
                        height: 40,
                      },
                      onClick: () => setCreateUser(true),
                    }}
                  />
                )}
              </AccessUserRight>
            </div>
          </div>
        </div>
      </div>

      {showNotFound ? (
        <ElementsNone search={showSearch} />
      ) : (
        <div className={css.content}>
          <div className="container">
            <div className={css.table}>
              {tabs === 'statistics' ? (
                <div
                  className={cn(css.header, {
                    isStatistics: tabs === 'statistics',
                  })}
                >
                  <Tooltip
                    hideHint
                    className={css.header__item}
                    classNameHint={css.header__item_hint}
                    title={
                      <TableFilter
                        data="name"
                        title={i18n.t('users_page.table.login')}
                        handleSort={handleSort}
                        sort={sort}
                        activeKey={activeKeySort}
                        isShow
                      />
                    }
                  >
                    {}
                  </Tooltip>
                  {activityUserKeys.map((key) => (
                    <Tooltip
                      key={key}
                      hintWidth={300}
                      className={css.header__item}
                      classNameHint={css.header__item_hint}
                      title={
                        <TableFilter
                          data={key}
                          title={i18n.t(`users_page.table.${key}`)}
                          activeKey={activeKeySort}
                          isShow
                          sort={sort}
                          handleSort={handleSort}
                        />
                      }
                    >
                      {i18n.t(`users_page.table.hinits.${key}`)}
                    </Tooltip>
                  ))}
                </div>
              ) : (
                <div
                  className={cn(css.header, { isOnline: tabs === 'online' })}
                >
                  <div className={css.header__item}>
                    {i18n.t('users_page.table.login')}
                  </div>
                  {tabs !== 'online' && (
                    <div className={css.header__item}>
                      {i18n.t('users_page.table.groups')}
                    </div>
                  )}
                  <div className={css.header__item}>
                    {i18n.t('users_page.table.name')}
                  </div>
                  <div className={css.header__item}>
                    {i18n.t('users_page.table.type')}
                  </div>
                  <div className={css.header__item}>
                    {i18n.t('translations.title')}
                  </div>
                </div>
              )}

              {isLoading || isLoadingOnline ? (
                <div className={css.body}>
                  <PreloaderList type="user" />
                </div>
              ) : (
                <div className={cn(css.body, 'table-body')}>
                  {filterData &&
                    filterData.map((userItem) => (
                      <Item
                        key={userItem.id}
                        user={userItem}
                        tabs={tabs}
                        getStatisticsUser={getStatisticsUser}
                      />
                    ))}
                  {!filterData && <ElementsNone />}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {createUser && (
        <CreateUserModal
          state={createUser}
          mode="create"
          onClose={() => setCreateUser(false)}
        />
      )}
    </div>
  );
};

export default Users;
