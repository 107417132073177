import { useEffect, useState } from 'react';

type TResult = {
    /** Достигнут ли конце страницы */
    endBody: boolean
};

type TUseDetectBlockScroll = () => TResult;

/** Определяем, что польлзователь закончил достиг конца страницы после скролла */
export const useDetectFinishScroll: TUseDetectBlockScroll = () => {
    const [endBody, setEndBody] = useState<boolean>(false);

    const checkEndDocument = () => {
        if (
            window.innerHeight + window.scrollY >=
            document.documentElement.scrollHeight
        ) {
            setEndBody(true);
        } else {
            setEndBody(false);
        }
    };

    useEffect(() => {
        document.addEventListener('scrollend', checkEndDocument);
        return () => {
            document.removeEventListener('scrollend', checkEndDocument);
        };
    }, []);

    return {
        endBody,
    };
};