import { useLocation, useRouteMatch } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { TReportItem, PCampaignReport, TNamingItem, TItem } from '../types';
import { useViewReportInfo } from './useCampaignGeneral';
import { TDates, TID, TOnSetDateRange } from '../../../types/general';
import { KeysSettings, useStateValue } from '../../../utils/context';
import { parsingDate } from '../../../utils/momentParse';
import { fetchReportType } from '../model/actions';
import { chartColors } from '../reports/reportStatististic/chartColors';
import toLocaleStringSupporte from '../../../utils/toLocaleStringSupporte';
import { useDispatchApp } from '../../../redux/rootSelectors';
import { useHideNavigation } from '../../user/model/selectors';
import { useCampaignInfo } from '../model/selectors';

type DateFilterProps = {
  range: TDates['period'];
  dateStart: Date;
  dateEnd: Date;
  initialTitleDay: TDates['type'];
};

type TFUseReportByID = () => DateFilterProps & {
  id: string;
  report: ReturnType<typeof useViewReportInfo>['report'];
  campaign: ReturnType<typeof useCampaignInfo>['data'];
  isLoading: ReturnType<typeof useViewReportInfo>['isLoading'];
  hideNavigation: ReturnType<typeof useHideNavigation>;
  errorCampaign: ReturnType<typeof useCampaignInfo>['error'];
  error: boolean;
  isDateReport: ReturnType<typeof useViewReportInfo>['isDateReport'];
  title: string;
  handlerDateFilter: TOnSetDateRange;
  typeReport: TReportItem['type'];
  getType: string;
  requestParams: PCampaignReport;
  statisticsIntegration: TItem[];
  genIntegrationNaming: TNamingItem;
  defChartItemsGen: ReturnType<typeof useViewReportInfo>['defChartItemsGen'];
  oneIntegrationKey: string;
};

/** функция возвращает данные для отчета */
export const useReportByID: TFUseReportByID = () => {
  const { report, isLoading, error, isDateReport, defChartItemsGen } =
    useViewReportInfo();
  const hideNavigation = useHideNavigation();
  const dispatch = useDispatchApp();
  const { t } = useTranslation();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const type = params.get('type') as TReportItem['type'];

  const { state, dispatch: dispatchContext } = useStateValue();
  const settings = state.settings[KeysSettings.CAMPAIGN];

  const {
    params: { id },
  } = useRouteMatch<TID>();
  const { data: campaign, error: errorCampaign } = useCampaignInfo();

  /** Тип цены для расчета статы кампании */
  const getType = (campaign && campaign.pay_type) || '';

  /** Гард на проверку типа */
  const isGardTypeReport = (v: string): v is TReportItem['type'] => true;

  const [typeReport, setTypeReport] = useState<TReportItem['type']>('day');

  useEffect(() => {
    if (type && isGardTypeReport(type)) {
      setTypeReport(() => type);
    }
  }, []);

  /** Пропсы для сотояния фильтра */
  const dataFilterProps = useMemo<DateFilterProps>(
    () => ({
      range: {
        from: settings.period.from,
        to: settings.period.to,
      },
      dateStart: parsingDate(
        (campaign && campaign.date_start.date) || moment().subtract(1, 'years'),
      ).toDate(),
      dateEnd: parsingDate(
        (campaign && campaign.date_end.date) || new Date(),
      ).toDate(),
      initialTitleDay: 'period',
    }),
    [campaign, settings],
  );

  /** Получение заголовка старницы */
  const getTitle = (v: string | null): string => {
    const dictTitle: Record<string, string> = {
      geo_city: t('campaigns_page.campaign_settings.reports.geo_report'),
      day_techfee: t('campaigns_page.campaign_settings.reports.fee_report'),
      yandex: t('campaigns_page.campaign_settings.reports.yandex_report'),
      integration: t('campaigns_page.campaign_settings.reports.integr_report'),
      'nmi-integration-src': t(
        'campaigns_page.campaign_settings.reports.integr_report',
      ),
      socdem: t('campaigns_page.campaign_settings.reports.socdem_report'),
      shownum: t('campaigns_page.campaign_settings.reports.shows_report'),
    };
    if (v && dictTitle[v]) {
      return dictTitle[v];
    }
    return t('campaigns_page.campaign_settings.reports.gen_report');
  };

  /** Изменеение периода отчета */
  const handlerDateFilter: ReturnType<TFUseReportByID>['handlerDateFilter'] = (
    dates,
    typeItem,
  ) => {
    dispatchContext({
      type: 'setSettings',
      payload: {
        item: {
          period: { ...dates },
          type: typeItem,
        },
        key: KeysSettings.CAMPAIGN,
      },
    });
    if (type && isGardTypeReport(type)) {
      const data = {
        xxhash: id,
        type,
        period: { ...dates },
      };
      dispatch(fetchReportType(data));
    }
  };

  /** параметры для запроса */
  const requestParams = useMemo<PCampaignReport>(
    () => ({
      xxhash: id,
      period: { ...settings.period },
      type: typeReport,
    }),
    [settings, params],
  );

  /** Статистика для интеграционного отчета */
  const statisticsIntegration =
    report?.statisticsIntegration?.utm &&
    report?.statisticsIntegration?.utm.length &&
    report?.statisticsIntegration?.utm.map((e) => {
      const element = {
        utm_tags: `${e.utm_campaign}–${e.utm_source}`,
        ...e,
      };
      const removeKeys = [
        'utm_source',
        'utm_campaign',
        'utm_medium',
        'utm_content',
        'utm_term',
      ] as const;

      removeKeys.forEach((key) => {
        if (key in element) {
          delete element[key];
        }
      });

      return element;
    });

  /** ключ для первого столбца в интеграционном отчете */
  let oneIntegrationKey = '';

  /** генератор столбцов для интегрированного отчета */
  const genIntegrationNaming: TNamingItem = new Map();
  if (type === 'integration') {
    // eslint-disable-next-line no-unused-expressions
    statisticsIntegration &&
      statisticsIntegration[0] &&
      Object.keys(statisticsIntegration[0]).forEach(
        // eslint-disable-next-line consistent-return
        (key: string, i: number) => {
          if (i === 0) {
            oneIntegrationKey = key;
            genIntegrationNaming.set(key, {
              title: key,
              isIndex: true,
              noChart: true,
            });
            return null;
          }
          genIntegrationNaming.set(key, {
            title: key,
            color: chartColors[i],
            noChart: ['utm_source'].includes(key),
            formatCell: (item) => {
              if (typeof item[key] === 'undefined') {
                return '';
              }
              return toLocaleStringSupporte(`${item[key]}`);
            },
          });
        },
      );
  }

  return {
    id,
    report,
    campaign,
    isLoading,
    hideNavigation,
    errorCampaign,
    error: error.isError,
    isDateReport,
    title: getTitle(typeReport),
    handlerDateFilter,
    typeReport,
    getType,
    requestParams,
    statisticsIntegration: statisticsIntegration || [],
    genIntegrationNaming,
    defChartItemsGen,
    oneIntegrationKey: oneIntegrationKey || '',
    ...dataFilterProps,
  };
};
