import TooltipPoratal from 'components/UI/TooltipPoratal';
import React, { FC } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import css from './styles.module.scss';
import { ReactComponent as BarLineChart } from '../../assets/bar-line-chart.svg';
import { ReactComponent as BarLineSvg } from '../../assets/bar-line.svg';
import { ReactComponent as BarChartSvg } from '../../assets/bar-chart.svg';

interface IStatisticTypeTooltipProps {
  statistic_type: string | null | undefined;
  isStatisticIsNull: boolean;
}

const StatisticTypeTooltip: FC<IStatisticTypeTooltipProps> = ({
  statistic_type,
  isStatisticIsNull,
}) => {
  const { t } = useTranslation();
  const getTooltipHintForStatisticType = () => {
    let srt = t(`campaigns_page.td_statistics`);

    if (statistic_type === 'DASHBORD_CH')
      srt = t(`campaigns_page.td_statistics_dash`);
    if (statistic_type === 'DASHBORD')
      srt = t(`campaigns_page.dash_statistics`);

    return srt;
  };

  return (
    <div
      className={cn(css.dashbord, {
        _isNotNullStatistics: statistic_type === null && isStatisticIsNull,
      })}
    >
      <TooltipPoratal
        hint={getTooltipHintForStatisticType()}
        hideHint={isStatisticIsNull}
      >
        {statistic_type === 'DASHBORD_CH' && <BarLineChart />}
        {statistic_type === 'DASHBORD' && <BarLineSvg />}
        {statistic_type === null && !isStatisticIsNull && <BarChartSvg />}
        {statistic_type === null && isStatisticIsNull && <BarLineChart />}
      </TooltipPoratal>
    </div>
  );
};

export default StatisticTypeTooltip;
