import React, { FC } from 'react';
import { ChartStatisticItem, WeekStatisticItem } from 'domains/campaigns/types';
import cn from 'classnames';
import Row from './Row';
import css from './styles.module.scss';
import { tableProps } from './types';

interface tableBodyProps {
  columns: {
    name: string;
    data: string;
  }[];
  totalValues: WeekStatisticItem | null;
  tableStatistic: ChartStatisticItem[];
  mode: tableProps['mode'];
  /* Ширина первого столбца */
  extend?: number;
  /* Раскрыты ли деревья аудиторий */
  showAuTree?: boolean;
}

const TableBody: FC<tableBodyProps> = ({
  columns,
  totalValues,
  tableStatistic,
  mode,
  extend,
  showAuTree = false,
}) => {
  const renderRowRecursive = (
    item: ChartStatisticItem,
    isChild?: boolean,
  ): JSX.Element | (JSX.Element | Element)[] => {
    const row = (
      <Row
        statData={item}
        mode={mode}
        columns={columns}
        key={item.internal_id}
        extend={extend}
        group={showAuTree && !isChild}
        tree={isChild}
      />
    );

    if (item.child && item.child.length > 0) {
      const childRows: any = item.child.flatMap((child) =>
        renderRowRecursive(child, true),
      );
      return [row, ...childRows];
    }

    return row;
  };

  return (
    <>
      {totalValues && (
        <Row
          totalData={totalValues}
          mode={mode}
          columns={columns}
          extend={extend}
        />
      )}
      <div
        className={cn(css.table_content, {
          [css.table_content_long]: mode === 'agency',
        })}
      >
        {tableStatistic.map((item) => renderRowRecursive(item))}
      </div>
    </>
  );
};

export default TableBody;
