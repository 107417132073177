import React, { FC, useEffect } from 'react';
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from 'react-router';
import { TID } from 'types/general';
import NavigationHeader from 'components/NavigationHeader';
import { useUserInfo } from 'domains/user/model/selectors';
import { NavLink } from 'react-router-dom';
import Error404 from 'domains/app/Errors/Error404';
import { everyGroups } from 'utils/statics/everyGroups';
import ClientGeneralReport from 'domains/campaigns/Reports';
import { useDispatchApp } from 'redux/rootSelectors';
import LimitGroups from './LimitGroups';
import BasicSettings from './BasicSettings';
import Additional from './Additional';
import { useGetGroupList } from './LimitGroups/hooks/useGetGroupList';
import i18n from '../../i18n';
import Settings from './Settings';
import { fetchClientInfoById } from './reduser';

const Detail: FC = () => {
  const { path } = useRouteMatch<TID>();
  const dispatch = useDispatchApp();
  const location = useLocation();
  const { data: user } = useUserInfo();
  const { params } = useRouteMatch<TID>();
  const { clientInfo } = useGetGroupList();

  useEffect(() => {
    dispatch(fetchClientInfoById(params.id));
  }, []);

  return (
    <div>
      {user &&
        (user.partner.type === 'AGENCY' ||
          user.partner.type === 'ORGANIZATION') && (
          <>
            <NavigationHeader
              back={{
                title: i18n.t(
                  `campaigns_page.campaign_settings.black_menu.Back`,
                ),
                to: `/main/clients?agency_id=${clientInfo?.parent_id}`,
              }}
            >
              {location.pathname.slice(-7) !== 'reports' && (
                <>
                  <NavLink
                    to={{
                      ...location,
                      pathname: `/main/client/${params.id}/basic_settings`,
                    }}
                    activeClassName="isActive"
                  >
                    {i18n.t(
                      `campaigns_page.campaign_settings.black_menu.Main_settings`,
                    )}
                  </NavLink>
                  <NavLink
                    to={{
                      ...location,
                      pathname: `/main/client/${params.id}/additional`,
                    }}
                    activeClassName="isActive"
                  >
                    {i18n.t(
                      `campaigns_page.campaign_settings.black_menu.Settings_aditional`,
                    )}
                  </NavLink>
                  <NavLink
                    to={{
                      ...location,
                      pathname: `/main/client/${params.id}/limit_groups`,
                    }}
                    activeClassName="isActive"
                  >
                    {i18n.t(`clients_page.limit_groups.title`)}
                  </NavLink>
                  {everyGroups(user, 'owner') && (
                    <NavLink
                      to={{
                        ...location,
                        pathname: `/main/client/${params.id}/settings`,
                      }}
                      activeClassName="isActive"
                    >
                      {i18n.t(`project_page.settings`)}
                    </NavLink>
                  )}
                </>
              )}
            </NavigationHeader>
          </>
        )}

      {user && (
        <Switch>
          <Route
            path={`${path}/reports`}
            component={
              everyGroups(user, 'reports_client')
                ? ClientGeneralReport
                : Error404
            }
          />
          {!(
            user.partner.type === 'AGENCY' ||
            user.partner.type === 'ORGANIZATION'
          ) && <Route component={Error404} />}
          <Route path={`${path}/basic_settings`} component={BasicSettings} />
          <Route path={`${path}/additional`} component={Additional} />
          <Route path={`${path}/limit_groups`} component={LimitGroups} />
          <Route
            path={`${path}/settings`}
            component={everyGroups(user, 'owner') ? Settings : Error404}
          />

          <Redirect
            from={path}
            to={{
              ...location,
              pathname: `${path}/basic_settings`,
            }}
          />
        </Switch>
      )}
    </div>
  );
};

export default Detail;
