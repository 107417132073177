import { Dispatch, SetStateAction, useEffect } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router';
import { useDispatchApp } from 'redux/rootSelectors';
import { TID } from 'types/general';
import { useSRMethod } from 'domains/campaign/Settings/hooks';
import { ListSRMethods, TExperimentalInfoField } from 'domains/campaign/types';
import { IRacalcMethodParams, useRecalculationMethod } from 'utils/MethodRecalcHelpers/useRecalculationMethod';
import { fetchCampaignExperimental } from 'domains/campaign/model/actions';
import { editClientSettings, fetchClientInfoById } from '../reduser';
import { useClientInfo } from './selectors';
import { ResponseInfoClient } from '../types';

export type TUseClientSettings = () => {
  t: TFunction<'translation'>;
  routeParams: TID;
  clientInfo: ResponseInfoClient | null;
  isLoading: boolean;
  formik: any;
  commissionAgencyData: TExperimentalInfoField;
  commissionSelfServiceData: TExperimentalInfoField;
  cpcData: TExperimentalInfoField | undefined;
  marginalityData: TExperimentalInfoField | undefined;
  listSRMethods: ListSRMethods | null;
  onChangeSRMethod: (v: string | number) => void;
  SRMethod: string;
};

export const useClientSettings: TUseClientSettings = () => {
  const { data: clientInfo } = useClientInfo();
  const { t } = useTranslation();
  const dispatch = useDispatchApp();
  const { params: routeParams } = useRouteMatch<TID>();

  const {
    SRMethod,
    listSRMethods,
    onChangeSRMethod,
  } = useSRMethod(clientInfo);

  const editClientDispatch = (
    params: IRacalcMethodParams,
    callbacks: Record<string, Dispatch<SetStateAction<boolean>>>,
  ) => {
    dispatch(
        editClientSettings({
          id: clientInfo?.id as string,
          idAgency: clientInfo?.parent_id as string,
          ...params,
          callbacks,
        }),
      );
  };

  const {
    formik,
    isLoading,
    commissionAgencyData,
    commissionSelfServiceData,
    cpcData,
    marginalityData,
  } = useRecalculationMethod(clientInfo, editClientDispatch, SRMethod);

  useEffect(() => {
    dispatch(fetchClientInfoById(routeParams.id));
    dispatch(fetchCampaignExperimental());
  }, []);

  return {
    t,
    routeParams,
    clientInfo,
    isLoading,
    formik,
    commissionAgencyData,
    commissionSelfServiceData,
    cpcData,
    marginalityData,
    listSRMethods,
    onChangeSRMethod,
    SRMethod
  };
};
