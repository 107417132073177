/* eslint-disable no-nested-ternary */
import Breadcrumbs from 'components/Breadcrumbs/breadcrumbs';
import React, { FC, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useRouteMatch } from 'react-router-dom';
import cn from 'classnames';
import Card from 'components/UI/Card';
import Button from 'components/UI/Buttons/buttons';
import { TID } from 'types/general';
import { useDispatch } from 'react-redux';
import CardsList from 'components/UI/CardList';
import { useIsGroup } from 'domains/user/hooks';
import css from './styles.module.scss';
import LimitGroupsClientModal from './modal';
import Group from './Group';
import { useGetGroupList } from './hooks/useGetGroupList';
import { fetchClientInfoById, fetchGroupList } from '../reduser';
import i18n from '../../../i18n';
import Loadinggroup from './ui/Loading/Loadinggroup';
import { useRemoveCustomErrors } from './hooks/useRemoveCustomErrors';

const LimitGroups: FC = () => {
  const dispatch = useDispatch();
  const [openAddGroupModal, setOpenAddGroupModal] = useState(false);
  const { groups, clientInfo, isLoading, error } = useGetGroupList();
  const { params } = useRouteMatch<TID>();
  const [gropsInRemoveQueue, setGropsInRemoveQueue] = useState<string[]>([]);
  const refGropsInRemoveQueue = useRef(gropsInRemoveQueue);
  const { removeCustomErrors } = useRemoveCustomErrors();
  const { isLocked } = useIsGroup();
  
  useEffect(() => {
    const removeDouplicateGroups = new Set(gropsInRemoveQueue)
    refGropsInRemoveQueue.current = [...removeDouplicateGroups];
  }, [gropsInRemoveQueue]);

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    dispatch(fetchClientInfoById(params.id));
    dispatch(fetchGroupList({ xxhash: params.id }));
  }, []);

  return (
    <div className={css.limit__groups}>
      <Helmet>
        <title>{i18n.t(`clients_page.limit_groups.title`)}</title>
      </Helmet>
      <div className={cn(css.header)}>
        <div className="container">
          <Breadcrumbs type="Client" xxhash={params.id} />
          <div className={css.status}>
            <h1 className={css.title}>{clientInfo?.title}</h1>
          </div>
        </div>
      </div>
      <div className={css.body}>
        <div className={cn(css.container, 'container')}>
          <CardsList>
            <Card noHover isUseAccess>
              <h3 className={css.card_title}>
                {i18n.t(`clients_page.limit_groups.title`)}
              </h3>
              <div className={css.groups}>
                {isLoading ? (
                  <Loadinggroup />
                ) : groups?.length ? (
                  groups.map((groupInfo) => (
                    <Group
                      groupInfo={groupInfo}
                      error={error}
                      setGropsInRemoveQueue={setGropsInRemoveQueue}
                      key={groupInfo.group.id}
                    />
                  ))
                ) : (
                  <div className={css.empty}>
                    {i18n.t(`clients_page.limit_groups.no_settings`)}
                  </div>
                )}
              </div>
              {!isLoading && (
                <div className={css.buttons}>
                  <Button
                    title={i18n.t(`clients_page.limit_groups.add_new`)}
                    buttonAttributes={{
                      disabled: isLoading || isLocked,
                      onClick: () => setOpenAddGroupModal(true),
                    }}
                  />
                </div>
              )}
            </Card>
          </CardsList>
          <div className={css.widget} />
        </div>
      </div>
      {openAddGroupModal && (
        <LimitGroupsClientModal
          error={error}
          open={openAddGroupModal}
          onClose={() => {
            setOpenAddGroupModal(false);
            removeCustomErrors();
          }}
        />
      )}
    </div>
  );
};

export default LimitGroups;
