import { getSizes } from 'domains/uploads/helpers';
import UploadImage from 'domains/uploads/UploadImage';
import React, { FC } from 'react';
import Input from 'components/UI/Input/input';
import { IUseTypeProp } from 'domains/creative/creatives/creativeAdd/hooks/useType';
import i18n from '../../../../../../../../i18n';
import css from '../../../styles.module.scss';

type TPushProp = {
  formik: IUseTypeProp['formik']
}

const index: FC<TPushProp> = ({formik: { values, setFieldValue, handleChange, validateForm, resetForm }}) => (
  <div className={css.form}>
    <UploadImage
      keyUploadFile="push"
      preview={getSizes(values.data.iconMask, values.data.iconFormats)}
      name="iconMask"
      setFieldValue={setFieldValue}
      resetForm={resetForm}
      validateForm={validateForm}
      label={i18n.t('creative.creasmall_set.icon_word')}
      customLinkTitle={i18n.t('creative.creasmall_set.linktoicon_word')}
      maxSizeFile={200}
      hintText={
        <span>
          {i18n.t('creative.creasmall_set.push_formats1')}
          <br />
          {i18n.t('creative.creasmall_set.push_formats2')}
        </span>
      }
      hasEdit
      edit
      isData
      file_ext={['.png', '.jpeg', '.jpg']}
      typeSection="push"
    />
    <UploadImage
      keyUploadFile="push"
      preview={getSizes(values.data.imgMask, values.data.imgFormats)}
      name="imgMask"
      setFieldValue={setFieldValue}
      resetForm={resetForm}
      validateForm={validateForm}
      label={i18n.t('creative.creasmall_set.image_word')}
      customLinkTitle={i18n.t('creative.creasmall_set.linktoimage_word')}
      maxSizeFile={200}
      hintText={
        <span>
          {i18n.t('creative.creasmall_set.push_formats1')}
          <br />
          {i18n.t('creative.creasmall_set.push_formats3')}
        </span>
      }
      hasEdit
      edit
      isData
      file_ext={['.png', '.jpeg', '.jpg']}
      typeSection="push"
    />
    <Input
      label={i18n.t('creative.creasmall_set.title_word')}
      showValueCount
      inputAttributes={{
        name: 'data.title',
        placeholder: '',
        maxLength: 65,
        style: { height: 48 },
        value: values.data.title,
        onChange: handleChange,
      }}
    />
    <Input
      label={i18n.t('creative.creasmall_set.text_word')}
      showValueCount
      isTextarea
      inputAttributes={{
        name: 'data.text',
        style: { height: 128 },
        maxLength: 240,
        value: values.data.text,
        onChange: handleChange,
      }}
    />
  </div>
);

export default index;
