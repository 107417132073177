import React, { FC, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import cn from 'classnames';
import TooltipPoratal from 'components/UI/TooltipPoratal';
import { FiTrash2, FiPlus } from 'react-icons/fi';
import css from './styles.module.scss';

type TLimitGroupsItem = {
  id: string;
  internal_id?: string;
  name: string;
  readOnly?: boolean;
  campaignsXxhash?: string[];
  setCampaignsXxhash?: React.Dispatch<React.SetStateAction<string[]>>;
  isResetCampaigns?: boolean;
  setIsResetCampaigns?: React.Dispatch<React.SetStateAction<boolean>>;
};

const Item: FC<TLimitGroupsItem> = ({
  id,
  internal_id,
  name,
  readOnly,
  campaignsXxhash,
  setCampaignsXxhash,
  isResetCampaigns,
  setIsResetCampaigns,
}) => {
  const [justAdded, setJustAdded] = useState<boolean>(false);
  const [add, setAdd] = useState(() => !campaignsXxhash?.includes(id));

  useEffect(() => {
    if (isResetCampaigns) {
      setAdd(true);
    }
  }, [isResetCampaigns]);

  const addRemoveClickHandler = () => {
    if (setCampaignsXxhash) {
      if (add) {
        setCampaignsXxhash((prev) => [...prev, id]);
        setAdd(!add);
        if (setIsResetCampaigns) {
          setIsResetCampaigns(false);
        }
        setJustAdded(true);
        setTimeout(() => {
          setJustAdded(false);
        }, 1500);
        return;
      }
      setCampaignsXxhash((prev) => prev.filter((item) => item !== id));
      setAdd(!add);
    }
  };

  return (
    <div
      className={cn(css.limit_groups_item, {
        _isAdded: !add,
        _isReadOnly: readOnly,
        _isJustAdded: justAdded,
      })}
    >
      <NavLink
        className={css.link}
        target="_blank"
        to={{
          pathname: `/main/campaign/${id}/main`,
        }}
      >
        {internal_id}
      </NavLink>
      <div className={css.action_wrapper} onClick={addRemoveClickHandler}>
        <TooltipPoratal hint={name}>
          <div className={css.name}>{name}</div>
        </TooltipPoratal>
        {setCampaignsXxhash && (
          <div className={css.actions}>
            {add ? (
              <div className={css.actions__add}>
                <FiPlus size={20} />
              </div>
            ) : (
              <div className={css.actions__remove}>
                <FiTrash2 size={20} />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Item;
