import { ContractItem, TContractStatus } from 'domains/contract/model/types';
import { useStorageContractsTabs } from 'domains/storage/model/selectors';
import { useEffect, useRef, useState } from 'react';
import { useDispatchApp } from 'redux/rootSelectors';
import { getCounteragentsList, getOrdList } from 'domains/counteragents/model/actions';
import useSearchField from 'hooks/useSearchField';
import { setContractsTabs } from 'domains/storage/model/reducer';
import { getContractsList } from '../actions';
import { useContractsList } from '../selectors';

type TReturnProps = {
  currentTab: TContractStatus;
  contractsView: ContractItem[] | undefined;
  searchRef: React.RefObject<HTMLInputElement>;
  searchWrapperRef: React.RefObject<HTMLDivElement>;
  applyContractFilterHandler: () => void;
  clearContractFilter: () => void;
  currentTabHandler: (tab: TContractStatus) => void;
  toggleSearch: boolean;
  onOpenSearch: () => void;
  onChangeSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onCloseSearch: () => void;
  isLoadingContractsList: boolean;
  contractsFilters: {
    executor: string;
    customer: string;
    status: TContractStatus;
  };
  setContractsFilters: React.Dispatch<
    React.SetStateAction<{
      executor: string;
      customer: string;
      status: TContractStatus;
    }>
  >;
  search: string;
};

export const useContractsData = (): TReturnProps => {
  const dispatch = useDispatchApp();
  const status = useStorageContractsTabs();
  const searchRef = useRef<HTMLInputElement>(null);
  const searchWrapperRef = useRef<HTMLDivElement>(null);

  const { data: ContractsList, isLoading: isLoadingContractsList } =
  useContractsList();

  const { onChangeSearch, search, onCloseSearch, onOpenSearch, toggleSearch } =
  useSearchField(searchRef, searchWrapperRef);

  const [currentTab, setCurrentTab] = useState<TContractStatus>(status);

  const [contractsFilters, setContractsFilters] = useState<{
    executor: string;
    customer: string;
    status: TContractStatus;
  }>({
    executor: '',
    customer: '',
    status: currentTab,
  });

  const contractsView = ContractsList?.filter(
    (contract) =>
      contract.number?.toLowerCase().includes(search.toLowerCase()) ||
      contract.uuid?.toLowerCase().includes(search.toLowerCase()) ||
      contract.externalId?.toLowerCase().includes(search.toLowerCase()) ||
      contract.executor.inn?.toLowerCase().includes(search.toLowerCase()),
  );

  const createFilterObj = () => {
    const filter: {
      executor?: string;
      customer?: string;
      status?: TContractStatus[] | undefined;
    } = {};
    if (contractsFilters.executor !== '') {
      filter.executor = contractsFilters.executor;
    }
    if (contractsFilters.customer !== '') {
      filter.customer = contractsFilters.customer;
    }
    if (currentTab !== 'ALL') {
      filter.status = [currentTab];
    }
    return filter;
  };

  const applyContractFilterHandler = () => {
    const filter = createFilterObj();
    dispatch(getContractsList({ filter }));
  };

  const clearContractFilter = () => {
    const param: {
      filter?: {
        status?: TContractStatus[] | undefined;
      };
    } = {};

    if (currentTab !== 'ALL') {
      param.filter = {};
      param.filter.status = [currentTab];
    }

    dispatch(getContractsList(param));
  };

  const currentTabHandler = (tab: TContractStatus) => {
    if (tab === 'ALL') {
      setCurrentTab(tab);
      dispatch(setContractsTabs(tab));
      const currentFilterObj = createFilterObj();
      delete currentFilterObj.status;
      dispatch(
        getContractsList({
          filter: currentFilterObj,
        }),
      );
    } else {
      setCurrentTab(tab);
      dispatch(setContractsTabs(tab));
      dispatch(
        getContractsList({
          filter: {
            ...createFilterObj(),
            status: [tab],
          },
        }),
      );
    }
  };

  useEffect(() => {
    const param: {
      filter?: {
        status?: TContractStatus[];
      };
    } = {};

    if (currentTab !== 'ALL') {
      param.filter = {};
      param.filter.status = [currentTab];
    }

    dispatch(getContractsList(param));
    dispatch(getOrdList());
    dispatch(getCounteragentsList({}));
  }, []);

  return {
    currentTab,
    contractsView,
    searchRef,
    searchWrapperRef,
    applyContractFilterHandler,
    clearContractFilter,
    currentTabHandler,
    toggleSearch,
    onOpenSearch,
    onChangeSearch,
    onCloseSearch,
    isLoadingContractsList,
    contractsFilters,
    setContractsFilters,
    search
  };
};
