import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { FiMoreHorizontal, FiEdit, FiSliders } from 'react-icons/fi';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import Card from 'components/UI/Card';
import OnlyOwner from 'components/OnlyOwner';
import Tooltip from 'components/UI/Tooltip';
import { useDispatchApp } from 'redux/rootSelectors';
import { everyGroups } from 'utils/statics/everyGroups';
import toArchiveSvg from '../../../assets/toarchive.svg';
import fromArchiveSvg from '../../../assets/fromarchive.svg';
import css from './styles.module.scss';
import ToggleSwitch from '../../../components/UI/ToggleSwitch';
import avatarColors from '../../../utils/avatarColors';
import { RerenderType, setStatusClientAction } from '../reducer';
import ModalWithQuestion from '../../modals/modalWithQuestion';
import { RClients } from '../types';
import { useUserInfo, useUserTheme } from '../../user/model/selectors';
import reportSvg from '../../../components/SideMenu/report.svg';

type Props = {
  client: RClients;
  paramsClients: RerenderType;
  isLastEdited?: boolean;
};

const Item: FC<Props> = ({ client, paramsClients, isLastEdited }) => {
  const { title, id, default_icon, icon, site, status } = client;
  const dispatch = useDispatchApp();
  const { getValueFromSettingForKey } = useUserTheme();
  const { data: user } = useUserInfo();
  const [isOpenModalArhive, setOpenModalArchive] = useState(false);
  const [active, setActive] = useState(status === 'LAUNCHED' || false);
  const [toggle, setToggle] = useState(false);
  const { t } = useTranslation();

  const handleChangeArchive = () => {
    dispatch(
      setStatusClientAction({
        id,
        status: status === 'ARCHIVE' ? 'STOPPED' : 'ARCHIVE',
        setter: setOpenModalArchive,
        value: isOpenModalArhive,
        reRenderParams: {
          idAgency: paramsClients.idAgency,
          status: paramsClients.status,
          alertText: {
            before: `${t('just_words.w3')} `,
            title: `"${title}"`,
            after:
              status === 'ARCHIVE'
                ? ` ${t('clients_page.client_archive_from_alert')}`
                : ` ${t('clients_page.client_archive_to_alert')}`,
          },
          isArchive: true,
        },
      }),
    );
  };
  const handleChangeStatus = () => {
    dispatch(
      setStatusClientAction({
        id,
        status: active ? 'STOPPED' : 'LAUNCHED',
        setter: setActive,
        value: active,
      }),
    );
  };

  return (
    <div>
      <Card
        className={cn(css.item, {
          _isOpen: toggle,
          _isDeleted: status === 'DELETED',
          _isLastEdited: isLastEdited,
        })}
      >
        <div className={css.indicator} />
        <div
          onMouseEnter={() => {
            if (!toggle) {
              setToggle(false);
            } else {
              setToggle(true);
            }
          }}
          onMouseLeave={() => {
            if (!toggle) {
              setToggle(false);
            }
          }}
          className={css.toggle__status}
        >
          <div>
            <OnlyOwner>
              <ToggleSwitch
                onChange={handleChangeStatus}
                toggleOnly
                notField={false}
                checked={active}
                disabled={status === 'ARCHIVE' || status === 'DELETED'}
              />
            </OnlyOwner>
          </div>
        </div>
        <Link
          to={{
            pathname: '/main/campaigns',
            search: `?client_id=${id}`,
            state: {
              back: {
                to: '/main/clients',
              },
            },
          }}
          title={title}
        />
        <div className={css.item__header}>
          {!default_icon ? (
            <div
              className={css.item__logo}
              style={{ backgroundImage: `url('${icon}')` }}
            />
          ) : (
            <div
              className={cn(css.item__defaultLogo)}
              style={{
                backgroundColor: avatarColors(title.slice(0, 1).toLowerCase()),
              }}
            >
              {title.slice(0, 1).toUpperCase()}
            </div>
          )}

          <div className={css.item__title}>
            <Tooltip
              className={css.tooltip}
              hintWidth={300}
              leftArrowPosition
              title={
                <Link
                  to={{
                    pathname: '/main/campaigns',
                    search: `?client_id=${id}`,
                    state: {
                      back: {
                        to: '/main/clients',
                      },
                    },
                  }}
                  title={title}
                >
                  <strong>{title}</strong>
                </Link>
              }
            >
              <p>{title}</p>
            </Tooltip>
            {site && (
              <div className={css.item__subtitle}>
                <span>{site}</span>
              </div>
            )}
          </div>
        </div>
        <div className={css.actions}>
          <div
            className={cn('toggle-dropdown', css.dropdown, {
              _isOpen: toggle,
              _isDeleted: status === 'ARCHIVE' || status === 'DELETED',
              _isNoAnimate: getValueFromSettingForKey('animation'),
            })}
            onMouseEnter={() => setToggle(true)}
            onMouseLeave={() => setToggle(false)}
          >
            <button type="button" className={css.dropdown__show}>
              <FiMoreHorizontal size={24} />
            </button>
            <div className={css.dropdown__menu}>
              {toggle && everyGroups(user, 'reports_client') && (
                <div>
                  <Link
                    to={{
                      pathname: `/main/client/${id}/reports`,
                      state: {
                        back: {
                          to: '/main/clients',
                        },
                      },
                    }}
                  >
                    <span>{t('clients_page.nav_menu.reports')}</span>
                    <ReactSVG src={reportSvg} />
                  </Link>
                </div>
              )}
              {toggle && (
                <OnlyOwner>
                  <Link
                    className={css.limits}
                    to={{
                      pathname: `/main/client/${id}/limit_groups`,
                      state: {
                        back: {
                          to: '/main/clients',
                        },
                      },
                    }}
                  >
                    <span>{t(`clients_page.limit_groups.title`)}</span>
                    <FiSliders size={24} />
                  </Link>
                </OnlyOwner>
              )}
              {toggle && (
                <OnlyOwner>
                  <div onClick={() => setOpenModalArchive(true)}>
                    <span>
                      {status === 'ARCHIVE'
                        ? t('archive_title_from')
                        : t('archive_title_to')}
                    </span>
                    {status === 'ARCHIVE' ? (
                      <ReactSVG src={fromArchiveSvg} />
                    ) : (
                      <ReactSVG src={toArchiveSvg} />
                    )}
                  </div>
                </OnlyOwner>
              )}
              {toggle && (
                <OnlyOwner>
                  <Link
                    to={{
                      pathname: `/main/client/${id}/basic_settings`,
                      state: {
                        back: {
                          to: '/main/clients',
                        },
                      },
                    }}
                  >
                    <span>{t('clients_page.edit_icon')}</span>
                    <FiEdit size={24} />
                  </Link>
                </OnlyOwner>
              )}
              {isOpenModalArhive && (
                <OnlyOwner>
                  <ModalWithQuestion
                    onClose={() => setOpenModalArchive(false)}
                    modalName={
                      status === 'ARCHIVE'
                        ? t('archive_title_from')
                        : t('archive_title_to')
                    }
                    title={client.title}
                    btnTitle={
                      status === 'ARCHIVE'
                        ? t('from_archive_btn')
                        : t('in_archive_btn')
                    }
                    subText={
                      status === 'ARCHIVE'
                        ? t('clients_page.client_archive_from_text')
                        : t('clients_page.client_archive_to_text')
                    }
                    onClick={handleChangeArchive}
                  />
                </OnlyOwner>
              )}
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default Item;
