import React, { FC, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import NavigationHeader from 'components/NavigationHeader';
import NotificationTarget from 'components/NotificationTarget';
import Breadcrumbs from 'components/Breadcrumbs/breadcrumbs';
import { Helmet } from 'react-helmet';
import { clearClientPersonalInfoById } from 'domains/client/reduser';
import { useDispatchApp } from 'redux/rootSelectors';
import Settings from './Settings';
import Targeting from './Targeting';
import css from './styles.module.scss';
import { TID } from '../../../../types/general';
import { useCampaignInfo } from '../../../campaign/model/selectors';
import { useFetchVendorLinks } from '../../model/hooks/useValidateVendorLInk';

const CreativeAdd: FC = () => {
  const { id } = useRouteMatch<TID>().params;
  const [tab, setTab] = useState('settings');
  const { t } = useTranslation();
  const { data: campaign } = useCampaignInfo();

  const dispatch = useDispatchApp()

  useFetchVendorLinks();

  useEffect(
    () => () => {
      dispatch(clearClientPersonalInfoById());
    },
    [],
  );

  return (
    <div className={css.creative}>
      <Helmet>
        <title>{tab === 'settings' ? t('creative.header_menu.settings') : t('creative.header_menu.targetings')}</title>
      </Helmet>
      <NavigationHeader
        back={{
          title: t('creative.header_menu.back'),
          to: id ? `/main/campaign/${id}/creatives` : '/main/campaigns',
        }}
      >
        <button
          type="button"
          className={cn({ isActive: tab === 'settings' })}
          onClick={() => setTab('settings')}
        >
          {t('creative.header_menu.settings')}
        </button>

        <button
          type="button"
          className={cn({ isActive: tab === 'targeting' })}
          onClick={() => setTab('targeting')}
        >
          {t('creative.header_menu.targetings')}
        </button>
      </NavigationHeader>

      <NotificationTarget />

      <div className={css.header}>
        <div className={cn(css.container, 'container')}>
          {campaign && <Breadcrumbs type="Campaign" xxhash={campaign.xxhash} />}
          <div className={css.status}>
            <h1 className={css.title}>
              {t('creative.create_creative.create_tit')}
            </h1>
          </div>
        </div>
      </div>

      <div className={cn(css.tab, { isShow: tab === 'settings' })}>
        <Settings />
      </div>

      <div className={cn(css.tab, { isShow: tab === 'targeting' })}>
        <Targeting />
      </div>
    </div>
  );
};

export default CreativeAdd;
