import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { OptionsType, OptionTypeBase } from 'react-select/src/types';
import { FormikErrors, FormikProps, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useDispatchApp } from 'redux/rootSelectors';
import { TGetAuditDictionary } from 'domains/agency/types';
import { fetchAuditDictionaryList } from 'domains/agency/reducer';
import { useAuditDictionaryList } from 'domains/agency/model/selectors';
import * as Yup from 'yup';
import { additionalMetricsValidation, statisticCoeffValidation } from 'utils/MethodRecalcHelpers/utils';
import i18n from '../../../i18n';
import { Setting, TFormikSettigns } from "../types";
import {
  fetchListSRMethods,
  fetchTagsGetCompany,
  setEditPersonalCampaign,
  setSRMethodRequest,
} from '../model/actions';
import { TID } from '../../../types/general';
import {
  useCampaignInfo,
  useListSRMethodsInfo,
  useSelectorCampaignCompanyTagsInfo,
} from '../model/selectors';

type TFUseSRMethod = (p: any) => {
  onSaveSRMethod: () => void;
  SRMethod: string;
  listSRMethods: ReturnType<typeof useListSRMethodsInfo>['data'];
  onChangeSRMethod: (v: string | number) => void;
  setDefSRMethod: () => void;
  optTag: OptionsType<OptionTypeBase>;
  onChangeText: (v: string) => void;
  aggregationTag: OptionTypeBase | undefined;
  onChangeAggregation: (v: OptionTypeBase) => void;
};

type TFUseCampaignSettigns = (p: Setting) => {
  formik: FormikProps<TFormikSettigns>;
  isLoadingEdit: boolean;
  audiences: TGetAuditDictionary[];
  setCurrentAudit: React.Dispatch<React.SetStateAction<string>>;
};

export const useSRMethod: TFUseSRMethod = (data) => {
  const dispatch = useDispatchApp();

  const { id } = useParams<TID>();

  const { data: listSRMethods } = useListSRMethodsInfo();

  const [SRMethod, setSRMethod] = useState<string>('');
  const [defSRMethod, setDefSR] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const [optTag, setOptTag] = useState<ReturnType<TFUseSRMethod>['optTag']>([]);
  const [filterTag, setfilterTag] = useState<string>('');
  const [aggregationTag, setAggregationTag] = useState<
    OptionTypeBase | undefined
  >(undefined);

  useEffect(() => {
    if (data === null) {
      return;
    }
    setTitle(data.title);
    setDefSR(data.method_recalculating_statistics);

    if (data.aggregation_tag) {
      const aggregation_tag = data?.aggregation_tag;
      if (typeof aggregation_tag === 'string') {
        setAggregationTag({ label: aggregation_tag, value: aggregation_tag });
      }

      dispatch(fetchTagsGetCompany(data.xxhash));
    }
  }, [data]);

  const { data: companyTags } = useSelectorCampaignCompanyTagsInfo();

  useEffect(() => {
    if (companyTags === null) return;
    if (!filterTag) return;
    if (filterTag.length < 3) {
      // eslint-disable-next-line consistent-return
      return setOptTag([]);
    }
    const filterData = companyTags.filter((item) =>
      item.toUpperCase().includes(filterTag.toUpperCase()),
    );
    setOptTag(filterData.map((i) => ({ label: i, value: i })));
  }, [companyTags, filterTag]);

  const onChangeText: ReturnType<TFUseSRMethod>['onChangeText'] = useCallback(
    (v) => {
      setfilterTag(v);
    },
    [setfilterTag],
  );

  const onChangeAggregation: ReturnType<TFUseSRMethod>['onChangeAggregation'] =
    useCallback(
      (v) => {
        setAggregationTag(v ?? { label: '', value: '' });
        setfilterTag(v?.value ?? '');
      },
      [setAggregationTag],
    );

  useEffect(() => {
    if (typeof id !== 'string') {
      return;
    }

    dispatch(fetchListSRMethods());
  }, [id]);

  useEffect(() => {
    if (typeof defSRMethod !== 'string') return;
    setSRMethod(defSRMethod);
  }, [defSRMethod]);

  const onChangeSRMethod: ReturnType<TFUseSRMethod>['onChangeSRMethod'] =
    useCallback(
      (v) => {
        if (typeof v !== 'string') {
          return null;
        }
        return setSRMethod(() => v);
      },
      [setSRMethod, SRMethod],
    );

  const setDefSRMethod: ReturnType<TFUseSRMethod>['setDefSRMethod'] =
    useCallback(() => {
      setSRMethod(defSRMethod);
    }, [setSRMethod, defSRMethod]);

  const onSaveSRMethod = useMemo(
    () => () => {
      if (
        defSRMethod !== SRMethod &&
        typeof SRMethod !== 'undefined' &&
        typeof id === 'string'
      ) {
        dispatch(setSRMethodRequest({ SRMethod, id, title }));
      }
    },
    [defSRMethod, SRMethod, title],
  );

  return {
    onSaveSRMethod,
    SRMethod,
    listSRMethods,
    onChangeSRMethod,
    setDefSRMethod,
    optTag,
    onChangeText,
    aggregationTag,
    onChangeAggregation,
  };
};

export const useCampaignSettigns: TFUseCampaignSettigns = ({
  aggregationTag,
  SRMethod,
  configValuesIds,
  commissionAgencyData,
  commissionSelfServiceData,
  marginalityId,
  cpcId
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatchApp();
  const { data: campaign } = useCampaignInfo();
  const [isLoadingEdit, setIsLoadingEdit] = useState<boolean>(false);
  const { data: auditDictionaryList } = useAuditDictionaryList();
  const [audiences, setAudiences] = useState<TGetAuditDictionary[]>([]);
  const [currentAudit, setCurrentAudit] = useState<string>('');

  const getAuditList = (hash: string) => {
    dispatch(
      fetchAuditDictionaryList({
        partner_xxhash: hash,
      }),
    );
  };

  useEffect(() => {
    if (campaign) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      getAuditList(campaign.bread_crumbs.Agency.xxhash);
    }
  }, [campaign]);

  useEffect(() => {
    if (auditDictionaryList) {
      setAudiences(auditDictionaryList);
    }
  }, [auditDictionaryList]);

  const getAuditIdByTitle = (
    audiencesArr: TGetAuditDictionary[],
    audTitle: string,
  ) => {
    const foundAudience = audiencesArr.find(
      (audience) => audience.title === audTitle,
    );
    return foundAudience ? foundAudience.id : 0;
  };

  type TFValidate = (values: TFormikSettigns) => FormikErrors<TFormikSettigns & Record<string, string>>;

  const validateFormCampaign: TFValidate = (values) => {
    const errors: FormikErrors<TFormikSettigns & Record<string, string>> = {};

    if (SRMethod === 'SELF_SERVICE_MARKUP' && values?.config_values) {
      [commissionAgencyData, commissionSelfServiceData].forEach(item => {
        const fieldValue = values.config_values?.[item.id] || 0

        if (+fieldValue > (100)) {
          errors[item.id] = `${i18n.t('errors.commission_limit')}`
        }

      })

      return errors
    }
    if (SRMethod !== 'MAX_FIX_CPC_MARGINALITY') return errors

    configValuesIds.forEach((id) => {
      const fieldValue = values?.config_values && values.config_values[id]
      /*       if (id === marginalityId) {
              if (fieldValue && +fieldValue > 100) {
                errors[id] = `${i18n.t('errors.percent_limit')}`
              }
              return
            } */
      if (fieldValue && +fieldValue > 2147483647) {
        errors[id] = `${i18n.t('errors.limitsym')}`
      }
    })

    if (values.hidden_commissions && values.hidden_commissions > 2147483647) {
      errors.hidden_commissions = `${i18n.t('errors.limitsym')}`
    }

    return errors;
  };

  const dashboardValuesToLowerCase = (str: string | undefined): string => {
    if (!str) return '';

    const keysArray = [
      'source',
      'idCampaign',
      'UTMCampaign',
      'UTMSource',
      'UTMTerm',
      'UTMMedium',
      'UTMContent',
    ];

    const parts = str.split('||');
    const baseString = parts[0];
    const customString = parts.length > 1 ? `||${parts[1]}` : '';

    const result =
      baseString
        .split('&')
        .map((s) => {
          const [key, value] = s.split('=');
          if (keysArray.includes(key)) {
            return `${key}=${(value ?? '').toLowerCase()}`;
          }

          return s;
        })
        .join('&') + customString;

    return result;
  };

  const formik = useFormik<TFormikSettigns>({
    onSubmit: (values) => {
      let params = {
        ...values,
        dashboard_data: dashboardValuesToLowerCase(values.dashboard_data),
        external_ids: values.external_ids,
        additional_metrics: values.additional_metrics,
        aggregation_tag: aggregationTag?.value,
        statistic_coefficient: values.statistic_coefficient?.replace(
          / /g,
          '\t',
        ),
        check_ERID: values.check_ERID,
        audit_dictionary_id: getAuditIdByTitle(audiences, currentAudit),
        method_recalculating_statistics:
          SRMethod || (campaign && campaign.method_recalculating_statistics),
        reports: {
          ...values.reports,
          items: values.reports.items.map((e) => ({
            type: e.type,
            is_enabled: e.is_enabled,
          })),
        },
        fields: {},
        is_recalculating: values.is_recalculating,
      };

      if (SRMethod !== 'COEFFICIENTS') {
        if ('statistic_coefficient' in params) {
          delete params.statistic_coefficient;
        }
      }
      if (SRMethod === 'MAX_FIX_CPC_MARGINALITY') {
        delete params.additional_metrics;
      }
      if (SRMethod !== 'MAX_FIX_CPC_MARGINALITY') {
        delete params.config_values;
        delete params.hidden_commissions;
      }

      if (SRMethod === 'SELF_SERVICE_MARKUP') {
        params.fields = {
          commission_agency:
            values.config_values?.[commissionAgencyData.id] || 0,
          commission_self_service:
            values.config_values?.[commissionSelfServiceData.id] || 0,
        };
      }

      params = {
        ...params,
        config_values: cpcId && marginalityId && values.config_values && {
          [cpcId]: values.config_values[cpcId] || 0,
          [marginalityId]: values.config_values[marginalityId] || 0,
        } || undefined,
        hidden_commissions: values.hidden_commissions || 0
      }

      delete params.config_values?.[commissionAgencyData.id];
      delete params.config_values?.[commissionSelfServiceData.id];

      const keys = Object.keys(params);

      keys.forEach((e) => {
        if (!params[e] && !typeof params[e]) {
          delete params[e];
        }
      });

      if (campaign) {
        dispatch(
          setEditPersonalCampaign({
            xxhash: campaign.xxhash,
            ...params,
            blockTitle: t(
              'campaigns_page.campaign_settings.black_menu.Settings',
            ),
            callback: {
              setIsLoading: setIsLoadingEdit,
            },
          }),
        );
      }
    },
    initialValues: {
      dashboard_data: '',
      additional_metrics: '',
      extended_report: '',
      external_ids: '',
      hidden_commissions: 0,
      reports: { use_inherit_settings: false, items: [] },
      statistic_coefficient: '',
      check_ERID: false,
      hidden_statistic: false,
      config_values: {},
      is_recalculating: true,
    },
    validationSchema: Yup.object({
      additional_metrics: Yup.string().test(
        'additional_metrics validation',
        i18n.t(`campaigns_page.campaign_settings.click_hint.error_additional_metrics`),
        (value) =>
          value || value === '' || value === undefined
            ? additionalMetricsValidation(value)
            : false,
      ),
      statistic_coefficient: Yup.string().test(
        'statistic_coefficient validation',
        i18n.t(`campaigns_page.campaign_settings.click_hint.error_statistic_coefficient`),
        (value) =>
          value || value === '' || value === undefined
            ? statisticCoeffValidation(value)
            : false,
      ),
    }),
    validate: validateFormCampaign,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true
  });

  useEffect(() => {
    if (campaign) {
      formik.setValues({
        dashboard_data: campaign.dashboard_data,
        additional_metrics: campaign.additional_metrics,
        extended_report: campaign.extended_report,
        external_ids: campaign.external_ids,
        reports: campaign.reports,
        statistic_coefficient: campaign.statistic_coefficient,
        hidden_statistic: campaign.hidden_statistic,
        check_ERID: !!campaign.check_ERID.CAMPAIGN,
        hidden_commissions: campaign.hidden_commissions,
        config_values: {},
        is_recalculating: true,
      });
    }
  }, [campaign]);

  useEffect(() => {
    if (SRMethod === 'SELF_SERVICE_MARKUP') {
      formik.validateForm()
      return
    }
    if (SRMethod === 'MAX_FIX_CPC_MARGINALITY') {
      formik.validateForm()
      return
    }
    formik.setErrors({})
  }, [SRMethod])


  useEffect(() => {
    if (campaign) {
      const currentConfigValues: Record<string, string | number | undefined> = {}
      configValuesIds.forEach(id => {
        const value = campaign.config_values ? campaign?.config_values[id] : ''
        currentConfigValues[id] = value
      });

      currentConfigValues[commissionAgencyData.id] =
        campaign.commission_agency === 0 ? '' : campaign.commission_agency;
      currentConfigValues[commissionSelfServiceData.id] =
        campaign.commission_self_service === 0
          ? ''
          : campaign.commission_self_service;

      formik.setFieldValue('config_values', currentConfigValues);
    }
  }, [campaign, configValuesIds]);

  return {
    formik,
    isLoadingEdit,
    audiences,
    setCurrentAudit,
  };
};
