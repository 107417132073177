import { shallowEqual, useSelector } from 'react-redux';
import { AppState } from '../../redux/rootReducer';
import { CreativeState, setListSort } from './reduser';
import { useDispatchApp } from '../../redux/rootSelectors';

type TFUseCreativeSortInfo = () => {
  sort: CreativeState['listSort']['sort'];
  key: CreativeState['listSort']['key'];
  isShow: boolean;
  handlerSort: (p: CreativeState['listSort']) => void;
};

export const useCreativeSortInfo: TFUseCreativeSortInfo = () => {
  const dispatch = useDispatchApp();

  const {
    listSort: { sort, key },
    isShowSortFilter,
  } = useSelector<AppState, CreativeState>(
    ({ creativeReducer }) => creativeReducer,
    shallowEqual,
  );

  const handlerSort: ReturnType<TFUseCreativeSortInfo>['handlerSort'] = (p) => {
    dispatch(setListSort(p));
  };

  return { sort, key, isShow: isShowSortFilter, handlerSort };
};
