import React, { ChangeEvent, FC, useMemo } from 'react';
import MultiSelectCheckbox from 'components/UI/MultiSelectCheckbox';
import { FormikProps } from 'formik';
import TagAutoSave from 'components/UI/TagAutoSave';
import ToggleSwitch from 'components/UI/ToggleSwitch';
import { FormikValues } from 'domains/usersAPI/model/types';
import { useUsersGroupsInfo } from 'domains/project/model/selectors';
import { usePartnersListInfo } from 'domains/partners/model/selector';
import Input from 'components/UI/Input/input';
import { useTranslation } from 'react-i18next';
import InputWithPass from 'components/InputWithPass';
import css from './styles.module.scss';

interface Props {
  formik: FormikProps<FormikValues>;
  mod: 'create' | 'edit';
  email?: string;
  tagValidationError?: string;
}

const Main: FC<Props> = ({ formik, mod, tagValidationError }) => {
  const { t } = useTranslation();
  const { data: roleOptions } = useUsersGroupsInfo();

  const { data: partners } = usePartnersListInfo();

  const partnerOptions = useMemo(
    () =>
      partners?.map((partner) => ({
        value: partner.xxhash,
        label: partner.title,
      })),
    [partners],
  );

  const createErrorTags = (errText: string | undefined) => {
    const stringStart = 'Tags: ';
    const stringEnd = ' not found';
    const tagString = errText?.slice(
      stringStart.length,
      errText.length - stringEnd.length,
    );
    return tagString?.split(', ');
  };

  return (
    <div className={css.main}>
      <div className={css.title}>{t(`project_page.main_settings`)}</div>
      <div className={css.rows}>
        {mod === 'edit' && (
          <div>
            <Input
              label={t(`form.common.login`)}
              theme="horizontal"
              error={
                !!(formik.touched.data?.email && formik.errors?.data?.email)
              }
              errortext={formik.errors?.data?.email}
              inputAttributes={{
                name: 'data.email',
                value: formik.values.data.email,
                onChange: (e: ChangeEvent<HTMLInputElement>) => {
                  formik.handleChange(e);
                },
              }}
            />
          </div>
        )}
        <InputWithPass
          theme="horizontal"
          label={t(`form.common.password`)}
          error={
            formik.touched.data?.password && !!formik.errors?.data?.password
          }
          errortext={formik.errors?.data?.password}
          inputAttributes={{
            name: 'data.password',
            style: {
              height: '40px',
            },
            placeholder: t(`form.common.password`),
            autoComplete: 'new-password',
            value: formik.values.data.password,
            onChange: (e: ChangeEvent<HTMLTextAreaElement>) => {
              formik.handleChange(e);
            },
          }}
        />
        <div className={css.multiselect}>
          <div className={css.label}>{`${t(`top.menu.partners`)} *`}</div>
          <MultiSelectCheckbox
            fieldName="data.partners"
            formik={formik}
            isError={
              !!(formik.touched.data?.partners && formik.errors.data?.partners)
            }
            errorText={formik.errors.data?.partners}
            propsValues={partnerOptions || null}
            placeholder={t(`project_page.forms.not_selected`)}
            hideIcon={false}
          />
        </div>
        {mod === 'edit' && (
          <div className={css.status}>
            <div className={css.label}>
              {t(`campaigns_page.create_campaign.status`)}
            </div>
            <ToggleSwitch
              checked={!!formik.values.data.isActive}
              activateText={t(`users_page.statuses.active`)}
              deactivateText={t(`users_page.statuses.active`)}
              onChange={() =>
                formik.setFieldValue(
                  'data.isActive',
                  !formik.values.data.isActive,
                )
              }
            />
          </div>
        )}
        <div className={css.multiselect}>
          <div className={css.label}>{t(`users_page.table.groups`)}</div>
          <MultiSelectCheckbox
            fieldName="data.groups"
            isError={false}
            formik={formik}
            propsValues={roleOptions}
            placeholder={t(`project_page.forms.not_selected`)}
          />
        </div>
        <TagAutoSave
          tagsData={formik.values.data.tags || []}
          maxLength={150}
          isErrorTags={createErrorTags(tagValidationError)}
          handlerData={(tags: string[]) =>
            formik.setFieldValue('data.tags', tags)
          }
          theme="horizontal"
          isDisable={false}
          tableView
        />
      </div>
    </div>
  );
};

export default Main;
