import React, { FC } from 'react';
import Button from 'components/UI/Buttons/buttons';
import Breadcrumbs from 'components/Breadcrumbs/breadcrumbs';
import { Helmet } from 'react-helmet';
import cn from 'classnames';
import CardsList from 'components/UI/CardList';
import Card from 'components/UI/Card';
import { useTranslation } from 'react-i18next';
import ToggleSwitch from 'components/UI/ToggleSwitch';
import Input from 'components/UI/Input/input';
import { isDisableEridToggle } from 'utils/isDisableEridToggle';
import LabelField from 'components/UI/LabelField';
import Loader from 'components/UI/Loader';
import { useEditAgencyMainSettings } from 'domains/agency/hooks/useEditAgencyMainSettings';
import { useRouteMatch } from 'react-router';
import { TID } from 'types/general';
import css from './styles.module.scss';
import UploadImage from '../UploadImage';

const MainSettings: FC = () => {
  const { t } = useTranslation();
  const { formik, onDelLoadImg, AgencyData, isLoadData } =
    useEditAgencyMainSettings();
  const { params } = useRouteMatch<TID>();

  return (
    <div className={css.settings}>
      <Helmet>
        <title>{t(`clients_page.nav_menu.basic_settings`)}</title>
      </Helmet>
      <div className={css.header}>
        <div className="container">
          <Breadcrumbs type="Agency" xxhash={params.id} />
          <div className={css.status}>
            <h1 className={css.title}>{AgencyData?.title}</h1>
          </div>
        </div>
      </div>
      <div className={css.body}>
        <div className={cn(css.container, 'container')}>
          {isLoadData ? (
            <Loader />
          ) : (
            <CardsList>
              <Card noHover isUseAccess>
                <h3>{t(`just_words.w5`)}</h3>
                <div className={css.rows}>
                  <Input
                    label={`${t('agencies_page.modal.name')} *`}
                    error={!!formik.errors.title}
                    errortext={
                      typeof formik.errors?.title === 'string'
                        ? formik.errors?.title
                        : ''
                    }
                    theme="horizontal"
                    showValueCount
                    inputAttributes={{
                      name: 'title',
                      onChange: formik.handleChange,
                      value: formik.values.title,
                      autoComplete: 'off',
                      maxLength: 128,
                    }}
                  />

                  <Input
                    label={t('agencies_page.modal.site')}
                    showValueCount
                    theme="horizontal"
                    inputAttributes={{
                      name: 'site',
                      maxLength: 128,
                      onChange: formik.handleChange,
                      value: formik.values.site,
                      autoComplete: 'off',
                    }}
                  />
                  <LabelField label={t(`creative.widget_text.check_ERID`)}>
                    <ToggleSwitch
                      disabled={isDisableEridToggle(AgencyData, 'AGENCY')}
                      onChange={() =>
                        formik.setFieldValue(
                          'check_ERID',
                          !formik.values.check_ERID,
                        )
                      }
                      checked={Boolean(formik.values.check_ERID)}
                      toggleOnly
                    />
                  </LabelField>
                </div>
              </Card>
              <div className={css.actions}>
                <Button
                  title={t(`save_btn`)}
                  buttonAttributes={{
                    type: 'submit',
                    onClick: () => formik.handleSubmit(),
                    disabled: false,
                  }}
                />
              </div>
            </CardsList>
          )}
          <CardsList>
            <Card noHover isUseAccess>
              {isLoadData ? (
                <Loader />
              ) : (
                <UploadImage
                  mode="edit"
                  onDelLoadImg={onDelLoadImg}
                  remove_icon={formik.values.remove_icon}
                />
              )}
            </Card>
          </CardsList>
        </div>
      </div>
    </div>
  );
};

export default MainSettings;
