import React, { CSSProperties, FC, useEffect, useState } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import {
  Field,
  FieldProps,
  FormikHandlers,
  FormikHelpers,
  FormikState,
} from 'formik';
import { Trans, useTranslation } from 'react-i18next';
import Loader from 'components/UI/Loader';
import Tooltip from 'components/UI/Tooltip';
import RadioGroup from 'components/UI/RadioGroup';
import { FiAlertCircle, FiExternalLink } from 'react-icons/fi';
import cn from 'classnames';
import Card from 'components/UI/Card';
import Input from 'components/UI/Input/input';
import { SelectField } from 'types/general';
import { animated, useSpring } from 'react-spring';
import { useValidateVendorLInk } from 'domains/creative/model/hooks/useValidateVendorLInk';
import Help from 'components/Help';
import { NamedProps } from 'react-select';
import { useGetFieldConfigValue } from 'domains/creative/creatives/hooks/useGetFieldConfigValue';
import checkSelectOptionsTranslation from 'utils/checkSelectOptionsTranslation';
import { useUserTheme } from 'domains/user/model/selectors';
import { useSearchBreadcrumbsInfo } from 'domains/search/model/selectors';
import InfoBlock from 'components/UI/InfoBlock';
import { useCampaignInfo } from 'domains/campaign/model/selectors';
import { getOffersList } from 'domains/offers/model/actions';
import { useOffersList } from 'domains/offers/model/selectors';
import NewSelect from 'components/UI/NewSelect';
import i18next from '../../../../../../i18n';
import css from '../styles.module.scss';
import { TFormikCreativeAdd } from '../../../../types';
import PriceInput from '../../../../../../components/UI/PriceInput/input';
import { useDispatchApp } from '../../../../../../redux/rootSelectors';
import { fetchClientInfoById } from '../../../../../client/reduser';
import { useClientInfo } from '../../../../../client/model/selectors';
import {
  optionsCategoryIAB,
  optionsLanguage,
  optionsCategoryIABEng,
  optionsLanguageEng,
} from '../../../../model/shared';

interface Props {
  sex: SelectField[];
  algorithmsSelect: SelectField[];
  violence: SelectField[];
  selectDefault: (v: string, opt: SelectField[]) => SelectField[];
  helper: ReturnType<typeof useValidateVendorLInk>['helper'];
  formik: FormikState<TFormikCreativeAdd> &
    FormikHandlers &
    FormikHelpers<TFormikCreativeAdd>;
}

const Additionally: FC<Props> = ({
  sex,
  algorithmsSelect,
  violence,
  selectDefault,
  helper,
  formik: { errors, touched, submitCount, handleChange, values, setFieldValue },
}) => {
  const dispatch = useDispatchApp();
  const { isLoading, data: client } = useClientInfo();
  const { data: bread_crumbs } = useSearchBreadcrumbsInfo();
  const [warning24, setWarning24] = useState<boolean>(false);

  useEffect(() => {
    if (values.categories.iab === '24') {
      setWarning24(true);
      return;
    }
    setWarning24(false);
  }, [values.categories]);

  const jsAudit = useGetFieldConfigValue('js_code_audit', values.type);
  const clickAuditPixel = useGetFieldConfigValue(
    'click_audit_pixel',
    values.type,
  );
  const iURL = useGetFieldConfigValue('iurl', values.type);

  const showBlock = useSpring<CSSProperties>(
    !(values.algorithm_cpc.id === '1' || values.algorithm_cpc.id === 1)
      ? {
          height: 'auto',
          visibility: 'visible',
          opacity: 1,
          transform: 'translateY(0)',
          config: { duration: 120 },
          delay: 0,
        }
      : {
          opacity: 0,
          height: 0,
          visibility: 'hidden',
          transform: 'translateY(-50px)',
          delay: 30,
        },
  );

  const customOptions: NamedProps<SelectField, boolean>['components'] = {
    Option: ({ innerProps, innerRef, label }) => (
      <div
        ref={innerRef}
        {...innerProps}
        className={cn(css.customOptions, {
          _isSub: label.indexOf('-') > -1,
        })}
      >
        {label}
      </div>
    ),
  };

  const { data: campaign } = useCampaignInfo();
  const offerId = campaign?.offer_id;

  const { data: offersList } = useOffersList();
  const currentOfferTitle = offersList?.find(
    (offer) => offer.id === offerId,
  )?.title;

  useEffect(() => {
    if (bread_crumbs?.Client?.xxhash) {
      dispatch(fetchClientInfoById(bread_crumbs.Client.xxhash));
      dispatch(
        getOffersList({ partner_xxhash_list: [bread_crumbs.Client.xxhash] }),
      );
    }
  }, [bread_crumbs?.Client?.xxhash]);

  const helperError = (...names: string[]) => {
    if (submitCount === 0) {
      return;
    }

    // eslint-disable-next-line consistent-return
    return names.some((e) => errors[e] && touched[e]);
  };
  const { t } = useTranslation();
  const currentLanguage = i18next.language;
  const currentIABOptions = checkSelectOptionsTranslation(
    optionsCategoryIAB,
    optionsCategoryIABEng,
    currentLanguage,
  );
  const currentLangOptions = checkSelectOptionsTranslation(
    optionsLanguage,
    optionsLanguageEng,
    currentLanguage,
  );

  const check_client_additions_settings =
    client &&
    (client.is_animation_setting_enabled ||
      client.is_violence_setting_enabled ||
      client.is_erotic_setting_enabled);
  const { getValueFromSettingForKey } = useUserTheme();
  return (
    <Card noHover className={css.card}>
      <h3 className={css.title}>
        {t('creative.create_creative.Additional_settings')}
        {getValueFromSettingForKey('HideHelp') && (
          <Help
            width={250}
            title={t('creative.create_creative.Additional_settings')}
            helpattributes={{
              link: t('creative.creative_prompt.additional_settings.link'),
              sizeIcon: 18,
            }}
          />
        )}
      </h3>
      <div>
        <Tabs>
          <TabList>
            <Tab
              className={cn('react-tabs__tab', {
                hasError:
                  !!errors?.adomain ||
                  !!errors?.link ||
                  !!errors?.extension_link ||
                  !!errors?.pixel ||
                  !!errors?.pixel_additional ||
                  !!errors?.js_code_audit ||
                  !!errors?.data?.show_html,
              })}
            >
              <span>{t('creative.create_creative.URL_settings')}</span>
              {(!!errors?.adomain ||
                !!errors?.link ||
                !!errors?.extension_link ||
                !!errors?.pixel ||
                !!errors?.pixel_additional ||
                !!errors?.js_code_audit ||
                !!errors?.data?.show_html) && (
                <FiAlertCircle size={16} className="alert-icon" />
              )}
            </Tab>
            {values.type !== 'MULTI_FORMAT' && (
              <Tab
                className={cn('react-tabs__tab', {
                  hasError: !!errors?.categories || !!errors?.language,
                })}
              >
                {warning24 && (
                  <FiAlertCircle
                    size={16}
                    color="#FF9C08"
                    className="warning-icon"
                  />
                )}
                <span>
                  {t('creative.create_creative.CategoriesAndLanguage')}
                </span>
                {(!!errors?.categories || !!errors?.language) && (
                  <FiAlertCircle size={16} className="alert-icon" />
                )}
              </Tab>
            )}
            {values.type !== 'CLICKUNDER' && values.type !== 'MULTI_FORMAT' && (
              <Tab
                className={cn('react-tabs__tab', {
                  hasError: !!errors?.algorithm_cpc,
                })}
              >
                <span>{t('creative.create_creative.optimization.title')}</span>
                {!!errors?.algorithm_cpc && (
                  <FiAlertCircle size={16} className="alert-icon" />
                )}
              </Tab>
            )}
          </TabList>
          <TabPanel>
            {offerId && currentOfferTitle && (
              <InfoBlock
                color="BLUE"
                text={
                  <Trans
                    i18nKey="offers_page.use_offer"
                    values={{ currentOfferTitle }}
                    components={{ bold: <strong /> }}
                  />
                }
              />
            )}
            <div className={css.form}>
              <Input
                error={!!errors.link}
                errortext={errors.link}
                helper={helper().link}
                label={t('creative.create_creative.Navigation_link')}
                disabled={!!offerId}
                inputAttributes={{
                  name: 'link',
                  placeholder: t('creative.create_creative.Link'),
                  onChange: handleChange,
                  value: values.link,
                  autoComplete: 'off',
                }}
                theme="horizontal"
                isTextarea
                classname="exactly"
              />
              <div className={css.hint}>
                <Tooltip
                  white
                  hintWidth={300}
                  title="{CAMP_ID}"
                  className={css.tooltip}
                >
                  <h6 style={{ marginBottom: 0 }}>
                    {t('creative.create_creative.tizmacr.m1.t1')}
                  </h6>
                  <p style={{ marginBottom: 0 }}>
                    {t('creative.create_creative.tizmacr.m1.t2')}
                  </p>
                  <p
                    style={{
                      marginBottom: 0,
                      color: '#828282',
                    }}
                  >
                    https://www.example.com.ru/?camp_id=6596f2bb147e5638
                  </p>
                  <a
                    style={{
                      display: 'block',
                      marginTop: '16px',
                    }}
                    href="https://terratraf.readme.io/docs/td-general-macros"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t('more_btn')} <FiExternalLink size={16} />
                  </a>
                </Tooltip>
                &nbsp;
                <Tooltip
                  white
                  hintWidth={300}
                  title="{CR_ID}"
                  className={css.tooltip}
                >
                  <h6 style={{ marginBottom: 0 }}>
                    {t('creative.create_creative.tizmacr.m2.t1')}
                  </h6>
                  <p style={{ marginBottom: 0 }}>
                    {t('creative.create_creative.tizmacr.m2.t2')}
                  </p>
                  <p
                    style={{
                      marginBottom: 0,
                      color: '#828282',
                    }}
                  >
                    https://www.example.com.ru/?cr_id=CgiaFuHAXVmrZxDSkJq5ChiGuBSC1vKXuBQ
                  </p>
                  <a
                    style={{
                      display: 'block',
                      marginTop: '16px',
                    }}
                    href="https://terratraf.readme.io/docs/td-general-macros"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t('more_btn')} <FiExternalLink size={16} />
                  </a>
                </Tooltip>
                &nbsp;
                <Tooltip
                  white
                  hintWidth={300}
                  title="{SRC_ID}"
                  className={css.tooltip}
                >
                  <h6 style={{ marginBottom: 0 }}>
                    {t('creative.create_creative.tizmacr.m3.t1')}
                  </h6>
                  <p style={{ marginBottom: 0 }}>
                    {t('creative.create_creative.tizmacr.m3.t2')}
                  </p>
                  <p
                    style={{
                      marginBottom: 0,
                      color: '#828282',
                    }}
                  >
                    https://www.example.com.ru/src_id=CgiaFuHAXVmrZxDSkJq5ChiGuBSC1vKXuBQ
                  </p>
                  <a
                    style={{
                      display: 'block',
                      marginTop: '16px',
                    }}
                    href="https://terratraf.readme.io/docs/td-general-macros"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t('more_btn')} <FiExternalLink size={16} />
                  </a>
                </Tooltip>
                &nbsp;
                <Tooltip
                  white
                  hintWidth={300}
                  title="{TR_KEY} "
                  className={css.tooltip}
                >
                  <h6 style={{ marginBottom: 0 }}>
                    {t('creative.create_creative.tizmacr.m4.t1')}
                  </h6>
                  <p style={{ marginBottom: 0 }}>
                    {t('creative.create_creative.tizmacr.m4.t2')}
                  </p>
                  <p
                    style={{
                      marginBottom: 0,
                      color: '#828282',
                    }}
                  >
                    https://www.example.com.ru/?tr_key=CgjifzCW8PhxyRCEt7flBhj-4L3vBQ
                  </p>
                  <a
                    style={{
                      display: 'block',
                      marginTop: '16px',
                    }}
                    href="https://terratraf.readme.io/docs/td-general-macros"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t('more_btn')} <FiExternalLink size={16} />
                  </a>
                </Tooltip>
              </div>
              {(values.type === 'HTML' ||
                values.type === 'VIDEO_VAST' ||
                values.type === 'VIDEO_INLINE' ||
                values.type === 'COMPANION') && (
                <Input
                  error={!!errors.extension_link}
                  errortext={errors.extension_link}
                  helper={helper().extension_link}
                  label={t('creative.create_creative.Extension_link')}
                  inputAttributes={{
                    name: 'extension_link',
                    placeholder: t('creative.create_creative.Link'),
                    onChange: handleChange,
                    value: values.extension_link,
                    autoComplete: 'off',
                  }}
                  theme="horizontal"
                  isTextarea
                  classname="exactly"
                />
              )}
              {values.type === 'HTML' && clickAuditPixel && (
                <Input
                  label={t(`creative.${clickAuditPixel.field}`)}
                  error={!!errors.click_audit_pixel}
                  errortext={errors.click_audit_pixel}
                  helper={helper().click_audit_pixel}
                  inputAttributes={{
                    name: 'click_audit_pixel',
                    onChange: handleChange,
                    placeholder: t('creative.create_creative.Link'),
                    value: values.click_audit_pixel,
                  }}
                  theme="horizontal"
                  isTextarea
                  classname="exactly"
                />
              )}
              {values.type !== 'MULTI_FORMAT' && (
                <Input
                  error={!!errors.adomain}
                  errortext={errors.adomain}
                  label={t('creative.create_creative.Domain')}
                  disabled={!!offerId}
                  inputAttributes={{
                    name: 'adomain',
                    placeholder: t('creative.create_creative.Link'),
                    onChange: handleChange,
                    value: values.adomain,
                    autoComplete: 'off',
                  }}
                  theme="horizontal"
                  isTextarea
                  classname="exactly"
                />
              )}
              {(values.type === 'HTML' ||
                values.type === 'TEASER' ||
                values.type === 'YANDEX') && (
                <>
                  <Input
                    label={t('creative.create_creative.Pixel_audit')}
                    error={!!errors.pixel}
                    errortext={errors.pixel}
                    helper={helper().pixel}
                    disabled={!!offerId}
                    inputAttributes={{
                      name: 'pixel',
                      onChange: handleChange,
                      value: values.pixel,
                      placeholder: t('creative.create_creative.Link'),
                    }}
                    theme="horizontal"
                    isTextarea
                    classname="exactly"
                  />
                  <Input
                    label={t('creative.create_creative.Optional_pixel')}
                    error={!!errors.pixel_additional}
                    errortext={errors.pixel_additional}
                    helper={helper().pixel_additional}
                    inputAttributes={{
                      name: 'pixel_additional',
                      onChange: handleChange,
                      placeholder: t('creative.create_creative.Link'),
                      value: values.pixel_additional,
                    }}
                    theme="horizontal"
                    isTextarea
                    classname="exactly"
                  />
                </>
              )}
              {values.type === 'COMPANION' && (
                <Input
                  label={t('creative.create_creative.show_html')}
                  error={!!errors.data?.show_html}
                  errortext={errors.data?.show_html}
                  helper={helper().show_html}
                  inputAttributes={{
                    name: 'data.show_html',
                    onChange: handleChange,
                    placeholder: t('creative.create_creative.Link'),
                    value: values.data.show_html,
                  }}
                  theme="horizontal"
                  isTextarea
                  classname="exactly"
                />
              )}
              {iURL && (
                <Input
                  error={!!errors.iurl}
                  errortext={errors.iurl}
                  label={iURL.field}
                  inputAttributes={{
                    name: 'iurl',
                    placeholder: t('creative.create_creative.Link'),
                    onChange: handleChange,
                    value: values.iurl === null ? '' : values.iurl,
                    autoComplete: 'off',
                  }}
                  theme="horizontal"
                  isTextarea
                  classname="exactly"
                />
              )}

              {values.type === 'HTML' && jsAudit && (
                <Input
                  label={t(`creative.${jsAudit.field}`)}
                  error={!!errors?.js_code_audit}
                  errortext={errors.js_code_audit}
                  helper={helper().js_code_audit}
                  inputAttributes={{
                    name: 'js_code_audit',
                    placeholder: t(`creative.js_code`),
                    value:
                      values.js_code_audit === null ? '' : values.js_code_audit,
                    onChange: handleChange,
                  }}
                  theme="horizontal"
                  isTextarea
                  classname="exactly"
                />
              )}
            </div>
          </TabPanel>
          {values.type !== 'MULTI_FORMAT' && (
            <TabPanel>
              <div className={css.form}>
                {isLoading && <Loader />}
                <Field name="categories.iab">
                  {({ field, form }: FieldProps) => (
                    <NewSelect
                      label="IAB"
                      form={form}
                      field={field}
                      selectAttributes={{
                        placeholder: t(
                          'creative.create_creative.select_from_list',
                        ),
                        name: 'categories.iab',
                        options: currentIABOptions,
                        value: selectDefault(
                          `${values.categories.iab}`,
                          currentIABOptions,
                        ),
                        customOptions,
                      }}
                      viewStyle="Vertical"
                    />
                  )}
                </Field>
                {warning24 && (
                  <div className={css.warning}>
                    <FiAlertCircle
                      size={16}
                      color="#FF9C08"
                      className="warning-icon"
                    />
                    <span>{t('errors.additional_cat_err')}</span>
                  </div>
                )}
                {client && check_client_additions_settings && (
                  <>
                    {client.is_animation_setting_enabled &&
                      values.type !== 'CLICKUNDER' && (
                        <RadioGroup
                          name="categories.is_animated"
                          label={t('creative.create_creative.animation')}
                          options={[
                            {
                              title: t('yes'),
                              value: true,
                            },
                            {
                              title: t('no'),
                              value: false,
                            },
                          ]}
                          setFieldValue={setFieldValue}
                          checked={values.categories.is_animated}
                        />
                      )}

                    {values.type !== 'CLICKUNDER' && (
                      <div className={css.rough}>
                        {client.is_violence_setting_enabled && (
                          <div className={css.rough_col}>
                            <Field name="categories.violence_rank">
                              {({ field, form }: FieldProps) => (
                                <NewSelect
                                  label={t(
                                    'creative.create_creative.type_jest',
                                  )}
                                  form={form}
                                  field={field}
                                  selectAttributes={{
                                    placeholder: t(
                                      'creative.create_creative.select_type',
                                    ),
                                    options: violence,
                                    value: selectDefault(
                                      `${values.categories.violence_rank}`,
                                      violence,
                                    ),
                                  }}
                                  viewStyle="Vertical"
                                />
                              )}
                            </Field>
                          </div>
                        )}

                        {client.is_erotic_setting_enabled && (
                          <div className={css.rough_col}>
                            <Field name="categories.erotic_rank">
                              {({ field, form }: FieldProps) => (
                                <NewSelect
                                  label={t(
                                    'creative.create_creative.type_erotick',
                                  )}
                                  form={form}
                                  field={field}
                                  selectAttributes={{
                                    placeholder: t(
                                      'creative.create_creative.select_type',
                                    ),
                                    options: sex,
                                    value: selectDefault(
                                      `${values.categories.erotic_rank}`,
                                      sex,
                                    ),
                                  }}
                                  viewStyle="Vertical"
                                />
                              )}
                            </Field>
                          </div>
                        )}
                      </div>
                    )}
                  </>
                )}
                <Field name="language">
                  {({ field, form }: FieldProps) => (
                    <NewSelect
                      label={t('creative.create_creative.Language')}
                      form={form}
                      field={field}
                      selectAttributes={{
                        placeholder: t(
                          'creative.create_creative.select_from_list',
                        ),
                        name: 'language',
                        options: currentLangOptions,
                        value: selectDefault(
                          `${values.language}`,
                          currentLangOptions,
                        ),
                      }}
                      viewStyle="Vertical"
                    />
                  )}
                </Field>
              </div>
            </TabPanel>
          )}
          {values.type !== 'CLICKUNDER' && values.type !== 'MULTI_FORMAT' && (
            <TabPanel>
              <div
                className={cn(css.form, {
                  single:
                    values.algorithm_cpc.id === '1' ||
                    values.algorithm_cpc.id === 1,
                })}
              >
                <Field name="algorithm_cpc.id">
                  {({ field, form }: FieldProps) => (
                    <NewSelect
                      label={t(
                        'creative.create_creative.optimization.algorithm',
                      )}
                      form={form}
                      field={field}
                      selectAttributes={{
                        placeholder: t('creative.create_creative.select_type'),
                        options: algorithmsSelect,
                        value: selectDefault(
                          `${values.algorithm_cpc.id}`,
                          algorithmsSelect,
                        ),
                      }}
                      viewStyle="Vertical"
                    />
                  )}
                </Field>
                <animated.div style={showBlock} className={css.sizes}>
                  <div className={cn(css.sizes_col)}>
                    <PriceInput
                      label={t('creative.create_creative.optimization.min')}
                      error={
                        helperError('algorithm_cpc.min') ||
                        !!errors.algorithm_cpc?.min
                      }
                      errorTextNull={errors.algorithm_cpc?.min === 'true'}
                      disabled={
                        values.algorithm_cpc.id === 1 ||
                        values.algorithm_cpc.id === '1'
                      }
                      errorText={errors.algorithm_cpc?.min}
                      numberFormatAttributes={{
                        decimalScale: 3,
                        maxLength: 18,
                        fixedDecimalScale: false,
                        value: values.algorithm_cpc.min,
                        onValueChange: ({ floatValue }) => {
                          setFieldValue('algorithm_cpc.min', floatValue);
                        },
                        onFocus: () => {
                          if (values.algorithm_cpc.min === 0) {
                            setFieldValue('algorithm_cpc.min', null);
                          }
                        },
                      }}
                    />
                  </div>
                  <div className={cn(css.sizes_col)}>
                    <PriceInput
                      label={t('creative.create_creative.optimization.max')}
                      error={
                        helperError('algorithm_cpc.max') ||
                        !!errors.algorithm_cpc?.max
                      }
                      disabled={
                        values.algorithm_cpc.id === 1 ||
                        values.algorithm_cpc.id === '1'
                      }
                      errorText={errors.algorithm_cpc?.max}
                      numberFormatAttributes={{
                        decimalScale: 3,
                        maxLength: 18,
                        fixedDecimalScale: false,
                        value: values.algorithm_cpc.max,
                        onValueChange: ({ floatValue }) => {
                          setFieldValue('algorithm_cpc.max', floatValue);
                        },
                        onFocus: () => {
                          if (values.algorithm_cpc.max === 0) {
                            setFieldValue('algorithm_cpc.max', null);
                          }
                        },
                      }}
                    />
                  </div>
                </animated.div>
              </div>
            </TabPanel>
          )}
        </Tabs>
      </div>
    </Card>
  );
};

export default Additionally;
