import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RGetPersonal, TStatisticsFields } from 'domains/campaign/types';
import { TDates, TStatisticType } from 'types/general';
import { FetchedData, genFetchedData } from '../../redux/fetchedData';
import { makeGenList, TMakeGenList } from '../../redux/makeGenList';
import {
  ParamsCampaigns,
  ParamsCampaignsStatistics,
  ParamSetStatusCampaignsSaga,
  PayloadParamsAddCampaigns,
  RCampaignsArray,
  RCampaignsArrayStata,
  ResponseStatisticsCampaigns,
  RStatisticsCampaigns,
  RStatisticsCampaignsWeek,
  TBalance,
  TCampaignsFilters,
  TPartnerStore,
  ResponseTranslation,
  ResponseCampaignsStata,
  WeekStatistic,
  TotalResponse,
  ParamsStatisticType,
} from './types';

export interface CampaignsState {
  tempCampaigns: FetchedData<RCampaignsArrayStata>;
  tempCampaignsModal: FetchedData<RCampaignsArray>;
  view: TMakeGenList<RCampaignsArrayStata>;
  tempStatistic: FetchedData<RStatisticsCampaigns>;
  chartStatistic: FetchedData<WeekStatistic>;
  tempStatisticWeek: FetchedData<RStatisticsCampaignsWeek>;
  tempStatisticModal: FetchedData<ResponseStatisticsCampaigns[]>;
  tempTranslation: FetchedData<Record<string, ResponseTranslation>>;
  lastId: string;
  lastStatus: string;
  listSort: {
    key: string | null;
    sort: 'ASC' | 'DESC' | null;
  };
  isShowSortFilter: boolean;
  campaignsPeriod: TDates['period'];
  balance: FetchedData<TBalance>;
  statisticFieldList: FetchedData<TStatisticsFields>;
  partner: TPartnerStore;
  filter: TCampaignsFilters;
  campaignTranslationFilter: ResponseCampaignsStata[] | null;
  total: FetchedData<TotalResponse>;
  campaignsIcons: string[];
  statisticType: FetchedData<TStatisticType[]>;
}

const initialState: CampaignsState = {
  tempCampaigns: genFetchedData<RCampaignsArrayStata>(null),
  tempCampaignsModal: genFetchedData<RCampaignsArray>(null),
  view: makeGenList<RCampaignsArrayStata>(),
  tempStatistic: genFetchedData<RStatisticsCampaigns>(null),
  chartStatistic: genFetchedData<WeekStatistic>(null),
  tempStatisticWeek: genFetchedData<RStatisticsCampaignsWeek>(null),
  tempStatisticModal: genFetchedData<ResponseStatisticsCampaigns[]>(null),
  tempTranslation: genFetchedData<Record<string, ResponseTranslation>>(null),
  lastId: '',
  lastStatus: '',
  listSort: {
    key: null,
    sort: null,
  },
  isShowSortFilter: false,
  campaignsPeriod: {
    from: '',
    to: '',
  },
  balance: genFetchedData<TBalance>(null),
  statisticFieldList: genFetchedData<TStatisticsFields>(null),
  partner: {
    name: '',
    xxhash: '',
  },
  filter: [],
  campaignTranslationFilter: null,
  total: genFetchedData<TotalResponse>(null),
  campaignsIcons: [],
  statisticType: genFetchedData<TStatisticType[]>(null),
};

const campaignsSlice = createSlice({
  name: 'Campaigns',
  initialState,
  reducers: {
    setTempFetchCampaigns(
      state,
      action: PayloadAction<CampaignsState['tempCampaigns']>,
    ) {
      state.tempCampaigns = action.payload;
    },
    setClearCampaignsList(state) {
      state.tempCampaigns = initialState.tempCampaigns;
    },
    setTempFetchCampaignsSuccess(
      state,
      action: PayloadAction<CampaignsState['tempCampaigns']>,
    ) {
      state.tempCampaigns = action.payload;
    },
    setCampaignsListView(state, action: PayloadAction<CampaignsState['view']>) {
      state.view = action.payload;
    },
    setCampaignsListModal(
      state,
      action: PayloadAction<CampaignsState['tempCampaignsModal']>,
    ) {
      state.tempCampaignsModal = action.payload;
    },
    setTempFetchStatistic(
      state,
      action: PayloadAction<CampaignsState['tempStatistic']>,
    ) {
      state.tempStatistic = action.payload;
    },
    setChartStatistic(
      state,
      action: PayloadAction<CampaignsState['chartStatistic']>,
    ) {
      state.chartStatistic = action.payload;
    },
    clearChartStatistic(state) {
      state.chartStatistic = initialState.chartStatistic;
    },
    setTempFetchStatisticWeek(
      state,
      action: PayloadAction<CampaignsState['tempStatisticWeek']>,
    ) {
      state.tempStatisticWeek = action.payload;
    },
    setTempFetchTranslation(
      state,
      action: PayloadAction<CampaignsState['tempTranslation']>,
    ) {
      state.tempTranslation = action.payload;
    },
    setLastId(state, action: PayloadAction<CampaignsState['lastId']>) {
      state.lastId = action.payload;
    },
    setLastStatus(state, action: PayloadAction<CampaignsState['lastStatus']>) {
      state.lastStatus = action.payload;
    },
    delLastId(state) {
      state.lastId = initialState.lastId;
    },
    delLastStatus(state) {
      state.lastStatus = initialState.lastStatus;
    },
    setListSort(state, action: PayloadAction<CampaignsState['listSort']>) {
      state.listSort = action.payload;
    },
    setIsShowSortFilter(
      state,
      action: PayloadAction<CampaignsState['isShowSortFilter']>,
    ) {
      state.isShowSortFilter = action.payload;
    },
    setCampaignsPeriod(
      state,
      action: PayloadAction<CampaignsState['campaignsPeriod']>,
    ) {
      state.campaignsPeriod = action.payload;
    },
    clearCampaignsPeriod(state) {
      state.campaignsPeriod = initialState.campaignsPeriod;
    },
    setBalance(state, action: PayloadAction<CampaignsState['balance']>) {
      state.balance = action.payload;
    },
    resetBalance(state) {
      state.balance = initialState.balance;
    },
    setStatisticFieldList(
      state,
      action: PayloadAction<CampaignsState['statisticFieldList']>,
    ) {
      state.statisticFieldList = action.payload;
    },
    resetStatisticFieldList(state) {
      state.statisticFieldList = initialState.statisticFieldList;
    },
    setPartner(state, action: PayloadAction<CampaignsState['partner']>) {
      state.partner = action.payload;
    },
    setCampaignsFilter(state, action: PayloadAction<CampaignsState['filter']>) {
      state.filter = action.payload;
    },
    setCampaignTranslationFilter(
      state,
      action: PayloadAction<CampaignsState['campaignTranslationFilter']>,
    ) {
      state.campaignTranslationFilter = action.payload;
    },
    setTotal(state, action: PayloadAction<CampaignsState['total']>) {
      state.total = action.payload;
    },
    setCampaignsIcons(
      state,
      action: PayloadAction<CampaignsState['campaignsIcons']>,
    ) {
      state.campaignsIcons = action.payload;
    },
    setStatisticType(
      state,
      action: PayloadAction<CampaignsState['statisticType']>
    ) {
      state.statisticType = action.payload;
    }
  },
});

export const fetchCampaigns = createAction<ParamsCampaigns>(
  'Campaigns/fetchCampaigns',
);
export const fetchChartStatistic = createAction<ParamsCampaignsStatistics>(
  'Campaigns/fetchChartStatistic',
);
export const fetchStatisticType = createAction<ParamsStatisticType>(
  'Campaigns/fetchStatisticType'
);
export const fetchCampaignsStatistic = createAction<
  ParamsCampaignsStatistics & { xxhash_list_total?: string[] | null }
>('Campaigns/fetchCampaignsStatistic');
export const fetchCampaignsStatisticWeek = createAction<
  ParamsCampaignsStatistics & { xxhash_list_total?: string[] | null }
>('Campaigns/fetchCampaignsStatisticWeek');
export const fetchCampaignsTranslation =
  createAction<ParamsCampaignsStatistics>(
    'Campaigns/fetchCampaignsTranslation',
  );
export const setToggleStatusCampaign = createAction<
  ParamSetStatusCampaignsSaga<string | boolean>
>('Campaigns/toggleStatusCampaign');
export const createCampaignAction = createAction<PayloadParamsAddCampaigns>(
  'Campaigns/createCampaignAction',
);
export const fetchCampaignsTotal = createAction<
  ParamsCampaignsStatistics & { debounce?: number }
>('statistic/fetchCampaignsTotal');
export const fetchCampaignsIcons = createAction('creative/fetchCampaignsIcons');

export const setCampaignsIcon = createAction<{
  xxhash: RGetPersonal['xxhash'];
  icon: string;
}>('creative/setCampaignsIcon');
export const clearCampaignsIcon = createAction<{
  xxhash: RGetPersonal['xxhash'];
}>('creative/clearCampaignsIcon');

export const {
  setTempFetchCampaigns,
  setTempFetchCampaignsSuccess,
  setCampaignsListModal,
  setCampaignsListView,
  setTempFetchStatistic,
  setChartStatistic,
  setTempFetchStatisticWeek,
  setCampaignTranslationFilter,
  delLastId,
  delLastStatus,
  setLastId,
  setLastStatus,
  setListSort,
  setIsShowSortFilter,
  setCampaignsPeriod,
  clearCampaignsPeriod,
  setBalance,
  setPartner,
  resetBalance,
  setStatisticFieldList,
  resetStatisticFieldList,
  setCampaignsFilter,
  setTempFetchTranslation,
  setClearCampaignsList,
  clearChartStatistic,
  setTotal,
  setCampaignsIcons,
  setStatisticType
} = campaignsSlice.actions;
export const campaignsReducer = campaignsSlice.reducer;
