import { useCampaignExperimental } from 'domains/campaign/Experimental/hooks';
import { TExperimentalInfoField } from 'domains/campaign/types';
import { useMemo } from 'react';
import { getCurrentVariableField } from './getCurrentVariableField';

type TReturn = {
  cpcData: TExperimentalInfoField | undefined;
  marginalityData: TExperimentalInfoField | undefined;
  configValuesIds: number[];
  commissionAgencyData: TExperimentalInfoField;
  commissionSelfServiceData: TExperimentalInfoField;
  experimentalInfo: TExperimentalInfoField[] | null;
};

/**
 * Хук возвращает cpcData и marginalityData
 * @returns cpcData, marginalityData, experimentalInfo
 */
export const useExperimentalData = (): TReturn => {
  const { experimentalInfo } = useCampaignExperimental();

  const commissionAgencyData: TExperimentalInfoField = {
    default_value: 0,
    id: 100,
    field: 'commission_agency',
    title: 'Комиссия агентства',
    type: 'float',
    page: '',
    description: 'Для изменения CPM в DSP применяется двойная наценка. Скрытая комиссия агентства и наша комиссия за self-service',
    configFieldsList: [],
    max: 9999.999999,
    min: 0,
  }
  const commissionSelfServiceData: TExperimentalInfoField = {
    default_value: 0,
    id: 101,
    field: 'commission_self_service',
    title: 'Комиссия Self-Service',
    type: 'float',
    page: '',
    description: 'Для изменения CPM в DSP применяется двойная наценка. Скрытая комиссия агентства и наша комиссия за self-service',
    configFieldsList: [],
    max: 9999.999999,
    min: 0,
  }

  const cpcData = useMemo(
    () => getCurrentVariableField(experimentalInfo, 'max_cpc'),
    [experimentalInfo],
  );

  const marginalityData = useMemo(
    () => getCurrentVariableField(experimentalInfo, 'marginality'),
    [experimentalInfo],
  );

  const createConfigIdArr = (
    arr: (TExperimentalInfoField | undefined)[],
  ): number[] => {
    const resultArr: number[] = [];
    arr.forEach((item) => item && resultArr.push(item.id));
    return resultArr;
  };

  const configValuesIds = useMemo(
    () => createConfigIdArr([cpcData, marginalityData]),
    [cpcData, marginalityData],
  );

  return {
    cpcData,
    marginalityData,
    configValuesIds,
    commissionAgencyData,
    commissionSelfServiceData,
    experimentalInfo
  };
};
