import React, { FC, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import cn from 'classnames';
import { useHistory, useLocation } from 'react-router-dom';
import {
  FiX,
  FiTrendingUp,
  FiUsers,
  FiUpload,
  FiExternalLink,
  FiColumns,
  FiCode,
  FiLink2,
} from 'react-icons/fi';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import { fetchDCOUrl, openUrlByToken } from 'domains/user/reduser';
import { useProjectInfo } from 'domains/project/hooks';
import agencySvg from './agency.svg';
import campaing from './campaing.svg';
import report from './report.svg';
import './styles.scss';
import Dropdown from './Dropdown';
import { everyGroups, someGroups } from '../../utils/statics/everyGroups';
import { useModalBwListUpdate } from '../../domains/bw_lists_update/modal/hooks/useModal';
import { useUserInfo } from '../../domains/user/model/selectors';
import { ReactComponent as OrdSvg } from './ord.svg';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const SideMenu: FC<Props> = ({ isOpen, onClose }) => {
  const { openModal } = useModalBwListUpdate();
  const history = useHistory();
  const location = useLocation();
  const { data: user } = useUserInfo();
  const { data: project } = useProjectInfo();

  const dispatch = useDispatch();
  const onClick = (to: string) => {
    history.push(to);
    onClose();
  };

  const { t } = useTranslation();

  const openLoad = useCallback(() => {
    dispatch(openUrlByToken('http://import.dashboard.terratraf.io/auth/?key='));
  }, [localStorage.getItem('$token'), user, location]);

  const panelSupTitle = () => {
    if (location.pathname.includes('partners')) {
      return t('top.menu.partners');
    }
    if (location.pathname.includes('project')) {
      return t(`top.menu.project`);
    }
    if (location.pathname.includes('users')) {
      return t('top.menu.users');
    }
    if (location.pathname.includes('users_api')) {
      return t(`users_page.users_api`);
    }
    if (location.pathname.includes('history')) {
      return t('top.menu.history_list');
    }
    if (location.pathname.includes('analysis')) {
      return t('analysis_page.title');
    }
    if (location.pathname.includes('contracts')) {
      return t(`ord.contracts_title`);
    }
    if (location.pathname.includes('counteragents')) {
      return t(`ord.сounterparties_title`);
    }
    if (location.pathname.includes('blackLists')) {
      return t(`blackLists.title`);
    }
    return '';
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'initial';
    }
  }, [isOpen]);

  return (
    <div>
      <div className={cn('side-menu', { isOpened: isOpen })}>
        <div className="side-menu__bg" onClick={onClose} />
        <div className="side-menu__card">
          <div className="header">
            <div className="header__close">
              <FiX size={24} onClick={onClose} />
            </div>
          </div>
          <div className="menu">
            {user && user.partner.type === 'ORGANIZATION' && (
              <button
                type="button"
                onClick={() => onClick('/main/agencies')}
                className={cn('menu__item', {
                  isActive: location.pathname.includes('agencies'),
                })}
              >
                <ReactSVG size={24} src={agencySvg} className="menu__icon" />

                <span>{t('top.menu.agency')}</span>
              </button>
            )}
            {((user && user.partner.type === 'AGENCY') ||
              (user && user.partner.type === 'ORGANIZATION')) && (
              <button
                type="button"
                onClick={() => onClick('/main/clients')}
                className={cn('menu__item', {
                  isActive: location.pathname.includes('clients'),
                })}
              >
                <FiUsers size={24} />
                <span>{t('top.menu.clients')}</span>
              </button>
            )}
            {user && someGroups(user, 'default', 'owner', 'self_user') && (
              <button
                type="button"
                onClick={() => onClick('/main/campaigns')}
                className={cn('menu__item', {
                  isActive: location.pathname.includes('campaign'),
                })}
              >
                <ReactSVG src={campaing} className="svg" />
                <span>{t('top.menu.campaigns')}</span>
              </button>
            )}
            {user &&
              user.partner.type === 'CLIENT' &&
              everyGroups(user, 'reports_client') && (
                <button
                  type="button"
                  onClick={() =>
                    onClick(`/main/client/${user.partner.xxhash}/reports`)
                  }
                  className={cn('menu__item', {
                    isActive: location.pathname.includes('report'),
                  })}
                >
                  <ReactSVG src={report} className="svg" />
                  <span>{t(`clients_page.client_report`)}</span>
                </button>
              )}
            {user &&
              user.partner.type === 'AGENCY' &&
              everyGroups(user, 'reports_client') && (
                <button
                  type="button"
                  onClick={() =>
                    onClick(`/main/agency/${user.partner.xxhash}/reports`)
                  }
                  className={cn('menu__item', {
                    isActive: location.pathname.includes('report'),
                  })}
                >
                  <ReactSVG src={report} className="svg" />
                  <span>{t(`agencies_page.agencies_report`)}</span>
                </button>
              )}
            {user &&
              user.partner.type === 'AGENCY' &&
              ((user.group.includes('owner') &&
                user.group.includes('reports_agency')) ||
                (user.group.includes('self_user') &&
                  user.group.includes('reports_agency'))) && (
                <button
                  type="button"
                  onClick={() =>
                    onClick(`/main/agency/${user.partner.xxhash}/reports_au`)
                  }
                  className={cn('menu__item', {
                    isActive: location.pathname.includes('reports_au'),
                  })}
                >
                  <ReactSVG src={report} className="svg" />
                  <span>{t(`agencies_page.report.title`)}</span>
                </button>
              )}
            {someGroups(user, 'owner', 'self_user') &&
              (user?.partner.type === 'AGENCY' ||
                user?.partner.type === 'ORGANIZATION') &&
              project?.ord_registration && (
                <Dropdown
                  menuIcon={<OrdSvg />}
                  menuText={<span>{t(`ord.title`)}</span>}
                  selectedItem={panelSupTitle()}
                  showSelectedItem={
                    location.pathname.includes('contracts') ||
                    location.pathname.includes('counteragents')
                  }
                  toggleSide={isOpen}
                >
                  <button
                    className={cn({
                      isActive: location.pathname.includes('counteragent'),
                    })}
                    type="button"
                    onClick={() => onClick('/main/counteragents')}
                  >
                    <span>{t(`ord.сounterparties_title`)}</span>
                  </button>
                  <button
                    className={cn({
                      isActive: location.pathname.includes('contracts'),
                    })}
                    type="button"
                    onClick={() => onClick('/main/contracts')}
                  >
                    <span>{t(`ord.contracts_title`)}</span>
                  </button>
                </Dropdown>
              )}
            {user && someGroups(user, 'owner', 'self_user') && (
              <button
                type="button"
                onClick={() => onClick('/main/offers')}
                className={cn('menu__item', {
                  isActive: location.pathname.includes('offers'),
                })}
              >
                <FiLink2 size={24} />
                <span>{t(`offers_page.offers`)}</span>
              </button>
            )}
            {user && everyGroups(user, 'container') && (
              <button
                type="button"
                onClick={() => onClick('/main/containers')}
                className={cn('menu__item', {
                  isActive: location.pathname.includes('container'),
                })}
              >
                <FiTrendingUp size={24} />
                <span>{t('top.menu.containers')}</span>
              </button>
            )}

            {everyGroups(user, 'dco_viewer') && (
              <a
                onClick={(event) => {
                  event.preventDefault();
                  dispatch(fetchDCOUrl());
                }}
                href="http://uploads.mediasniper.ru/getjson/"
                className="menu__link"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FiCode size={24} />
                <span>DCO</span>
                <FiExternalLink size={16} />
              </a>
            )}
            {process.env.REACT_APP_API !== 'https://tdm5.adcloud.org/api/' &&
              everyGroups(user, 'owner') && (
                <button className="menu__link" onClick={openLoad} type="button">
                  <FiUpload size={24} />
                  <span>{t('top.menu.load_data')}</span>
                  <FiExternalLink size={16} />
                </button>
              )}
            {everyGroups(user, 'manager') && (
              <Dropdown
                menuIcon={<FiColumns size={24} />}
                menuText={<span>{t('top.menu.control_panel')}</span>}
                selectedItem={panelSupTitle()}
                showSelectedItem={
                  location.pathname.includes('project') ||
                  location.pathname.includes('partners') ||
                  location.pathname.includes('users') ||
                  location.pathname.includes('users_api') ||
                  location.pathname.includes('history') ||
                  location.pathname.includes('analysis') ||
                  location.pathname.includes('blackLists')
                }
                toggleSide={isOpen}
              >
                {everyGroups(user, 'owner') && (
                  <button
                    className={cn({
                      isActive: location.pathname.includes('project'),
                    })}
                    type="button"
                    onClick={() => onClick('/control_panel/project')}
                  >
                    <span>{t('top.menu.project')}</span>
                  </button>
                )}
                <button
                  className={cn({
                    isActive: location.pathname.includes('partners'),
                  })}
                  type="button"
                  onClick={() => onClick('/control_panel/partners')}
                >
                  <span>{t('top.menu.partners')}</span>
                </button>
                <button
                  className={cn({
                    isActive:
                      location.pathname.includes('users') &&
                      !location.pathname.includes('users_api'),
                  })}
                  type="button"
                  onClick={() => onClick('/control_panel/users')}
                >
                  <span>{t('top.menu.users')}</span>
                </button>
                {everyGroups(user, 'owner', 'manager') && (
                  <button
                    className={cn({
                      isActive: location.pathname.includes('users_api'),
                    })}
                    type="button"
                    onClick={() => onClick('/control_panel/users_api')}
                  >
                    <span>{t(`users_page.users_api`)}</span>
                  </button>
                )}
                {everyGroups(user, 'manager') && (
                  <button
                    className={cn({
                      isActive: location.pathname.includes('history'),
                    })}
                    type="button"
                    onClick={() => onClick('/control_panel/history_changes')}
                  >
                    <span>{t('top.menu.history_list')}</span>
                  </button>
                )}
                {everyGroups(user, 'owner', 'manager') && (
                  <button
                    className={cn({
                      isActive: location.pathname.includes('analysis'),
                    })}
                    type="button"
                    onClick={() => onClick('/control_panel/analysis')}
                  >
                    <span>{t('analysis_page.title')}</span>
                  </button>
                )}
                {everyGroups(user, 'bw_lists_update') && (
                  <button
                    type="button"
                    onClick={() => {
                      openModal();
                    }}
                  >
                    <span>{t('top.menu.uploadbwl')}</span>
                  </button>
                )}
                {everyGroups(user, 'owner', 'manager') && (
                  <button
                    className={cn({
                      isActive: location.pathname.includes('blackLists'),
                    })}
                    type="button"
                    onClick={() => onClick('/control_panel/blackLists')}
                  >
                    <span>{t(`blackLists.title`)}</span>
                  </button>
                )}
              </Dropdown>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideMenu;
