import { usePartnersListInfo } from 'domains/partners/model/selector';
import { useDispatchApp } from 'redux/rootSelectors';
import { useUsersGroupsInfo } from 'domains/project/model/selectors';
import {
  GetManagementUsersList,
  POptions,
  PUserFormikParams,
  SParthner,
  TFormRegistration,
} from 'domains/users/model/types';
import { FormikErrors, FormikProps, useFormik } from 'formik';
import { objectOptimizationWithFormik } from 'utils/objectOptimizationWithFormik/objectOptimizationWithFormik';
import { useTranslation } from 'react-i18next';
import {
  createNewUser,
  editUser,
  resetPasswordRequest,
} from 'domains/users/model/actions';
import { useState } from 'react';

interface RUseCreateUser {
  partnerOptions?: POptions[];
  selectedPartner: SParthner;
  roleOptions: any;
  formik: FormikProps<TFormRegistration>;
  isLoading: boolean;
  resetPassword: () => void;
}

interface formikErrors {
  email: string;
  groups: string;
}

/* const groupsDictionary = {
  default: '1',
  owner: '2',
  raw_stat: '3',
  test: '4',
  manager: '7',
  bw_lists_update: '8',
  tabs_with_targets: '9',
  dco_viewer: '10',
  tabs_with_creatives: '11',
  view_hidden_partner: '12',
  self_user: '13',
  experimental_owner: '14',
  reports_client: '17',
  container: '18',
  field_stats_retarget: '19',
}; */

export const useCreateUser = (
  item: GetManagementUsersList | undefined,
  onClose: () => void,
  mode: string,
): RUseCreateUser => {
  const [isLoading, setIsLoading] = useState(false);
  const { data: partners } = usePartnersListInfo();
  const { data: roleOptions } = useUsersGroupsInfo();

  const dispatch = useDispatchApp();
  const { t } = useTranslation();

  const partnerOptions = partners?.map((partner) => ({
    value: partner.xxhash,
    label: partner.title,
    additional: partner,
  }));

  const roleLabels = roleOptions?.map((role) => role.label);

  const groupsOptions = item?.groups.map((group) => ({
    label: group,
    value:
      roleOptions?.find((groupItem) => groupItem?.label === group)?.value ||
      null,
    isFixed: !roleLabels?.includes(group),
  }));

  const selectedPartner = {
    value: item?.partner.xxhash,
    label: item?.partner.title,
    additional: item?.partner,
  };

  const validate = (values: TFormRegistration) => {
    const errors: FormikErrors<formikErrors> = {};

    if (!values.email) {
      errors.email = t(`form.common.enter_email`);
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = t(`form.common.wrong_format`);
    } else if (values.email.includes('@')) {
      const subDomain = values.email.split('@');
      if (subDomain[0].length > 64) {
        errors.email = 'Субдомен не может быть больше 64 символов';
      }
    }
    if (!values.groups?.length) {
      errors.groups = t(`errors.err45`);
    }
    return errors;
  };

  const initialValues = {
    email: item?.email || '',
    partner_xxhash: { label: '', value: '' },
    groups: groupsOptions || [],
  };

  const formik = useFormik<TFormRegistration>({
    initialValues,
    validate,
    onSubmit: (values) => {
      const paramData = objectOptimizationWithFormik<TFormRegistration>(
        initialValues,
        values,
      );
      const userParams: PUserFormikParams = {
        ...paramData,
        email: values.email,
        partner_xxhash: values?.partner_xxhash?.value,
        groups: values.groups
          .map((group) => (group.value ? +group.value : null))
          .filter((group) => group !== null),
        callbacks: { onClose, setIsLoading },
      };
      if (mode === 'edit' && item?.status) {
        if (
          item?.email === userParams.email &&
          item?.partner.xxhash === userParams.partner_xxhash &&
          item?.groups
            .map(
              (group) =>
                roleOptions?.find((itemGroup) => itemGroup.label === group)
                  ?.value || null,
            )
            .filter((group) => group !== null)
            .sort()
            .join() === userParams.groups?.sort().join()
        ) {
          onClose();
          return;
        }
        dispatch(editUser(userParams));
        return;
      }
      dispatch(
        createNewUser({
          ...userParams,
          domain: window.location.host,
        }),
      );
    },
  });

  const resetPassword = () => {
    dispatch(
      resetPasswordRequest({
        email: formik.values.email,
        domain: window.location.host,
      }),
    );
  };

  return {
    partnerOptions,
    selectedPartner,
    roleOptions,
    formik,
    isLoading,
    resetPassword,
  };
};
