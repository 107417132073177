import { CreativeState, delLastId, setLastId } from "domains/creative/reduser";
import { useCallback, useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { AppState } from "redux/rootReducer";
import { useDispatchApp } from "redux/rootSelectors";

type TFUseHighlights = () => string;
type TFUseSetLastIdCreative = () => void;
type TFUseDelLastIdCreative = () => () => void;
type TFUseCreativeListFilter = () => {
  listFilter: CreativeState['listFilter'];
};

/** возврощает последний посещенный ид */
export const useHighlights: TFUseHighlights = () => {
    const dispatch = useDispatchApp();
    const [id, setId] = useState<string>('');
    const history = useHistory();
    const lastId = useSelector<AppState, CreativeState['lastId']>(
      ({ creativeReducer }) => creativeReducer.lastId,
      shallowEqual,
    );
    useEffect(() => {
      if (!lastId) return;
      history.push(`?highlights=${lastId}`);
    }, [lastId]);

    useEffect(() => {
      const url = new URL(window.location.href);
      const highlights = url.searchParams.get('highlights') ?? '';
      dispatch(setLastId(highlights));
      setId(highlights);
    }, [window.location.href]);

    return id;
  };
  
  /** устанвливает последний посещенный ид из сылки */
  export const useSetLastIdCreative: TFUseSetLastIdCreative = () => {
    const dispatch = useDispatchApp();
    const { id } = useParams<{ id?: string }>();
    useEffect(() => {
      if (!id) return;
      dispatch(setLastId(id));
    }, [id]);
  };
  
  /** удаляет последний посещеный ид */
  export const useDelLastIdCreative: TFUseDelLastIdCreative = () => {
    const dispatch = useDispatchApp();
    return useCallback(() => {
      dispatch(delLastId());
    }, []);
  };
  
  /** возвращает состояние фильтра списка креативов */
  export const useCreativeListFilter: TFUseCreativeListFilter = () => {
    const listFilter = useSelector<AppState, CreativeState['listFilter']>(
      ({ creativeReducer }) => creativeReducer.listFilter,
      shallowEqual,
    );
    return {
      listFilter,
    };
  };