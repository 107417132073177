import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { TMakeReqWithRD, makeReqWithRD } from 'redux/makeReqWithRD';
import { genFetchedData } from 'redux/fetchedData';
import { toastSuccessBold } from 'utils/ToastUtils/toastSuccessBold';
import { createCounteragentAPI, getCounteragentAPI, updateCounteragentAPI } from '../api';
import { createCounteragent, getCounteragent, updateCounteragent } from '../actions';
import { setCounteragentData } from '../reducer';
import { CounteragentItem } from '../types';

export function* workerGetCounteragent({
  payload,
}: ReturnType<typeof getCounteragent>): SagaIterator<void> {
  try {
    yield call<TMakeReqWithRD<typeof getCounteragentAPI>>(makeReqWithRD, {
      fetcher: getCounteragentAPI,
      fill: setCounteragentData,
      parameters: payload,
    });
  } catch (e) {
    if (e.code === 90) {
      const newRD = genFetchedData<CounteragentItem>(null)
      newRD.set('error', {
        isError: true,
        message: 'Not found',
        code: e.code
      })
      yield put(setCounteragentData(newRD))
    }
    console.error({ e });
  }
}

export function* workerCreateCounteragent({
  payload,
}: ReturnType<typeof createCounteragent>): SagaIterator<void> {
  const { callbacks, ...params } = payload
  try {
    if (callbacks.setIsLoading) {
      callbacks.setIsLoading(true)
    }
    const result = yield call(createCounteragentAPI, params)
    if (result) {
      toastSuccessBold(`ord.counterparty_created`, {
        title: params.objectCuster.name
      })
      callbacks.goBack()
    }
  } catch (e) {
    console.error({ e });
  } finally {
    if (callbacks.setIsLoading) {
      callbacks.setIsLoading(false)
    }
  }
}

export function* workerUpdateCounteragent({
  payload,
}: ReturnType<typeof updateCounteragent>): SagaIterator<void> {
  const { callbacks, ...params } = payload
  try {
    if (callbacks.setIsLoading) {
      callbacks.setIsLoading(true)
    }
    const result = yield call(updateCounteragentAPI, params)
    if (result) {
      toastSuccessBold(`ord.contractor_edited`, {
        title: params.objectCuster.name
      })
      callbacks.goBack()
    }
  } catch (e) {
    console.error({ e });
  } finally {
    if (callbacks.setIsLoading) {
      callbacks.setIsLoading(false)
    }
  }
}