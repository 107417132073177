import { useEffect, useRef, useState } from 'react';

type TResult = {
    /** ref блока с скроллом, в котором лежит react-select */
    blockRef?: React.RefObject<HTMLDivElement>
    /** Момент, когда пользователь перестал скроллить блок */
    endScrollingBlock: boolean | null;
};

type TUseDetectBlockScroll = (global?: boolean) => TResult;

/** Иногда в компонентах react-select нужно принудительно скрывать меню, пока пользователь скроллит блок */
export const useDetectBlockScroll: TUseDetectBlockScroll = (global) => {
    const blockRef = useRef<HTMLDivElement>(null);
    const [endScrollingBlock, setEndScrollingBlock] = useState<boolean | null>(null);

    let scrollTimer: any = null;

    const handleScroll = () => {
        setEndScrollingBlock(false);
        clearTimeout(scrollTimer);

        scrollTimer = setTimeout(() => {
            setEndScrollingBlock(true);
        }, 150);
    };

    useEffect(() => {
        if (global) {
            document.addEventListener('scroll', handleScroll);
        }
        if (blockRef && blockRef.current) {
            blockRef.current.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (global) {
                document.removeEventListener('scroll', handleScroll);
            }
            if (blockRef && blockRef.current) {
                blockRef.current.removeEventListener('scroll', handleScroll)
            }
        };

    }, []);

    return {
        blockRef,
        endScrollingBlock,
    };
};