import React, { FC, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import cn from 'classnames';
import { FiSearch, FiX } from 'react-icons/fi';
import Button from 'components/UI/Buttons/buttons';
import ElementsNone from 'components/ElementsNone';
import PreloaderList from 'components/Preloaders/List';
import AccessUserRight from 'components/AccessUserRight';
import { Link } from 'react-router-dom';
import { Element, scroller } from 'react-scroll';
import i18n from '../../../i18n';
import css from './styles.module.scss';
import { useDataUsers } from '../model/hooks/useDataUsers';
import Item from './Item';
import { useHighlights } from '../../../utils/useLastIdHelpers/useLastIdUsersAPI';

const UsersAPI: FC = () => {
  const {
    users,
    isLoading,
    searchRef,
    onOpenSearch,
    onChangeSearch,
    search,
    onCloseSearch,
    toggleSearch,
    filterData,
  } = useDataUsers();

  const showNotFound = !isLoading && filterData && filterData.length === 0;

  const showSearch =
    !!users && users.length > 0 && !!filterData && filterData.length === 0;

  const filteredByDeskData = filterData?.sort(
    (a, b) => Date.parse(b.date_create.date) - Date.parse(a.date_create.date),
  );

  const lastId = useHighlights();

  useEffect(() => {
    setTimeout(() => {
      scroller.scrollTo(lastId, {
        duration: 0,
        delay: 0,
        smooth: false,
        offset: -400,
      });
    }, 1000);
  }, [lastId, filteredByDeskData]);

  return (
    <div className={css.users}>
      <Helmet>
        <title>{i18n.t(`users_page.users_api`)}</title>
      </Helmet>

      <div
        className={cn(css.header, {
          isToggleSearch: toggleSearch,
        })}
      >
        <div className={cn('container')}>
          <div className={css.status}>
            <h1 className={css.title}>{i18n.t(`users_page.users_api`)}</h1>
            <div className={css.actions}>
              <div className={cn(css.search, toggleSearch ? 'isActive' : '')}>
                <div className={css.icon} onClick={onOpenSearch}>
                  <FiSearch size={24} />
                </div>
                <div className={css.input}>
                  <input
                    type="text"
                    autoComplete="off"
                    placeholder={i18n.t('users_page.search_placeholder')}
                    value={search}
                    onChange={onChangeSearch}
                    ref={searchRef}
                  />
                </div>

                <div className={css.close} onClick={onCloseSearch}>
                  <FiX size={24} />
                </div>
              </div>
              <AccessUserRight>
                {
                  (/* { isDisable } */) => (
                    <Link to="users_api/create">
                      <Button
                        title={i18n.t('users_page.create_btn')}
                        buttonAttributes={{
                          disabled: false /* isDisable */,
                          style: {
                            height: 40,
                          },
                        }}
                      />
                    </Link>
                  )
                }
              </AccessUserRight>
            </div>
          </div>
        </div>
      </div>

      {showNotFound ? (
        <ElementsNone search={showSearch} />
      ) : (
        <div className={css.content}>
          <div className={cn('container')}>
            <div className={css.table}>
              <div className={cn(css.header, 'api')}>
                <div className={css.header__item}>
                  {i18n.t('users_page.table.login')}
                </div>
                <div className={css.header__item}>
                  {i18n.t('users_page.table.groups')}
                </div>
                <div className={css.header__item}>
                  {i18n.t('project_page.forms.partner')}
                </div>
                <div className={css.header__item}>
                  {i18n.t(`users_page.token`)}
                </div>
                <div className={css.header__item}>
                  {i18n.t(`users_page.table.status`)}
                </div>
              </div>
              {isLoading ? (
                <div className={css.body}>
                  <PreloaderList type="usersApi" />
                </div>
              ) : (
                <div className={cn(css.body, 'table-body')}>
                  {filteredByDeskData &&
                    filteredByDeskData.map((userItem) => (
                      <Element name={userItem.email} key={userItem.email}>
                        <Item
                          user={userItem}
                          isLastEdited={lastId === userItem.email}
                        />
                      </Element>
                    ))}
                  {!filteredByDeskData && <ElementsNone />}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UsersAPI;
