import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import { useDispatchApp } from 'redux/rootSelectors';
import { AppState } from 'redux/rootReducer';
import { TContractStatus } from 'domains/contract/model/types';
import { contractsState, delLastId, delLastStatus, setLastId, setLastStatus } from '../../domains/contracts/model/reducer';


/** возврощает последний посещенный ид */
export function useHighlights(): string {
  const dispatch = useDispatchApp();
  const [id, setId] = useState<string>('');
  const history = useHistory();
  const lastId = useSelector<AppState, contractsState['lastId']>(
    ({ contractsReducer }) => contractsReducer.lastId,
    shallowEqual,
  );
  const lastStatus = useSelector<AppState, contractsState['lastStatus']>(
    ({ contractsReducer }) => contractsReducer.lastStatus,
    shallowEqual,
  );

  useEffect(() => {
    if (!lastId || !lastStatus) return;
    const url = new URL(window.location.href);
    url.searchParams.set('highlights', lastId);
    url.searchParams.set('status', lastStatus);
    history.push(url.search);
  }, [lastId, lastStatus]);
  useEffect(() => {
    const url = new URL(window.location.href);
    const highlights = url.searchParams.get('highlights') ?? '';
    dispatch(setLastId(highlights));
    setId(highlights);
  }, [window.location.href]);
  return id;
}
/** устанвливает последний посещенный ид из сылки */
export function useSetLastIdContract(status?: TContractStatus): void {
  const dispatch = useDispatchApp();
  const { id } = useParams<{ id: string }>();
  useEffect(() => {
    if (!id || !status) return;
    dispatch(setLastId(id));
    dispatch(setLastStatus(status));
  }, [id, status]);
}
/** удаляет последний посещеный ид */
export function useDelLastIdContract(): () => void {
  const dispatch = useDispatchApp();
  return useCallback(() => {
    dispatch(delLastId());
    dispatch(delLastStatus());
  }, []);
}

export function useGetStatusFromUrl(): TContractStatus {
  const [contractStatus, setContractStatus] = useState<TContractStatus>('');
  const location = useLocation()

  useEffect(() => {
    const paramHash = new URLSearchParams(location.search)
    const status = paramHash.get('status') ?? '';
    const contractStatuses = ["", "STOPPED", "LAUNCHED", "DELETED", "ARCHIVE"]
    if (status && contractStatuses.includes(status)) {
        setContractStatus(status as TContractStatus)
    }    
  }, [location]);
  return contractStatus;
}
