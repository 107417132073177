import React, { FC } from 'react';
import UploadImage from 'domains/uploads/UploadImage';
import { getParamVideo, getSizes } from 'domains/uploads/helpers';
import { useTranslation } from 'react-i18next';
import { IUseTypeProp } from 'domains/creative/creatives/creativeAdd/hooks/useType';
import Code from 'components/UI/Code';
import { Field, FieldProps } from 'formik';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import cn from 'classnames';
import { FiAlertCircle } from 'react-icons/fi';
import PriceInput from 'components/UI/PriceInput';
import Input from 'components/UI/Input/input';
import { checkSizes } from 'domains/creative/creatives/creativeAdd/validate';
import { animated } from 'react-spring';
import RadioGroup from 'components/UI/RadioGroup';
import { useValidateVendorLInk } from 'domains/creative/model/hooks/useValidateVendorLInk';
import { additional_settings_switch_tabs } from 'domains/creative/model/shared';
import { SelectField } from 'types/general';
import { TTypeHookProps } from 'domains/creative/types';
import UploadZip from 'domains/uploads/UploadZip';
import EridWarning from 'components/EridWarning';
import NewSelect from 'components/UI/NewSelect';
import css from '../../../styles.module.scss';

type TCompanionProps = TTypeHookProps & {
  formikVideo: IUseTypeProp['formik'];
  helper: ReturnType<typeof useValidateVendorLInk>['helper'];
  setToggle: React.Dispatch<React.SetStateAction<boolean>>;
  defaultSizeOption: (v: string) => SelectField[] | [];
  setIsDisableSaveButton: React.Dispatch<React.SetStateAction<boolean>>;
  sizeOption: SelectField[];
};

const Companion: FC<TCompanionProps> = ({
  formikVideo: { values, setFieldValue, errors, handleChange, resetForm, validateForm },
  setIsDisableSaveButton,
  helper,
  erid,
  showBlock,
  setToggle,
  defaultSizeOption,
  sizeOption,
}) => {
  const { t } = useTranslation();

  return (
    <div className={css.form}>
      <UploadImage
        keyUploadFile="video"
        preview={getParamVideo(values.data, `${values.size}`)}
        name="video_url"
        setFieldValue={setFieldValue}
        resetForm={resetForm}
        validateForm={validateForm}
        label={t('creative.video.video_input.label')}
        customLinkTitle={t('creative.video.video_input.custom_link')}
        hintText={
          <span>
            {t('creative.video.video_input.hint1')}
            <br />
            {t('creative.video.video_input.hint2')}
          </span>
        }
        hasEdit
        edit
        fieldType="video"
        maxSizeFile={100}
        isData
        file_ext={['.mp4']}
        typeSection="video"
      />
      <UploadImage
        keyUploadFile="templateImage"
        preview={getSizes(values.data.imgMask, values.data.imgFormats)}
        name="imgMask"
        setFieldValue={setFieldValue}
        resetForm={resetForm}
        validateForm={validateForm}
        label={t('creative.creasmall_set.image_word')}
        customLinkTitle={t('creative.creasmall_set.linktoimage_word')}
        hintText={
          <span>
            {t('creative.creasmall_set.html_format1')}
            <br />
            {t('creative.creasmall_set.html_format2')}
          </span>
        }
        hasEdit
        edit
        isData
        minResolution={100}
        file_ext={['.png', '.jpeg', '.jpg']}
        typeSection="templateImage"
        addTabLabel={t(`creative.create_creative.Add_archive`)}
        addTabContent={
          <UploadZip
            isData
            name="zip"
            hintText={t('creative.create_creative.Supported_format')}
            label={t('creative.create_creative.File')}
            sizes={[
              '160x600',
              '240x400',
              '300x250',
              '300x600',
              '320x480',
              '320x50',
              '336x280',
              '480x320',
              '580x400',
              '728x90',
              '970x250',
              '970x90',
              t('creative.create_creative.Set_sizes'),
            ]}
            setFieldValue={setFieldValue}
            validateForm={validateForm}
            inputAttributes={{
              name: 'zip',
            }}
            type="zip_banner"
          />
        }
      />
      <Field name="data.html_code">
        {({ field, form, meta }: FieldProps) => (
          <Code
            label={t('creative.create_creative.HTML_Code')}
            error={errors.data && !!errors.data.html_code}
            maxCount={4096}
            valueCount={values.data.html_code?.length}
            errorText={errors.data && errors.data.html_code}
            field={field}
            form={form}
            meta={meta}
          />
        )}
      </Field>
      <Tabs>
        <TabList>
          <Tab
            className={cn('react-tabs__tab', {
              hasError:
                !!errors?.data?.duration ||
                !!errors?.data?.skip_duration ||
                !!errors?.data?.video_bitrate,
            })}
          >
            <span>{t('creative.video.tabs.tab1')}</span>
            {(!!errors?.data?.duration ||
              !!errors?.data?.skip_duration ||
              !!errors?.data?.video_bitrate) && (
              <FiAlertCircle size={16} className="alert-icon" />
            )}
          </Tab>
          <Tab
            className={cn('react-tabs__tab', {
              hasError: errors?.data?.audit,
            })}
          >
            <span>
              <span>{t('creative.video.tabs.tab2')}</span>
            </span>
            {!!errors?.data?.audit && (
              <FiAlertCircle size={16} className="alert-icon" />
            )}
          </Tab>
        </TabList>
        <TabPanel>
          <div className={css.form}>
            <div className={css.flex_row}>
              <div className={css.flex_row_col}>
                <PriceInput
                  error={!!errors.data?.video_bitrate}
                  errortext={errors.data?.video_bitrate}
                  label={t('creative.video.fields.video_bitrate')}
                  decimalScale={0}
                  onValueChange={({ floatValue }) => {
                    setFieldValue('data.video_bitrate', floatValue);
                  }}
                  value={values.data.video_bitrate}
                  hasZero={false}
                  disabled
                />
              </div>
              <div className={css.flex_row_col}>
                <PriceInput
                  error={!!errors.data?.duration}
                  errortext={errors.data?.duration}
                  label={t('creative.video.fields.video_duration')}
                  decimalScale={3}
                  onValueChange={({ floatValue }) => {
                    setFieldValue('data.duration', floatValue);
                  }}
                  value={values.data.duration}
                  hasZero={false}
                  disabled
                />
              </div>
              <div className={css.flex_row_col}>
                <PriceInput
                  error={!!errors.data?.skip_duration}
                  errortext={errors.data?.skip_duration}
                  label={t('creative.video.fields.video_duration_pass')}
                  decimalScale={0}
                  onValueChange={({ floatValue }) => {
                    setFieldValue('data.skip_duration', floatValue);
                  }}
                  value={values.data.skip_duration}
                  hasZero={false}
                />
              </div>
            </div>
            <Input
              label={t('creative.video.fields.video_description')}
              isTextarea
              showValueCount
              inputAttributes={{
                name: 'data.description',
                placeholder: '',
                maxLength: 128,
                style: { height: 128 },
                value: values.data.description,
                onChange: handleChange,
              }}
            />
            <Field name="size">
              {({ form, field }: FieldProps) => (
                <NewSelect
                  label={t('creative.create_creative.Size_px')}
                  form={form}
                  field={field}
                  selectAttributes={{
                    name: 'size',
                    placeholder: t('creative.create_creative.Please_select'),
                    options: sizeOption,
                    value: defaultSizeOption(`${values.size}`),
                    onChange: () => {
                      setToggle(checkSizes(values.size));
                      setFieldValue('size', values.size);
                    },
                  }}
                  viewStyle="Vertical"
                />
              )}
            </Field>
            <animated.div style={showBlock} className={css.sizes}>
              <PriceInput
                hasZero
                classname={css.rough_col}
                decimalScale={0}
                label={`${t('creative.create_creative.Width')}, PX`}
                placeholder={t('creative.create_creative.Width')}
                name="width"
                error={errors.width}
                errortext={errors.width}
                value={+values.width}
                onValueChange={({ floatValue }) => {
                  if (!floatValue) {
                    setFieldValue('width', null);
                    return;
                  }
                  setFieldValue('width', floatValue);
                }}
              />
              <PriceInput
                hasZero
                classname={css.rough_col}
                decimalScale={0}
                label={`${t('creative.create_creative.Height')}, PX`}
                placeholder={t('creative.create_creative.Height')}
                name="height"
                error={errors.height}
                errortext={errors.height}
                value={+values.height}
                onValueChange={({ floatValue }) => {
                  if (!floatValue) {
                    setFieldValue('height', null);
                    return;
                  }
                  setFieldValue('height', floatValue);
                }}
              />
            </animated.div>
            {erid && (
              <>
                <Input
                  label={erid.title}
                  showValueCount
                  error={!!errors.erid}
                  errortext={errors.erid}
                  inputAttributes={{
                    name: 'erid',
                    maxLength: 80,
                    value: values.erid,
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                      setFieldValue('erid', e.target.value.trim()),
                  }}
                />
                {values.erid && (
                  <EridWarning
                    withAccept
                    isChangeErid
                    setIsDisableSaveButton={setIsDisableSaveButton}
                  />
                )}
              </>
            )}
          </div>
        </TabPanel>
        <TabPanel>
          <div className={css.form}>
            <RadioGroup
              name="additional_settings_switch"
              label=""
              className={css.switch}
              options={[
                {
                  title: t('creative.video.switch.all'),
                  value: 'ALL',
                },
                {
                  title: t('creative.video.switch.quartiles'),
                  value: 'QUARTILES',
                },
                {
                  title: t('creative.video.switch.show'),
                  value: 'SHOW',
                },
              ]}
              setFieldValue={setFieldValue}
              checked={values.additional_settings_switch}
            />
            {values.additional_settings_switch !== 'ALL' && (
              <>
                {Object.keys(values.data.audit).map(
                  (value) =>
                    additional_settings_switch_tabs[
                      values.additional_settings_switch
                    ].includes(value) && (
                      <Input
                        key={`data.audit.${value}`}
                        error={
                          errors.data?.audit && !!errors.data?.audit[value]
                        }
                        errortext={
                          errors.data?.audit && errors.data?.audit[value]
                        }
                        helper={helper[value]}
                        label={t(`creative.video.fields.video_${value}`)}
                        inputAttributes={{
                          name: `data.audit.${value}`,
                          placeholder: '',
                          style: { height: 48 },
                          value: values.data.audit[value],
                          onChange: handleChange,
                        }}
                      />
                    ),
                )}
              </>
            )}
            {values.additional_settings_switch === 'ALL' && (
              <>
                {Object.keys(values.data.audit).map((value) => (
                  <Input
                    key={`data.audit.${value}`}
                    error={errors.data?.audit && !!errors.data?.audit[value]}
                    errortext={errors.data?.audit && errors.data?.audit[value]}
                    helper={helper[value]}
                    label={t(`creative.video.fields.video_${value}`)}
                    inputAttributes={{
                      name: `data.audit.${value}`,
                      placeholder: '',
                      style: { height: 48 },
                      value: values.data.audit[value],
                      onChange: handleChange,
                    }}
                  />
                ))}
              </>
            )}
          </div>
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default Companion;
