import { fetchTagsGetCompany, fetchListSRMethods, setSRMethodRequest } from "domains/campaign/model/actions";
import { useListSRMethodsInfo, useSelectorCampaignCompanyTagsInfo } from "domains/campaign/model/selectors";
import { useState, useEffect, useCallback, useMemo } from "react";
import { useParams } from "react-router";
import { OptionsType, OptionTypeBase } from "react-select";
import { useDispatchApp } from "redux/rootSelectors";
import { TID } from "types/general";

type TFUseSRMethod = (p: any) => {
    onSaveSRMethod: () => void;
    SRMethod: string;
    listSRMethods: ReturnType<typeof useListSRMethodsInfo>['data'];
    onChangeSRMethod: (v: string | number) => void;
    setDefSRMethod: () => void;
    optTag: OptionsType<OptionTypeBase>;
    onChangeText: (v: string) => void;
    aggregationTag: OptionTypeBase | undefined;
    onChangeAggregation: (v: OptionTypeBase) => void;
  };

export const useSRMethod: TFUseSRMethod = (data) => {
    const dispatch = useDispatchApp();
  
    const { id } = useParams<TID>();
  
    const { data: listSRMethods } = useListSRMethodsInfo();
  
    const [SRMethod, setSRMethod] = useState<string>('');
    const [defSRMethod, setDefSR] = useState<string>('');
    const [title, setTitle] = useState<string>('');
    const [optTag, setOptTag] = useState<ReturnType<TFUseSRMethod>['optTag']>([]);
    const [filterTag, setfilterTag] = useState<string>('');
    const [aggregationTag, setAggregationTag] = useState<
      OptionTypeBase | undefined
    >(undefined);
  
    useEffect(() => {
      if (data === null) {
        return;
      }
      setTitle(data.title);
      setDefSR(data.method_recalculating_statistics);

      if (!data.aggregation_tag) return;

      const aggregation_tag = data?.aggregation_tag;
      if (typeof aggregation_tag === 'string') {
        setAggregationTag({ label: aggregation_tag, value: aggregation_tag });
      }
  
      dispatch(fetchTagsGetCompany(data.xxhash));
    }, [data]);
  
    const { data: companyTags } = useSelectorCampaignCompanyTagsInfo();

    useEffect(() => {
      if (companyTags === null) return;
      if (!filterTag) return;
      if (filterTag.length < 3) {
        // eslint-disable-next-line consistent-return
        return setOptTag([]);
      }
      const filterData = companyTags.filter((item) =>
        item.toUpperCase().includes(filterTag.toUpperCase()),
      );
      setOptTag(filterData.map((i) => ({ label: i, value: i })));
    }, [companyTags, filterTag]);
  
    const onChangeText: ReturnType<TFUseSRMethod>['onChangeText'] = useCallback(
      (v) => {
        setfilterTag(v);
      },
      [setfilterTag],
    );

    const onChangeAggregation: ReturnType<TFUseSRMethod>['onChangeAggregation'] =
      useCallback(
        (v) => {
          setAggregationTag(v ?? { label: '', value: '' });
          setfilterTag(v?.value ?? '');
        },
        [setAggregationTag],
      );
  
    useEffect(() => {
      if (typeof id !== 'string') {
        return;
      }
  
      dispatch(fetchListSRMethods());
    }, [id]);
    useEffect(() => {
      if (typeof defSRMethod !== 'string') return;
      setSRMethod(defSRMethod);
    }, [defSRMethod]);
  
    const onChangeSRMethod: ReturnType<TFUseSRMethod>['onChangeSRMethod'] =
      useCallback(
        (v) => {
          if (typeof v !== 'string') {
            return null;
          }
          return setSRMethod(() => v);
        },
        [setSRMethod, SRMethod],
      );
    
    const setDefSRMethod: ReturnType<TFUseSRMethod>['setDefSRMethod'] =
      useCallback(() => {
        setSRMethod(defSRMethod);
      }, [setSRMethod, defSRMethod]);
  
    const onSaveSRMethod = useMemo(
      () => () => {
        if (
          defSRMethod !== SRMethod &&
          typeof SRMethod !== 'undefined' &&
          typeof id === 'string'
        ) {
          dispatch(setSRMethodRequest({ SRMethod, id, title }));
        }
      },
      [defSRMethod, SRMethod, title],
    );
  
    return {
      onSaveSRMethod,
      SRMethod,
      listSRMethods,
      onChangeSRMethod,
      setDefSRMethod,
      optTag,
      onChangeText,
      aggregationTag,
      onChangeAggregation,
    };
  };