import LabelField from 'components/UI/LabelField';
import { CampaignGeneralComponentProps } from 'domains/campaign/types';
import { Field, FieldProps } from 'formik';
import React, { memo } from 'react';
import { SelectField } from 'types/general';
import { selectDefault } from 'utils/selectDefault';
import NewSelect from 'components/UI/NewSelect';
import { useDetectBlockScroll } from 'utils/useDetectBlockScroll';
import i18n from '../../../../../../../i18n';

type TOfferFieldProps = Pick<
  CampaignGeneralComponentProps,
  'formik' | 'autoSubmit'
> & {
  offersOptions: SelectField[] | undefined;
};

const OfferField: React.FC<TOfferFieldProps> = ({
  formik,
  offersOptions,
  autoSubmit,
}) => {
  const { endScrollingBlock } = useDetectBlockScroll(true);
  return (
    <LabelField label={i18n.t(`offers_page.offer`)}>
      <Field name="offer_id">
        {({ field, form }: FieldProps) => (
          <NewSelect
            autoSubmit={autoSubmit}
            form={form}
            field={field}
            noOptionsMessage={() => i18n.t(`offers_page.no_availibale_offers`)}
            selectAttributes={{
              isDisabled: false,
              options: offersOptions,
              placeholder: '',
              value:
                offersOptions &&
                selectDefault(String(formik.values.offer_id), offersOptions),
              closeMenuOnScroll:
                !endScrollingBlock && endScrollingBlock !== null
                  ? () => true
                  : () => false,
            }}
            heightCustom={32}
            viewStyle="Horizontal"
          />
        )}
      </Field>
    </LabelField>
  );
};

export default memo(OfferField);
