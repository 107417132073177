import React, { ChangeEvent, FC, useEffect } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { FormikHelpers } from 'formik';
import { setResponseResultDefault } from 'domains/creative/reduser';
import { useDispatchApp } from 'redux/rootSelectors';
import { FiExternalLink } from 'react-icons/fi';
import Input from '../../../../components/UI/Input/input';
import vast_icon from './icons/vast_icon.svg';
import input from './styles.module.scss';
import { sizeSplit } from '../../../../utils/sizeUtils';
import i18n from "../../../../i18n";

type Props = {
  /** Ошибка валидации */
  isError?: boolean;
  /** Текст ошибки */
  errorText?: string;
  /** имя поля */
  name: string;
  /** изменением состояния в форме */
  setFieldValue: FormikHelpers<string>['setFieldValue'];
  /** Очистка формы */
  resetForm?: FormikHelpers<any | undefined>['resetForm'];
  /** Режим редактирования */
  edit?: boolean;
  /** Ссылка */
  url: string;
  /** Размер баннера */
  size: string;
  /** Тип сущьности */
  type?: 'vast';
};

const XMLUpload: FC<Props> = ({
  url,
  edit = false,
  size,
  type,
  name,
  setFieldValue,
  resetForm,
  isError,
  errorText,
}) => {
  const { t } = useTranslation();

  const dispatch = useDispatchApp();
  useEffect(
    () => () => {
      dispatch(setResponseResultDefault());
    },
    [],
  );

  const { width, height } = sizeSplit(size);

  return (
    <div
      className={cn(input['form-group'], {
        isDisabled: edit,
      })}
    >
      {url && size ? (
        <>
          <div className={input.preview}>
            <div className={input.image}>
              {type === 'vast' && <img src={vast_icon} alt={url} />}
            </div>

            <div className={input.description}>
              {!edit && (
                <div className={input.size}>{`${width}px x ${height}px`}</div>
              )}
              <div className={input.url}>
                <a
                  href={`${url}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={input.filename}
                >
                  <span>{url}</span>
                  <FiExternalLink size={16} color="#025DFF" />
                </a>
              </div>
            </div>
          </div>
          {edit && (
            <Input
              errortext={errorText}
              error={isError}
              label={t('creative.vast.fields.video_url')}
              inputAttributes={{
                name,
                value: url,
                onChange: (event: ChangeEvent<HTMLInputElement>) => {
                  dispatch(setResponseResultDefault());
                  if (resetForm) {
                    resetForm();
                  }
                  setFieldValue(name, event.currentTarget.value);
                  setFieldValue('size', null);
                  setFieldValue('data.duration', 0);
                  setFieldValue('data.skip_duration', 0);
                },
              }}
            />
          )}
        </>
      ) : (
        <Input
          errortext={errorText}
          error={isError}
          notField={edit}
          label={i18n.t('creative.vast.fields.video_url')}
          inputAttributes={{
            value: url,
            name,
            onChange: (event: ChangeEvent<HTMLInputElement>) => {
              dispatch(setResponseResultDefault());
              setFieldValue(name, event.currentTarget.value);
            },
          }}
        />
      )}
    </div>
  );
};
export default XMLUpload;
