import { useCallback } from 'react';
import { fetchPersonalInfo } from './actions';
import { useDispatchApp } from '../../redux/rootSelectors';
import { useUserInfo } from './model/selectors';

type TFUseIsGroup = () => {
  isOwner: boolean;
  isEperimentalOwner: boolean;
  isWithTargets: boolean;
  isShowHiddenPartner: boolean;
  isWithCreatives: boolean;
  isTypeAgency: boolean;
  isSelfUser: boolean;
  isRawStat: boolean;
  isLocked: boolean;
  isTest: boolean;
};

/**  возврощает функцию вызывающую запрос данных по пользователю
 * @isHardFetch флаг стоит ли делать запрос если данные уже есть
 * */
export function useGetPersonalInfo(isHardFetch = false): () => void {
  const dispatch = useDispatchApp();
  const { data } = useUserInfo();
  return useCallback(() => {
    if (isHardFetch) {
      return dispatch(fetchPersonalInfo());
    }

    if (data) {
      return null;
    }
    return dispatch(fetchPersonalInfo());
  }, [data]);
}

/** Функция возвращает является ли пользователь членом группы "owner" */
export const useIsGroup: TFUseIsGroup = () => {
  const { data, isLoading } = useUserInfo();

  const isOwner =
    !isLoading && data && data.group.some((role) => role === 'owner');

  const isEperimentalOwner = !isLoading && data && data.group.some((role) => role === 'experimental_owner');

  const isWithTargets =
    !isLoading &&
    data &&
    data.group.some((role) => role === 'tabs_with_targets');

  const isWithCreatives =
    !isLoading &&
    data &&
    data.group.some((role) => role === 'tabs_with_creatives');

  const isShowHiddenPartner =
    !isLoading &&
    data &&
    data.group.some((role) => role === 'view_hidden_partner');

  const isTypeAgency = !isLoading && data && data.partner.type === 'AGENCY';

  const isSelfUser =
    !isLoading && data && data.group.some((role) => role === 'self_user');
  const isRawStat =
    !isLoading && data && data.group.some((role) => role === 'raw_stat');
  const isTest =
    !isLoading && data && data.group.some((role) => role === 'test');

  const isLocked = !(isOwner || isSelfUser);

  return {
    isOwner: isOwner ?? false,
    isEperimentalOwner: isEperimentalOwner ?? false,
    isWithTargets: isWithTargets ?? false,
    isShowHiddenPartner: isShowHiddenPartner ?? false,
    isWithCreatives: isWithCreatives ?? false,
    isTypeAgency: isTypeAgency ?? false,
    isSelfUser: isSelfUser ?? false,
    isRawStat: isRawStat ?? false,
    isLocked: isLocked ?? false,
    isTest: isTest ?? false,
  };
};
