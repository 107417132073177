import Card from 'components/UI/Card';
import React, { FC } from 'react';
import Loader from 'components/UI/Loader';
import { useTranslation } from 'react-i18next';
import css from './style.module.scss';
import { ReactComponent as SaveSvg } from '../../assets/saveLoading.svg';

interface ISaveProcessingProps {
  /** Обратный отсчет для отмены изменений */
  counter?: number;
  /** Функция отмены процесса сохранения */
  onClose?: () => void;
}

/** Показывает процесс сохранения */
const SaveProcessing: FC<ISaveProcessingProps> = ({ counter, onClose }) => {
  const { t } = useTranslation();

  return (
    <Card className={css.card}>
      <SaveSvg className={css.img} />
      <div className={css.processing}>
        <Loader />
        <span>{t(`offers_page.saving_chanes`)}</span>
      </div>
      {!!counter && (
        <div className={css.cancel} onClick={onClose}>
          {`${t(`creative.discard_changes`)} (${counter}s)`}
        </div>
      )}
    </Card>
  );
};

export default SaveProcessing;
