import React, { FC } from 'react';
import { FiDownload } from 'react-icons/fi';
import cn from 'classnames';
import TooltipPoratal from 'components/UI/TooltipPoratal';
import css from './style.module.scss';
import i18n from '../../../../i18n';

type DownloadButtonProps = {
  onClick: () => void;
  disabled: boolean;
  classname?: string;
};

const DownloadButton: FC<DownloadButtonProps> = ({
  onClick,
  disabled,
  classname,
}) => (
  <div
    className={cn(css.download, classname, {
      disabled,
    })}
  >
    <TooltipPoratal
      hint={i18n.t('report.download')}
      hintWidth={150}
      isDisabled={disabled}
    >
      <FiDownload size={24} onClick={onClick} className={css.download__btn} />
    </TooltipPoratal>
  </div>
);

export default DownloadButton;
