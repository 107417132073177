import React, { FC } from 'react';
import { Form, FormikProvider } from 'formik';
import { useTranslation } from 'react-i18next';
import Input from 'components/UI/Input/input';
import EditCard from 'components/UI/EditCard';
import UploadImage from 'domains/uploads/UploadImage';
import { getSizes } from 'domains/uploads/helpers';
import css from './styles.module.scss';
import { useProjectBranding } from './hook';

const Branding: FC = () => {
  const { t } = useTranslation();
  const {
    formikContext,
    edit,
    setEdit,
    handleFormikReset,
    handleFormikSubmit,
    errors,
    touched,
    values,
    handleChange,
    handleBlur,
    setFieldValue,
    validateForm,
    resetForm
  } = useProjectBranding();

  const uploads = [
    'logo_medium',
    'logo_small',
    'background',
    'logotype',
    'logo_email',
    'background_email',
  ] as const;

  return (
    <FormikProvider value={formikContext}>
      <Form>
        <EditCard
          className={css.card}
          edited={edit}
          hasErrors={Object.keys(errors).length > 0}
          noHover
          onResetForm={handleFormikReset}
          onSave={handleFormikSubmit}
          setEdit={setEdit}
        >
          <h3>{t('project_page.forms.branding_title')}</h3>

          <div className={css.card__form}>
            <Input
              notField={edit}
              label={t('project_page.forms.slogan')}
              error={!!touched.slogan && !!errors.slogan}
              errortext={errors.slogan}
              showValueCount
              inputAttributes={{
                name: 'slogan',
                placeholder: '',
                value: values.slogan,
                onChange: handleChange,
                onBlur: handleBlur,
                maxLength: 120,
              }}
            />
            {uploads.map((key) => (
              <UploadImage
                keyUploadFile="project"
                key={key}
                preview={getSizes(values[key], ['200x300'])}
                name={key}
                setFieldValue={setFieldValue}
                resetForm={resetForm}
                validateForm={validateForm}
                edit={edit}
                hasEdit
                label={t(`project_page.forms.${key}`)}
                customLinkTitle={t('project_page.forms.custom_link_title')}
                hintText={<span>{t('project_page.forms.upload_hint')}</span>}
                file_ext={['.png', '.jpeg', '.jpg', '.svg']}
                typeSection="project"
                minResolution={25}
              />
            ))}
          </div>
        </EditCard>
      </Form>
    </FormikProvider>
  );
};

export default Branding;
