import React, { CSSProperties, FC, useEffect, useState } from 'react';
import cn from 'classnames';
import { Field, FieldProps, Form, FormikProvider } from 'formik';
import { useTranslation } from 'react-i18next';
import equal from 'deep-equal';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { useGetFieldConfigValue } from 'domains/creative/creatives/hooks/useGetFieldConfigValue';
import EditCard from 'components/UI/EditCard';
import Input from 'components/UI/Input/input';
import RadioGroup from 'components/UI/RadioGroup';
import { isErrorFields } from 'utils/isErrorFields';
import { FiAlertCircle } from 'react-icons/fi';
import { animated, useSpring } from 'react-spring';
import { useCheckGridEridInfo } from 'domains/creative/creatives/hooks/useCheckGridEridInfo';
import EridWarning from 'components/EridWarning';
import NewSelect from 'components/UI/NewSelect';
import css from '../styles.module.scss';
import { useFormVideo } from './hooks';
import PriceInput from '../../../../../../../components/UI/PriceInput';
import { CreativeDetail, CreativeDetailVideoVast } from '../../../../../types';
import XMLUpload from '../../../../XMLUpload';
import { additional_settings_switch_tabs } from '../../../../../model/shared';

interface Props {
  xxhash: CreativeDetail['xxhash'];
  data: CreativeDetailVideoVast['data'];
  size: CreativeDetail['size'];
  config_values: CreativeDetail['config_values'];
}

const FormType: FC<Props> = ({ data, xxhash, size, config_values }) => {
  const [toggle, setToggle] = useState<boolean>(false);
  const erid = useGetFieldConfigValue('erid', 'VIDEO_VAST');

  const showBlock = useSpring<CSSProperties>(
    !toggle
      ? {
          height: 'auto',
          visibility: 'visible',
          opacity: 1,
          transform: 'translateY(0)',
          config: { duration: 120 },
          delay: 0,
          marginBottom: '24px',
        }
      : {
          opacity: 0,
          height: 0,
          visibility: 'hidden',
          transform: 'translateY(-50px)',
          delay: 30,
          marginBottom: '0px',
        },
  );

  const [edit, setEdit] = useState(false);
  const { t } = useTranslation();
  const {
    formik,
    handlerFormikReset,
    handlerFormikSubmit,
    error,
    isLoading,
    sizeOption,
    defaultSizeOption,
    checkSizes,
    helper,
    helperError,
  } = useFormVideo({
    xxhash,
    size,
    erid: (erid && config_values[erid.id]) || '',
    data,
    setEdit,
    edit,
  });

  const { isDisableSaveButton, setIsDisableSaveButton } = useCheckGridEridInfo({
    eridData: erid,
    eridVal: formik.values.erid,
  });

  useEffect(() => {
    if (formik.values.size) {
      if (defaultSizeOption(formik.values.size)) {
        if (defaultSizeOption(formik.values.size)[0].value === '1x1') {
          setToggle(false);
        } else {
          setToggle(true);
        }
      }
    } else {
      setToggle(true);
    }
  }, [formik.values.size]);

  return (
    <FormikProvider value={formik}>
      <Form>
        <EditCard
          className={css.card}
          edited={edit}
          noHover
          noShadow
          hasErrors={
            Object.keys(formik.errors).length > 0 ||
            error.isError ||
            isLoading ||
            isDisableSaveButton
          }
          onResetForm={handlerFormikReset}
          onSave={handlerFormikSubmit}
          notEqual={!equal(formik.values, data)}
          setEdit={setEdit}
        >
          <div className={css.form}>
            <Tabs>
              <TabList>
                <Tab
                  className={cn('react-tabs__tab', {
                    hasError: !!formik.errors?.data?.vast_url,
                  })}
                >
                  <span>{t('creative.vast.tabs.tab0')}</span>
                  {!!formik.errors?.data?.vast_url && (
                    <FiAlertCircle size={16} className="alert-icon" />
                  )}
                </Tab>
              </TabList>
              <TabPanel>
                <XMLUpload
                  name="data.vast_url"
                  isError={
                    !!formik.errors.data?.vast_url ||
                    isErrorFields(error, 'vast_url')
                  }
                  errorText={
                    formik.errors.data?.vast_url ||
                    (isErrorFields(error, 'vast_url')
                      ? t('errors.err40_link')
                      : '')
                  }
                  size={formik.values.size}
                  edit={edit}
                  setFieldValue={formik.setFieldValue}
                  resetForm={formik.resetForm}
                  url={formik.values.data.vast_url}
                  type="vast"
                />
              </TabPanel>
            </Tabs>

            <Tabs className={css['video-tabs']}>
              <TabList>
                <Tab
                  className={cn('react-tabs__tab', {
                    hasError:
                      !!formik.errors?.data?.duration ||
                      !!formik.errors?.data?.skip_duration,
                  })}
                >
                  <span>{t('creative.video.tabs.tab1')}</span>
                  {(!!formik.errors?.data?.duration ||
                    !!formik.errors?.data?.skip_duration) && (
                    <FiAlertCircle size={16} className="alert-icon" />
                  )}
                </Tab>
                <Tab
                  className={cn('react-tabs__tab', {
                    hasError: !!formik.errors.data?.audit || helperError,
                  })}
                >
                  <span>
                    <span>{t('creative.video.tabs.tab2')}</span>
                  </span>
                  {(!!formik.errors?.data?.audit || helperError) && (
                    <FiAlertCircle size={16} className="alert-icon" />
                  )}
                </Tab>
              </TabList>
              <TabPanel>
                <div className={css.form}>
                  <div className={css.rough}>
                    <div className={css.rough_col}>
                      <PriceInput
                        error={!!formik.errors.data?.duration}
                        errortext={formik.errors.data?.duration}
                        notField={!edit}
                        label={t('creative.vast.fields.video_duration')}
                        decimalScale={0}
                        onValueChange={({ floatValue }) => {
                          formik.setFieldValue('data.duration', floatValue);
                        }}
                        value={formik.values.data.duration}
                        hasZero={false}
                        disabled
                      />
                    </div>
                    <div className={css.rough_col}>
                      <PriceInput
                        error={!!formik.errors.data?.skip_duration}
                        errortext={formik.errors.data?.skip_duration}
                        notField={!edit}
                        label={t('creative.vast.fields.video_duration_pass')}
                        decimalScale={0}
                        onValueChange={({ floatValue }) => {
                          formik.setFieldValue(
                            'data.skip_duration',
                            floatValue,
                          );
                        }}
                        value={formik.values.data.skip_duration}
                        hasZero={false}
                      />
                    </div>
                  </div>
                  <Field name="size">
                    {({ form, field }: FieldProps) => (
                      <NewSelect
                        label={t('creative.create_creative.Size_px')}
                        form={form}
                        field={field}
                        viewingOnly={!edit}
                        selectAttributes={{
                          placeholder: t(
                            'creative.create_creative.Please_select',
                          ),
                          options: sizeOption,
                          value: defaultSizeOption(formik.values.size),
                          onChange: () => {
                            setToggle(() => checkSizes(formik.values.size));
                          },
                        }}
                        viewStyle="Vertical"
                      />
                    )}
                  </Field>

                  {edit && (
                    <>
                      <animated.div style={showBlock} className={css.sizes}>
                        <PriceInput
                          classname={css.sizes_col}
                          hasZero
                          decimalScale={0}
                          label={`${t('creative.create_creative.Width')}, PX`}
                          placeholder={t('creative.create_creative.Width')}
                          name="width"
                          error={formik.errors.width}
                          errortext={formik.errors.width}
                          value={formik.values.width || 1}
                          onValueChange={({ floatValue }) => {
                            if (!floatValue) {
                              formik.setFieldValue('width', 1);
                              return;
                            }
                            formik.setFieldValue('width', floatValue);
                          }}
                          notField={!edit}
                        />
                        <PriceInput
                          classname={css.sizes_col}
                          hasZero
                          decimalScale={0}
                          label={`${t('creative.create_creative.Height')}, PX`}
                          placeholder={t('creative.create_creative.Height')}
                          name="height"
                          error={formik.errors.height}
                          errortext={formik.errors.height}
                          value={formik.values.height || 1}
                          onValueChange={({ floatValue }) => {
                            if (!floatValue) {
                              formik.setFieldValue('height', 1);
                              return;
                            }
                            formik.setFieldValue('height', floatValue);
                          }}
                          notField={!edit}
                        />
                      </animated.div>
                    </>
                  )}

                  {erid && (
                    <>
                      <Input
                        label={erid.title}
                        notField={edit}
                        showValueCount
                        error={!!formik.errors.erid}
                        errortext={formik.errors.erid}
                        inputAttributes={{
                          name: 'erid',
                          disabled: !edit,
                          maxLength: 80,
                          value: formik.values.erid,
                          onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                            formik.setFieldValue('erid', e.target.value.trim()),
                        }}
                      />
                      {formik.values.erid && (
                        <EridWarning
                          withAccept={edit}
                          setIsDisableSaveButton={setIsDisableSaveButton}
                          isChangeErid={
                            config_values[erid.id] !== formik.values.erid
                          }
                        />
                      )}
                    </>
                  )}
                </div>
              </TabPanel>
              <TabPanel>
                <div className={css.form}>
                  <RadioGroup
                    name="additional_settings_switch"
                    label=""
                    className={css.switch}
                    options={[
                      {
                        title: t('creative.video.switch.all'),
                        value: 'ALL',
                      },
                      {
                        title: t('creative.video.switch.quartiles'),
                        value: 'QUARTILES',
                      },
                      {
                        title: t('creative.video.switch.show'),
                        value: 'SHOW',
                      },
                    ]}
                    setFieldValue={formik.setFieldValue}
                    checked={formik.values.additional_settings_switch}
                  />
                  {formik.values.additional_settings_switch !== 'ALL' && (
                    <>
                      {Object.keys(formik.values.data.audit).map(
                        (value) =>
                          additional_settings_switch_tabs[
                            formik.values.additional_settings_switch
                          ].includes(value) && (
                            <Input
                              notField={edit}
                              key={`data.audit.${value}`}
                              error={
                                formik.errors.data?.audit &&
                                !!formik.errors.data?.audit[value]
                              }
                              errortext={
                                formik.errors.data?.audit &&
                                formik.errors.data?.audit[value]
                              }
                              helper={helper[value]}
                              label={t(`creative.video.fields.video_${value}`)}
                              inputAttributes={{
                                name: `data.audit.${value}`,
                                placeholder: '',
                                style: { height: 48 },
                                value: formik.values.data.audit[value],
                                onChange: formik.handleChange,
                              }}
                            />
                          ),
                      )}
                    </>
                  )}
                  {formik.values.additional_settings_switch === 'ALL' && (
                    <>
                      {Object.keys(formik.values.data.audit).map((value) => (
                        <Input
                          notField={edit}
                          key={`data.audit.${value}`}
                          error={
                            formik.errors.data?.audit &&
                            !!formik.errors.data?.audit[value]
                          }
                          helper={helper[value]}
                          errortext={
                            formik.errors.data?.audit &&
                            formik.errors.data?.audit[value]
                          }
                          label={t(`creative.video.fields.video_${value}`)}
                          inputAttributes={{
                            name: `data.audit.${value}`,
                            placeholder: '',
                            style: { height: 48 },
                            value: formik.values.data.audit[value],
                            onChange: formik.handleChange,
                          }}
                        />
                      ))}
                    </>
                  )}
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </EditCard>
      </Form>
    </FormikProvider>
  );
};

export default FormType;
