/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-irregular-whitespace */
import Card from 'components/UI/Card';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ToggleSwitch from 'components/UI/ToggleSwitch';
import cn from 'classnames';
import { Field, FieldProps, FormikProps } from 'formik';
import Select from 'components/UI/Select';
import {
  RGetPersonal,
  TFormikSettigns,
  TReportItem,
} from 'domains/campaign/types';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import LabelField from 'components/UI/LabelField';
import { TGetAuditDictionary } from 'domains/agency/types';
import CollapseCard from 'components/UI/CollapseCard';
import NewSelect from 'components/UI/NewSelect';
import css from '../styles.module.scss';
import i18n from '../../../../i18n';

interface IReportsProp {
  formik: FormikProps<TFormikSettigns>;
  convertReports: ({ is_enabled }: TReportItem) => string;
  audiences: TGetAuditDictionary[];
  setCurrentAudit: React.Dispatch<React.SetStateAction<string>>;
  campaign: RGetPersonal | null;
}

const Reports: FC<IReportsProp> = ({
  formik,
  convertReports,
  audiences,
  setCurrentAudit,
  campaign,
}) => {
  const { t } = useTranslation();

  useEffect(() => () => localStorage.removeItem('audience'), []);

  const editAudiences = (audArr: TGetAuditDictionary[]) => {
    const editedArr: { value: string; label: string }[] = [];
    editedArr.push({
      label: t(`project_page.forms.not_selected`),
      value: t(`project_page.forms.not_selected`),
    });
    Object.values(audArr).forEach((item) => {
      const obj = {
        value: item.title,
        label: item.title,
      };
      editedArr.push(obj);
    });
    return editedArr;
  };

  useEffect(() => {
    editAudiences(audiences);
  }, [i18n.language]);

  const [tab, setTab] = useState<boolean>(false);

  return (
    <Card noHover className={css.card}>
      <CollapseCard
        closedStart
        updateHeight={[tab]}
        title={
          <h3 className={css.title}>
            {t('campaigns_page.campaign_settings.additional.reports')}{' '}
          </h3>
        }
      >
        <div className={css.reports}>
          <Tabs>
            <TabList>
              <Tab onClick={() => setTab(!tab)}>
                {t(`agencies_page.agency_title`)}
              </Tab>
              <Tab onClick={() => setTab(!tab)}>
                {t(`campaigns_page.campaigns`)}
              </Tab>
            </TabList>
            <TabPanel>
              <LabelField
                label={t(`common.targetings.au`)}
                tooltip={{
                  text: (
                    <div>
                      {`${t(`agencies_page.report.hint`)} `}
                      {t(`agencies_page.report.hint_link`)}
                    </div>
                  ),
                }}
              >
                <Field name="partner_xxhash">
                  {({ field, form }: FieldProps) => (
                    <NewSelect
                      form={form}
                      field={field}
                      selectAttributes={{
                        options: editAudiences(audiences),
                        placeholder: t('targetings.search'),
                        defaultValue:
                          (campaign?.audit_dictionary && {
                            label: campaign?.audit_dictionary.title,
                            value: campaign?.audit_dictionary.title,
                          }) ||
                          editAudiences(audiences).find(
                            (item) =>
                              item.label ===
                              t(`project_page.forms.not_selected`),
                          ),
                      }}
                      heightCustom={32}
                      setCurrentAudit={setCurrentAudit}
                      viewStyle="Horizontal"
                    />
                  )}
                </Field>
              </LabelField>
            </TabPanel>
            <TabPanel className={css.panel}>
              <ToggleSwitch
                onChange={() => {
                  formik.setFieldValue(
                    'reports.use_inherit_settings',
                    !formik.values.reports.use_inherit_settings,
                  );
                }}
                checked={formik.values.reports.use_inherit_settings}
                activateText={t(
                  'campaigns_page.campaign_settings.additional.use_client',
                )}
                deactivateText={t(
                  'campaigns_page.campaign_settings.additional.use_client',
                )}
              />

              <div
                className={cn(css.list, {
                  _disabled: formik.values.reports.use_inherit_settings,
                })}
              >
                {formik.values.reports.items.map((e, i: number) => (
                  <div className={css.group} key={e.type}>
                    <span>{t(`common.reports.${e.type}`)}</span>
                    <Field name={`reports.items[${i}]`}>
                      {(formProps: any) => {
                        const selectProps = {
                          ...formProps,
                          meta: formProps.meta,
                          field: {
                            ...formProps.field,
                            value: convertReports(
                              formik.values.reports.items[i],
                            ),
                          },
                        };

                        return (
                          <Select
                            {...selectProps}
                            customChange={(val: string) => {
                              const item = {
                                is_enabled: false,
                              };

                              if (
                                val === t('clients_page.client_edit.included')
                              ) {
                                item.is_enabled = true;
                              }

                              formik.setFieldValue(`reports.items[${i}]`, {
                                ...formik.values.reports.items[i],
                                ...item,
                              });
                            }}
                            placeholder={t(
                              'campaigns_page.campaign_settings.additional.ddmm',
                            )}
                            options={[
                              t('clients_page.client_edit.included'),
                              t('clients_page.client_edit.turned_off'),
                            ]}
                            className={css.select}
                          />
                        );
                      }}
                    </Field>
                  </div>
                ))}
              </div>
            </TabPanel>
          </Tabs>
        </div>
      </CollapseCard>
    </Card>
  );
};

export default Reports;
