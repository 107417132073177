import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FetchedData, genFetchedData } from '../../../redux/fetchedData';
import { AllCounteragetnList, CounteragentsList, OrdList } from './types';

export type counteragentsState = {
  counteragentsList: FetchedData<CounteragentsList>;
  counteragentAllList: FetchedData<AllCounteragetnList>;
  ordList: FetchedData<OrdList>;
  lastId: string;
  lastStatus: string;
};

export const initialState: counteragentsState = {
  counteragentsList: genFetchedData<CounteragentsList>(null),
  counteragentAllList: genFetchedData<AllCounteragetnList>(null),
  ordList: genFetchedData<OrdList>(null),
  lastId: '',
  lastStatus: '',
};

const counteragentsSlice = createSlice({
  name: 'Counteragents',
  initialState,
  reducers: {
    setCounteragentsList(
      state,
      action: PayloadAction<counteragentsState['counteragentsList']>,
    ) {
      state.counteragentsList = action.payload;
    },
    setAllCounteragentList(
      state,
      action: PayloadAction<counteragentsState['counteragentAllList']>,
    ) {
      state.counteragentAllList = action.payload;
    },
    setOrdList(
      state,
      action: PayloadAction<counteragentsState['ordList']>,
    ) {
      state.ordList = action.payload;
    },
    setLastId(state, action: PayloadAction<counteragentsState['lastId']>) {
      state.lastId = action.payload;
    },
    setLastStatus(state, action: PayloadAction<counteragentsState['lastStatus']>) {
      state.lastStatus = action.payload;
    },
    delLastId(state) {
      state.lastId = initialState.lastId;
    },
    delLastStatus(state) {
      state.lastStatus = initialState.lastStatus;
    },
  },
});

export const {
  setCounteragentsList,
  setAllCounteragentList,
  setOrdList,
  setLastId,
  setLastStatus,
  delLastId,
  delLastStatus,
} = counteragentsSlice.actions;
export const counteragentsReducer = counteragentsSlice.reducer;
