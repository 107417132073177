import { Location } from 'history';
import { useEffect } from 'react';
import { useDelLastIdCreative } from './useLastIdCreative';
import { useDelLastIdUsersAPI } from './useLastIdUsersAPI';
import { useDelLastIdCounteragent } from './useLastIdCounteragent';
import { useDelLastIdCampaigns } from './useLastIdCampaign';
import { useDelLastIdContract } from './useLastIdContract';
import { useDelLastIdClients } from './useLastIdClient';

/**
 * Очищает последний добавленный id для хуков серии useLastId
 * @param location 
 */
export const removeLastId = (location: Location<unknown>): void => {
  const onDelLastIdCreative = useDelLastIdCreative();
  const onDelLastIdCampaigns = useDelLastIdCampaigns();
  const onDelLastIdCounteragent = useDelLastIdCounteragent();
  const onDelLastIdСontract = useDelLastIdContract();
  const onDelLastIdUserAPI = useDelLastIdUsersAPI();
  const onDelLastIdClients = useDelLastIdClients();

  useEffect(() => {
    if (!location.pathname.includes('creatives')) {
      onDelLastIdCreative();
    }
    if (!location.pathname.includes('campaigns')) {
      onDelLastIdCampaigns();
    }
    if (!location.pathname.includes('counteragents')) {
      onDelLastIdCounteragent();
    }
    if (!location.pathname.includes('contracts')) {
      onDelLastIdСontract();
    }
    if (!location.pathname.includes('users_api')) {
      onDelLastIdUserAPI();
    }
    if (!location.pathname.includes('campaigns')) {
      onDelLastIdClients();
    }
  }, [location]);
};
