import { AgencyInfo } from 'domains/agency/types';
import { RGetPersonal } from 'domains/campaign/types';
import { ResponseInfoClient } from 'domains/client/types';
import { useUserInfo } from 'domains/user/model/selectors';
import { everyGroups } from 'utils/statics/everyGroups';

export type TDataType =
  | AgencyInfo
  | RGetPersonal
  | ResponseInfoClient
  | null;

/**
 * Функция проверяет, установлен ли method_recalculating_statistics: SELF_SERVICE_MARKUP
 * @param data Информация о компании/клиенте/агенстве
 * @returns Boolean
 */
export const isDisableSelfService = (data: TDataType): boolean => {
  const { data: user } = useUserInfo();

  if (
    data &&
    data?.method_recalculating_statistics === 'SELF_SERVICE_MARKUP' &&
    !everyGroups(user, 'owner', 'experimental_owner')
  ) {
    return true;
  }
  return false;
};

const transformStr = (value: string, regex: RegExp) => {
  if (!value.includes('\n') && !regex.test(value)) return false;

  const parsedStrArray = value.split(/\n/);
  
  return parsedStrArray.every(s => s === '' ? true : regex.test(s.trim()));
}

/**
 * Функция валидирует additional_metrics
 * @param value Строка для валидации
 * @returns Boolean
 */
export const additionalMetricsValidation = (
  value: string | undefined,
): boolean => {
  if (value === '' || value === undefined) return true;
  const regex = /^\d{2}\.\d{2}\.\d{4}\s\d{3}\s\d{1}(\.\d{1})$/m;

  return transformStr(value, regex);
};

/**
 * Функция валидирует statistic_coefficient
 * @param value Строка для валидации
 * @returns Boolean
 */
export const statisticCoeffValidation = (value: string | undefined): boolean => {
  if (value === '' || value === undefined) return true;
  const regex = /^\d{2}\.\d{2}\.\d{4}\s\d{1}(\.\d{1})$/m;

  return transformStr(value, regex);
}
