import React, { FC } from 'react';
import { FiChevronDown } from 'react-icons/fi';
import { groupData } from 'domains/campaign/types';
import css from './styles.module.scss';
import i18n from '../../../../i18n';

interface groupWidgetProps {
  groupInfo: groupData;
}

const Limits: FC<groupWidgetProps> = ({ groupInfo }) => (
  <div className={css.limits_widget}>
    <div className={css.dropdown}>
      <span>{groupInfo?.title}</span>
      <FiChevronDown size={14} />
    </div>
    <div className={css.row}>
      <div className={css.name}>
        {i18n.t(`campaigns_page.campaign_settings.per_user`)}:
      </div>
      <div className={css.value}>
        {groupInfo?.limits.show_per_user.day || '-'}/
        {groupInfo?.limits.show_per_user.total || '-'}
      </div>
    </div>
    {
      // Удалила по задаче https://sniper.atlassian.net/browse/AS-9216
    }
    {/* <div className={css.row}>
      <div className={css.name}>
        {i18n.t(`campaigns_page.campaign_settings.Impressions`)}:
      </div>
      <div className={css.value}>
        {groupInfo?.limits.show.day || '-'}/
        {groupInfo?.limits.show.total || '-'}
      </div>
    </div>
    <div className={css.row}>
      <div className={css.name}>
        {i18n.t(`campaigns_page.campaign_settings.Clicks`)}:
      </div>
      <div className={css.value}>
        {groupInfo?.limits.click.day || '-'}/
        {groupInfo?.limits.click.total || '-'}
      </div>
    </div>
    <div className={css.row}>
      <div className={css.name}>
        {i18n.t('campaigns_page.campaign_settings.Budget')}, {currency}:
      </div>
      <div className={css.value}>
        {groupInfo?.limits.budget.day || '-'}/
        {groupInfo?.limits.budget.total || '-'}
      </div>
    </div> */}
  </div>
);

export default Limits;
