import React, { FC } from 'react';
import { FiX } from 'react-icons/fi';
import cn from 'classnames';
import css from './styles.module.scss';
import useTagInput, { TFUseTagInputProps } from './hook';

type InputProps = TFUseTagInputProps & {
  maxLength?: number;
  hideSymbol?: boolean;
  isError?: boolean;
};

const Input: FC<InputProps> = ({
  valueDefault,
  maxLength = 150,
  handlerClose,
  handlerAction,
  handlerUpdateAction,
  type,
  hideSymbol = false,
  index,
  isError,
  showEvent,
}) => {
  const { value, inputRef, onChange, wrapperRef, clearValue } = useTagInput({
    valueDefault,
    handlerClose,
    handlerAction,
    handlerUpdateAction,
    index,
    type,
    showEvent,
  });
  return (
    <div className={cn(css.inputFieldWrapper, { isError })} ref={wrapperRef}>
      <input
        className={css.inputField}
        value={value}
        maxLength={maxLength}
        ref={inputRef}
        onChange={onChange}
        id={`input-${index}`}
        autoComplete="off"
      />
      {!hideSymbol && (
        <label htmlFor={`input-${index}`} className={css.counter}>
          ({value.length}/{maxLength})
        </label>
      )}
      <button
        className={css.close}
        aria-label="Close input"
        type="button"
        onClick={() => {
          handlerClose();
          clearValue();
        }}
      >
        <FiX size={18} />
      </button>
    </div>
  );
};

export default Input;
