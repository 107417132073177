import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import { useDispatchApp } from 'redux/rootSelectors';
import { TStatusCampaign } from 'domains/campaign/types';
import { AppState } from '../../redux/rootReducer';
import {
  CampaignsState,
  delLastId,
  delLastStatus,
  setCampaignsFilter,
  setLastId,
  setLastStatus,
} from '../../domains/campaigns/reducer';
import { useCampaignsFilterInfo } from '../../domains/campaigns/model/selectors';
import { enCodeUrlFilter } from '../../domains/campaigns/model/utils/encodeurlFilter';

/** возврощает последний посещенный ид */
export function useHighlights(): string {
  const dispatch = useDispatchApp();
  const [id, setId] = useState<string>('');
  const history = useHistory();
  const lastId = useSelector<AppState, CampaignsState['lastId']>(
    ({ campaignsReducer }) => campaignsReducer.lastId,
    shallowEqual,
  );
  const lastStatus = useSelector<AppState, CampaignsState['lastStatus']>(
    ({ campaignsReducer }) => campaignsReducer.lastStatus,
    shallowEqual,
  );
  const filters = useCampaignsFilterInfo();

  useEffect(() => {
    if (!lastId || !lastStatus) return;
    const url = new URL(window.location.href);
    url.searchParams.set('highlights', lastId);
    url.searchParams.set('status', lastStatus);
    if (filters.length) {
      url.searchParams.set('filter', enCodeUrlFilter(filters));
    }
    history.push(url.search);
  }, [lastId, lastStatus]);
  useEffect(() => {
    const url = new URL(window.location.href);
    const highlights = url.searchParams.get('highlights') ?? '';
    dispatch(setLastId(highlights));
    setId(highlights);
  }, [window.location.href]);
  return id;
}
/** устанвливает последний посещенный ид из сылки */
export function useSetLastIdCampaigns(status?: TStatusCampaign): void {
  const dispatch = useDispatchApp();
  const { id } = useParams<{ id?: string }>();
  useEffect(() => {
    if (!id || !status) return;
    dispatch(setLastId(id));
    dispatch(setLastStatus(status));
  }, [id, status]);
}
/** удаляет последний посещеный ид */
export function useDelLastIdCampaigns(): () => void {
  const dispatch = useDispatchApp();
  return useCallback(() => {
    dispatch(delLastId());
    dispatch(delLastStatus());
    dispatch(setCampaignsFilter([]));
  }, []);
}

export function useGetStatusFromUrl(): TStatusCampaign {
  const [campaignStatus, setCampaignStatus] = useState<TStatusCampaign>('');
  const location = useLocation()

  useEffect(() => {
    const paramHash = new URLSearchParams(location.search)
    const status = paramHash.get('status') ?? '';
    const campaignStatuses = ["", "STOPPED", "LAUNCHED", "DELETED", "ARCHIVE"]
    if (status && campaignStatuses.includes(status)) {
      setCampaignStatus(status as TStatusCampaign)
    }    
  }, [location]);
  return campaignStatus;
}
