import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FetchedData, genFetchedData } from '../../../redux/fetchedData';
import { TAPIMethods, TAPIUser } from './types';
import { TGetTechnicalInfo, TTableSort } from '../../../types/general';

export type APIUsersState = {
  tempAPIUsers: FetchedData<TAPIUser[]>;
  APIMethods: FetchedData<TAPIMethods>;
  listSort: TTableSort;
  userTechnicalInfo: FetchedData<TGetTechnicalInfo>;
  lastId: string;
};

export const initialState: APIUsersState = {
  tempAPIUsers: genFetchedData<TAPIUser[]>(null),
  APIMethods: genFetchedData<TAPIMethods>(null),
  listSort: {
    sort: null,
    key: null,
  },
  userTechnicalInfo: genFetchedData<TGetTechnicalInfo>(null),
  lastId: '',
};

const APIUsersSlice = createSlice({
  name: 'APIUsers',
  initialState,
  reducers: {
    setTempAPIUsers(
      state,
      action: PayloadAction<APIUsersState['tempAPIUsers']>,
    ) {
      state.tempAPIUsers = action.payload;
    },
    setAPIMethods(
      state,
      action: PayloadAction<APIUsersState['APIMethods']>,
    ) {
      state.APIMethods = action.payload;
    },
    setTechnicalInfo(
      state,
      action: PayloadAction<APIUsersState['userTechnicalInfo']>,
    ) {
      state.userTechnicalInfo = action.payload;
    },
    setLastId(state, action: PayloadAction<APIUsersState['lastId']>) {
      state.lastId = action.payload;
    },
    delLastId(state) {
      state.lastId = initialState.lastId;
    },
  },
});

export const {
  setTempAPIUsers,
  setAPIMethods,
  setTechnicalInfo,
  setLastId,
  delLastId,
} = APIUsersSlice.actions;
export const APIUsersReducer = APIUsersSlice.reducer;
