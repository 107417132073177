import {
  THookStateInfo,
  TSelector,
  useSelectorTypes,
} from '../../../redux/rootSelectors';
import { StorageState } from './types';

/**
 * Список селекторов
 * */
export const selectorUserListTabs: TSelector<StorageState['usersTabs']> = ({
  storageReducer,
}) => storageReducer.usersTabs;

export const selectorAgenciesTabs: TSelector<StorageState['agenciesTabs']> = ({
  storageReducer,
}) => storageReducer.agenciesTabs;

export const selectorClientsTabs: TSelector<StorageState['clientsTabs']> = ({
  storageReducer,
}) => storageReducer.clientsTabs;

export const selectorContractsTabs: TSelector<StorageState['contractsTabs']> = ({
  storageReducer,
}) => storageReducer.contractsTabs;

export const selectorCampaignsTabs: TSelector<
  StorageState['campaignsTabs']
> = ({ storageReducer }) => storageReducer.campaignsTabs;

export const selectorIsShowStatistics: TSelector<
  StorageState['isShowTargetStatistics']
> = ({ storageReducer }) => storageReducer.isShowTargetStatistics;

export const selectorCollapseWrappers: TSelector<
  StorageState['collapseWrappers']
> = ({ storageReducer }) => storageReducer.collapseWrappers;

export const selectorActivityUsersDate: TSelector<
  StorageState['activityUsersDate']
> = ({ storageReducer }) => storageReducer.activityUsersDate;

/** Список хуков */
export const useStorageUserListTabs: THookStateInfo<
  StorageState['usersTabs']
> = () => useSelectorTypes(selectorUserListTabs);

export const useStorageAgenciesTabs: THookStateInfo<
  StorageState['agenciesTabs']
> = () => useSelectorTypes(selectorAgenciesTabs);

export const useStorageClientsTabs: THookStateInfo<
  StorageState['clientsTabs']
> = () => useSelectorTypes(selectorClientsTabs);

export const useStorageContractsTabs: THookStateInfo<
  StorageState['contractsTabs']
> = () => useSelectorTypes(selectorContractsTabs);

export const useStorageCampaignsTabs: THookStateInfo<
  StorageState['campaignsTabs']
> = () => useSelectorTypes(selectorCampaignsTabs);

export const useStorageIsShowStatistics: THookStateInfo<
  StorageState['isShowTargetStatistics']
> = () => useSelectorTypes(selectorIsShowStatistics);

export const useStorageCollapseWrappersInfo: THookStateInfo<
  StorageState['collapseWrappers']
> = () => useSelectorTypes(selectorCollapseWrappers);

export const useStorageActivityUsersInfo: THookStateInfo<
  StorageState['activityUsersDate']
> = () => useSelectorTypes(selectorActivityUsersDate);
