import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import Breadcrumbs from 'components/Breadcrumbs/breadcrumbs';
import cn from 'classnames';
import CardsList from 'components/UI/CardList';
import Card from 'components/UI/Card';
import { FiAlertCircle } from 'react-icons/fi';
import CalculationCoefficientsBlock from 'components/CalculationCoefficientsBlock';
import { Trans, useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router';
import { TID } from 'types/general';
import StatiscticFields from 'components/StatisticFields';
import { useSRMethod } from 'components/CalculationCoefficientsBlock/hooks/useSRMethod';
import { useAgencyInfo } from 'domains/agency/hooks';
import i18n from '../../../../i18n';
import css from './styles.module.scss';
import { useSettings } from './hook/useSettings';
import AudiencesMl from '../AudiencesMl';

const Settings: FC = () => {
  const { t } = useTranslation();
  const { params } = useRouteMatch<TID>();
  const { agency } = useAgencyInfo();

  const {
    SRMethod,
    listSRMethods,
    onChangeSRMethod,
  } = useSRMethod(agency);

  const {
    AgencyData,
    isLoadingAgency,
    formik,
    formikAudit,
    formikIntermediateAudit,
    editAuditDictionary,
    handlerInput,
    setCurrentIntermediateData,
    auditLoading,
    auditDictionaryList,
    commissionAgencyData,
    commissionSelfServiceData,
    cpcData,
    marginalityData
  } = useSettings(SRMethod);

  return (
    <div className={css.settings}>
      <Helmet>
        <title>{t(`project_page.settings`)}</title>
      </Helmet>
      <div className={css.header}>
        <div className="container">
          <Breadcrumbs type="Agency" xxhash={params.id} />
          <div className={css.status}>
            <h1 className={css.title}>{AgencyData?.title}</h1>
          </div>
        </div>
      </div>
      <div className={css.body}>
        <div className={cn(css.container, 'container')}>
          <CardsList className={css.cardlist}>
            <Card noHover isUseAccess>
              <h3 className={css.card_title}>
                {i18n.t('campaigns_page.campaign_stat_icon')}
              </h3>
              <StatiscticFields
                descriptionText={i18n.t('clients_page.show_columns')}
                mode="client"
              />
            </Card>
            <CalculationCoefficientsBlock
              isLoadingData={isLoadingAgency}
              formik={formik}
              SRMethod={SRMethod}
              listSRMethods={listSRMethods}
              onChangeSRMethod={onChangeSRMethod}
              commissionAgencyData={commissionAgencyData}
              commissionSelfServiceData={commissionSelfServiceData}
              cpcData={cpcData}
              marginalityData={marginalityData}
              data={agency}
            />
            <AudiencesMl
              formik={formikAudit}
              intermediateFormik={formikIntermediateAudit}
              editAuditDictionary={editAuditDictionary}
              handlerInput={handlerInput}
              setCurrentIntermediateData={setCurrentIntermediateData}
              auditLoading={auditLoading}
              auditDictionaryList={auditDictionaryList}
            />
          </CardsList>
          <CardsList>
            <Card noHover isUseAccess>
              <div className={css.info}>
                <FiAlertCircle size={20} />
                <div className={css.info_text}>
                  <p>
                    {t(`campaigns_page.campaign_settings.additional.info_1`)}
                  </p>
                  <p>
                    <Trans
                      i18nKey="campaigns_page.campaign_settings.additional.info_2"
                      values={{
                        method: 'method_recalculating_statistics_CAMPAIGN_TYPE',
                      }}
                      components={{ bold: <strong /> }}
                    />
                  </p>
                </div>
              </div>
            </Card>
          </CardsList>
        </div>
      </div>
    </div>
  );
};

export default Settings;
